import Cookie from 'js-cookie';

import * as REST from '../../api/rest';
import history from '../../history';
import Notification from '../../components/Notification';
import {
  createEcosystemFailed,
  createEcosystemStarted,
  createEcosystemSuccess,
  editEcosystemStarted,
  editEcosystemSuccess,
  editEcosystemFailed,
  removeEcosystemStarted,
  removeEcosystemSuccess,
  removeEcosystemFailed,
  upgradeEcosystemStarted,
  upgradeEcosystemSuccess,
  upgradeEcosystemFailed,
  recoverEcosystemStarted,
  recoverEcosystemSuccess,
  recoverEcosystemFailed,
  fetchingEcosystemsFailure,
  fetchingEcosystemsStarted,
  fetchingEcosystemsSuccess,
  fetchingNSPFailure,
  fetchingNSPStarted,
  fetchingNSPSuccess,
  setCurrentEcosystem,
  updateEcosystemMFADataStarted,
  updateEcosystemMFADataSuccess,
  updateEcosystemMFADataFailure,
} from '../../store/ecosystems/actions';

export function fetchEcosystems(customerUUID) {
  return async (dispatch) => {
    try {
      dispatch(fetchingEcosystemsStarted());
      const results = await REST.fetchEcosystems({ customerUUID });
      dispatch(fetchingEcosystemsSuccess(results));
    } catch (err) {
      dispatch(fetchingEcosystemsFailure(err));
    }
  };
}
export function openEcosystem(ecosystem) {
  return (dispatch) => {
    dispatch(setCurrentEcosystem(ecosystem));
    Cookie.set('currentEcosystem', ecosystem);
    const url = `${history.location.pathname.split('/').slice(0, 4).join('/')}/ecosystems/${ecosystem.uuid}/objects`;
    history.push(url);
  };
}
export function removeCurrent() {
  return (dispatch) => {
    dispatch(setCurrentEcosystem());
    Cookie.remove('currentEcosystem');
  };
}
export function removeEcosystem(ecosystem) {
  return async (dispatch, getState) => {
    try {
      dispatch(removeEcosystemStarted());
      await REST.removeEcosystem({
        ecosystem,
        customer: getState().customers.currentCustomer.uuid,
      });
      dispatch(removeEcosystemSuccess());
      Notification({ message: 'Ecosystem has been removed' });
    } catch (err) {
      dispatch(removeEcosystemFailed(err));
    }
  };
}
export function upgradeEcosystem(ecosystem) {
  return async (dispatch, getState) => {
    try {
      dispatch(upgradeEcosystemStarted());
      await REST.upgradeEcosystem({
        ecosystem,
        customer: getState().customers.currentCustomer.uuid,
      });
      dispatch(upgradeEcosystemSuccess());
    } catch (err) {
      dispatch(upgradeEcosystemFailed(err));
    }
  };
}
export function recoverEcosystem(ecosystem) {
  return async (dispatch, getState) => {
    try {
      dispatch(recoverEcosystemStarted());
      await REST.recoverEcosystem({
        ecosystem,
        customer: getState().customers.currentCustomer.uuid,
      });
      dispatch(recoverEcosystemSuccess());
    } catch (err) {
      dispatch(recoverEcosystemFailed(err));
    }
  };
}

export function editEcosystem(entity) {
  return async (dispatch, getState) => {
    try {
      const customer = getState().customers.currentCustomer.uuid;
      const ecosystem = {
        name: entity.name,
        regions: entity.regions,
        uuid: entity.uuid,
      };
      dispatch(editEcosystemStarted());
      const result = await REST.editEcosystem({ customer, ecosystem });
      dispatch(editEcosystemSuccess(result));
      Notification({ message: 'Ecosystem has been updated' });
    } catch (err) {
      dispatch(editEcosystemFailed(err));
    }
  };
}

export function createEcosystem(entity) {
  return async (dispatch, getState) => {
    try {
      dispatch(createEcosystemStarted());
      const ecosystem = {
        name: entity.name,
        regions: entity.regions,
      };
      const curCustomer = getState().customers.currentCustomer;
      const result = await REST.createEcosystem({
        entity: ecosystem,
        customer: curCustomer,
      });
      dispatch(createEcosystemSuccess(result));
      Notification({ message: 'New ecosystem has been created' });
    } catch (err) {
      dispatch(createEcosystemFailed(err));
    }
  };
}

export function renameEcosystem(entity) {
  return async (dispatch, getState) => {
    try {
      const customer = getState().customers.currentCustomer.uuid;
      const ecosystem = {
        name: entity.name,
        uuid: entity.uuid,
      };
      dispatch(editEcosystemStarted());
      await REST.renameEcosystem({ customer, ecosystem });
      dispatch(editEcosystemSuccess(ecosystem));
      Notification({ message: 'Ecosystem has been renamed' });
    } catch (err) {
      dispatch(editEcosystemFailed(err));
    }
  };
}

export function fetchNSPs() {
  return async (dispatch) => {
    try {
      dispatch(fetchingNSPStarted());
      const results = await REST.fetchNSPs();
      dispatch(fetchingNSPSuccess(results));
    } catch (err) {
      dispatch(fetchingNSPFailure(err));
    }
  };
}

export function updateEcosystemMFAData(mfaTypes) {
  return async (dispatch, getState) => {
    const ecosystem = getState().ecosystems.currentEcosystem;
    const customer = getState().customers.currentCustomer;

    try {
      dispatch(updateEcosystemMFADataStarted());
      await REST.updateEcosystemMFAData({
        customerUUID: customer.uuid,
        ecosystemUUID: ecosystem.uuid,
        mfaTypes,
      });
      dispatch(updateEcosystemMFADataSuccess({ mfaData: mfaTypes }));
      Notification({ message: `MFA Data has been updated` });
    } catch (error) {
      dispatch(updateEcosystemMFADataFailure(error));
    }
  };
}
