import dayjs from 'dayjs';
import { GATEWAY_TYPE, MODEL_TYPE } from '../enum/gateway';
import { IP_MODES } from '../enum/ip';
import { LOCATION_TYPE } from '../enum/location';

export default {
  role: ({ uuid, name, element }) => ({
    value: uuid || '',
    label: name || '',
    type: element || 'role',
  }),
  group: ({ uuid, name, element }) => ({
    value: uuid || '',
    label: name || '',
    type: element || 'group',
  }),
  thing: (entity) => ({
    name: entity.name,
    uuid: entity.uuid,
    assetValue: entity.asset,
    category: entity.category.value,
    ...(entity.expiryTime
      ? {
          expiry: {
            time: dayjs(entity.expiryTime).toISOString(),
            type: entity.expiryType,
          },
        }
      : {}),
    ...(entity.location.value !== LOCATION_TYPE.UNDEFINED
      ? {
          geolocation: {
            latitude: parseFloat(entity.lat),
            longitude: parseFloat(entity.lng),
          },
        }
      : {}),
    ...(entity.desc ? { description: entity.desc } : {}),
    profileGroup: entity.profile.value,
    type: 'device',
  }),
  address: (entity) => ({
    address: entity.address,
    category: entity.category.value,
    name: entity.name,
    type: entity.protocolType,
    ...(entity.expiryTime
      ? {
          expiry: {
            time: dayjs(entity.expiryTime).toISOString(),
            type: entity.expiryType,
          },
        }
      : {}),
    ...(entity.location.value !== LOCATION_TYPE.UNDEFINED
      ? {
          geolocation: {
            latitude: parseFloat(entity.lat),
            longitude: parseFloat(entity.lng),
          },
        }
      : {}),
    ...(entity.desc ? { description: entity.desc } : {}),
  }),
  publicAddress: (entity) => ({
    name: entity.name,
    ...(entity.desc ? { description: entity.desc } : {}),
    type: entity.type,
    nsp: entity.nsp,
  }),
  user: (entity) => ({
    users: entity.map((user) => ({
      ...user,
      role: user.role.value,
    })),
  }),
  server: (entity) => ({ ...entity }),
  gateway: (entity) => {
    const payload = {
      name: entity.name,
      desc: entity.desc,
      category: entity.category.value,
      gateway_type: entity.gateway_type,
      allow_all_ciphers: entity.allow_all_ciphers,
      peer_address: removeMask(entity.peer_address),
      peer_id:
        entity.model === MODEL_TYPE.AWS_SITE && entity.gateway_type !== GATEWAY_TYPE.WIREGUARD
          ? removeMask(entity.peer_id || '')
          : entity.peer_id,
      acreto_id: removeMask(entity.acreto_id),
      model: entity.model,
      uuid: entity.uuid,
    };
    if (entity.ipsec_fail_open) {
      payload.ipsec_fail_open = entity.ipsec_fail_open;
    }
    if (entity.expiryTime) {
      payload.expiry = {
        time: dayjs(entity.expiryTime).toISOString(),
        type: entity.expiryType,
      };
    }
    if (entity.location.value !== LOCATION_TYPE.UNDEFINED) {
      payload.geolocation = {
        latitude: parseFloat(entity.lat),
        longitude: parseFloat(entity.lng),
      };
    }
    if (entity.gateway_internet_proto === IP_MODES.STATIC) {
      payload.gateway_internet_gw = entity.gateway_internet_gw;
      payload.gateway_internet_ip = checkMask(entity.gateway_internet_ip);
    }
    if (entity.gateway_type === GATEWAY_TYPE.VGATEWAY) {
      payload.gateway_internet_proto = entity.gateway_internet_proto;
    }
    if ([GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(entity.gateway_type)) {
      payload.gateway_local_ip = checkMask(entity.gateway_local_ip);
    }
    if (entity.peer_port && Number(entity.peer_port)) {
      payload.peer_port = Number(entity.peer_port);
    }
    payload.peer_networks = entity.peer_networks.map((net) => {
      if ([GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(entity.gateway_type)) {
        return {
          network: checkMask(net.network),
          gateway: net.hop,
        };
      }
      return { network: checkMask(net.network) };
    });

    return payload;
  },
};

function checkMask(ip) {
  if (ip) {
    return ip.split('/')[1] ? ip : `${ip}/32`;
  }
  return null;
}
function removeMask(ip) {
  return ip.split('/')[0];
}
