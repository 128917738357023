import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Files from 'react-files';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import PhoneInput from 'components/PhoneInput';
import { resizeFile, toBase64 } from 'utils/utils';

import { updateUserProfile } from '../senario-actions';
import AuthForm from './AuthForm';

import '../styles.scss';

const TIMEOUT_OPTIONS = [300, 600, 1800];

const stringToAvatar = (user) => {
  if (user.avatar) {
    return { src: user.avatar };
  }
  if (user.firstName) {
    return { children: user.firstName[0]?.toUpperCase() };
  }
  return { children: <PersonIcon fontSize="64" /> };
};

const validationSchema = object().shape({
  firstName: string().trim().required('First name is required'),
  lastName: string().trim().required('Last name is required'),
  mobilePhone: string().trim().required('Phone number is required'),
});

const UserProfile = ({ user, auth, updateUserProfile, onFormChanged }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const isUserPassAuth = useMemo(() => auth?.iss === 'userpass', [auth]);

  const handleSave = useCallback(
    async (values, { setErrors }) => {
      if (!isValidPhoneNumber(values.mobilePhone)) {
        setErrors({ mobilePhone: 'Invalid phone number' });
      }
      try {
        const payload = values;
        await updateUserProfile(payload);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    [updateUserProfile],
  );

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, dirty } = useFormik({
    initialValues: {
      email: user.email,
      avatar: user.avatar,
      firstName: user.firstName,
      lastName: user.lastName,
      mobilePhone: user.mobilePhone,
      tokenTTL: user.tokenTTL || 600,
    },
    validationSchema,
    onSubmit: handleSave,
  });

  useEffect(() => {
    onFormChanged(dirty);
  }, [dirty, onFormChanged]);

  const handleAvatarChange = useCallback(
    async ([file]) => {
      if (file) {
        const format = file.type.split('/')[1];
        if (format !== 'png' && format !== 'jpg' && format !== 'jpeg' && format !== 'svg+xml') {
          enqueueSnackbar('Only png, jpg, jpeg, svg files are allowed', { variant: 'error' });
        }
        let image = null;
        if (format === 'svg+xml') image = await toBase64(file);
        else image = await resizeFile(file, 144, 144, format);
        setFieldValue('avatar', image);
      }
    },
    [enqueueSnackbar, setFieldValue],
  );

  const handleRemoveAvatar = () => {
    setFieldValue('avatar', '');
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={3}>
          <Stack spacing={2}>
            <Avatar
              sx={{ width: 144, height: 144, fontSize: 64 }}
              {...stringToAvatar(values)}
              data-testid="user-avatar"
            />
            <Files
              multiple={false}
              accepts={['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml']}
              onChange={handleAvatarChange}
            >
              <Button
                size="large"
                variant="contained"
                startIcon={<UploadIcon />}
                sx={{ width: 144 }}
                data-testid="btn-avatar_upload"
              >
                Upload
              </Button>
            </Files>
            <Button
              size="large"
              color="error"
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ width: 144 }}
              onClick={handleRemoveAvatar}
              data-testid="btn-avatar_delete"
            >
              Delete
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel>First Name</InputLabel>
              <TextField
                fullWidth
                value={values.firstName}
                name="firstName"
                onChange={handleChange}
                helperText={!!touched.firstName && errors.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
                FormHelperTextProps={{ sx: { fontSize: 12, ml: 0 } }}
                data-testid="input-firstName"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Last Name</InputLabel>
              <TextField
                fullWidth
                value={values.lastName}
                name="lastName"
                onChange={handleChange}
                helperText={!!touched.lastName && errors.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
                FormHelperTextProps={{ sx: { fontSize: 12, ml: 0 } }}
                data-testid="input-lastName"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Email</InputLabel>
              <TextField disabled fullWidth value={user.email} data-testid="input-email" />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Mobile Number</InputLabel>
              <PhoneInput
                value={values.mobilePhone}
                name="mobilePhone"
                onChange={(value) => setFieldValue('mobilePhone', value)}
                helperText={!!touched.mobilePhone && errors.mobilePhone}
                error={Boolean(touched.mobilePhone && errors.mobilePhone)}
                FormHelperTextProps={{ sx: { fontSize: 12, ml: 0 } }}
                data-testid="input-mobile"
              />
            </Grid>
          </Grid>
          {isUserPassAuth && <AuthForm user={user} />}
          <Typography mt={3} fontSize={24} sx={{ color: '#76888f', fontWeight: 'bold' }}>
            Settings
          </Typography>
          <Divider sx={{ my: 2 }} />
          <InputLabel>Inactivity Timeout</InputLabel>
          <Select
            name="tokenTTL"
            value={values.tokenTTL}
            onChange={handleChange}
            sx={{ fontSize: 16, width: 240 }}
            data-testid="select-timeout"
          >
            {TIMEOUT_OPTIONS.map((option) => (
              <MenuItem value={option} key={`idle-timeout-${option}`} sx={{ fontSize: 16 }}>
                {option / 60}&nbsp;min
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3 }} />
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          size="large"
          variant="outlined"
          color="inherit"
          sx={{ width: 120 }}
          onClick={() => history.goBack()}
          data-testid="btn-cancel_update"
        >
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{ width: 120 }}
          onClick={handleSubmit}
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={12} /> : null}
          data-testid="btn-user_update"
        >
          Update
        </Button>
      </Stack>
    </>
  );
};

UserProfile.propTypes = {
  user: PropTypes.object,
  auth: PropTypes.object,
  updateUserProfile: PropTypes.func.isRequired,
  onFormChanged: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (payload) => dispatch(updateUserProfile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
