import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import Files from 'react-files';

import FileDropzoneImg from 'assets/img/PNG/file-dropzone.png';

import FileChip from './FileChip';

const FileZone = ({ files, onUpload, onRemove }) => (
  <Box sx={{ p: 4, border: '2px solid #D1D5D9', borderRadius: '8px' }}>
    <Files
      className="files-dropzone"
      onChange={onUpload}
      accepts={[
        'image/png',
        'image/gif',
        'image/jpg',
        'image/jpeg',
        'text/plain',
        'text/csv',
        '.log',
        '.ovpn',
        '.conf',
      ]}
      multiple
      maxFileSize={10000000}
      clickable
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        data-testid="input-attachment"
      >
        <img src={FileDropzoneImg} alt="file-drop-zone" />
        <Stack direction="column" spacing={1.5}>
          <Typography variant="h4" fontSize={16}>
            Drag and drop image / text files to upload
          </Typography>
          <Typography variant="body2" fontSize={14} sx={{ color: '#76828E' }}>
            Your images / text will be upload & display in your attachments.
          </Typography>
        </Stack>
      </Box>
    </Files>
    <Divider sx={{ my: 4 }} />
    <Box data-testid="zone-file_chips">
      <Grid container spacing={2}>
        {files.map((file, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
            <FileChip label={file.name} onDelete={() => onRemove(index)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  </Box>
);

FileZone.propTypes = {
  files: PropTypes.array,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
};

export default FileZone;
