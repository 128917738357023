import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import cx from 'classnames';

import HeaderLinks from './HeaderLinks';
import { pathSlugToPageName } from '../../utils/utils';
import { ACRETO_LOGO, ACRETO_LOGO_CIRCLE } from '../../assets/Icons';
import { isAuthenticatedSelector } from '../../store/user/selectors';
import './header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false,
    };
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('nav-open');
  }

  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true,
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    const navbarCollapseElement = document.createElement('div');
    navbarCollapseElement.id = 'bodyClick';
    navbarCollapseElement.onclick = () => {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
      const wrapper = document.getElementById('root').getElementsByClassName('wrapper')[0];
      wrapper.style.overflow = 'auto';
      const navbarElement = document.getElementsByClassName('navbar-collapse collapse in');
      if (navbarElement.length) {
        navbarElement[0].classList.toggle('in');
      }
    };
    document.body.appendChild(navbarCollapseElement);
    const wrapper = document.getElementById('root').getElementsByClassName('wrapper')[0];
    wrapper.style.overflow = 'hidden';
  }

  renderLogo() {
    const { customer } = this.props;
    if (!customer || !customer.uuid) {
      return <div className="logo" />;
    }

    return (
      <div className="logo">
        <a href="/" className="simple-text logo-normal full-width">
          <div className="logo-img">
            <img src={customer.logo || ACRETO_LOGO} className="bigLogo" alt="logo_image" />
            <img src={customer.logo || ACRETO_LOGO_CIRCLE} className="smallLogo" alt="logo_image" />
          </div>
        </a>
      </div>
    );
  }

  render() {
    const { location, dashboardHeader, ecosystemHeader, brandText, showCollapse, isAuthenticated } = this.props;

    if (dashboardHeader) {
      return (
        <div className="dashboard-navbar">
          <Navbar fluid fixedTop>
            <Navbar.Header>
              <Navbar.Toggle onClick={this.mobileSidebarToggle} />
              <Navbar.Brand>
                <p>{pathSlugToPageName(location.pathname)}</p>
              </Navbar.Brand>
            </Navbar.Header>
            <Navbar.Collapse>
              <HeaderLinks showCommit />
            </Navbar.Collapse>
          </Navbar>
        </div>
      );
    }
    if (ecosystemHeader) {
      return (
        <div className="ecosystems__navbar">
          {this.renderLogo()}
          <div className="main-panel">
            <Navbar fluid>
              <Navbar.Header>
                <Navbar.Brand>
                  <div>{brandText}</div>
                </Navbar.Brand>
                <Navbar.Brand className="ecosystem_dashboard">
                  <div className="active">Summary</div>
                </Navbar.Brand>
              </Navbar.Header>
              {showCollapse && isAuthenticated && (
                <Navbar.Collapse>
                  <HeaderLinks />
                </Navbar.Collapse>
              )}
            </Navbar>
          </div>
        </div>
      );
    }

    return (
      <div className="ecosystems__navbar">
        {this.renderLogo()}
        <div className="main-panel">
          <Navbar fluid>
            <Navbar.Header>
              <Navbar.Brand>
                <div className={cx({ active: brandText })}>{brandText}</div>
              </Navbar.Brand>
            </Navbar.Header>
            {showCollapse && isAuthenticated && (
              <Navbar.Collapse>
                <HeaderLinks />
              </Navbar.Collapse>
            )}
          </Navbar>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  brandText: PropTypes.string,
  showCollapse: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  dashboardHeader: PropTypes.bool,
  ecosystemHeader: PropTypes.bool,
  location: PropTypes.object,
  customer: PropTypes.object,
};

Header.defaultProps = {
  showCollapse: true,
  dashboardHeader: false,
  isAuthenticated: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  customer: state.customers.currentCustomer,
});

export default connect(mapStateToProps, null)(Header);
