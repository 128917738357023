/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Typography, useTheme } from '@mui/material';
import { sum } from 'lodash';

import { numberWithCommas } from 'utils/utils';

const BlockedChart = ({ data }) => {
  const theme = useTheme();

  const xLables = useMemo(() => data.map(({ timeStamp }) => timeStamp, [data]));

  const options = useMemo(
    () => ({
      chart: {
        background: 'transparent',
        toolbar: { show: false },
        sparkline: { enabled: true },
        zoom: { enabled: false },
      },
      colors: ['#FF9140'],
      dataLabels: { enabled: false },
      theme: { mode: theme.palette.mode },
      stroke: {
        show: true,
        colors: ['#FF9140'],
        curve: 'smooth',
        width: 2,
      },
      legend: { show: false },
      xaxis: {
        type: 'datetime',
        categories: xLables,
        labels: { show: false },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: false,
        // x: {
        //   format: 'hh:mm',
        // },
        // y: {
        //   formatter: (value) => value.toFixed(3),
        // },
      },
    }),
    [xLables],
  );

  const series = useMemo(
    () => [
      {
        name: 'Total',
        data: data ? data.map(({ value }) => value) : [],
      },
    ],
    [data],
  );

  return (
    <Box>
      <Box sx={{ maxHeight: 80 }}>
        <Chart options={options} series={series} type="area" height="100%" />
      </Box>
      <Typography textAlign="center" fontSize={14} fontWeight="bold" data-testid="blocked-total">
        {numberWithCommas(sum(series[0].data))}
      </Typography>
    </Box>
  );
};

BlockedChart.propTypes = {
  data: PropTypes.array,
};

export default BlockedChart;
