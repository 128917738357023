export const GET_TICKET_LIST_REQUEST = 'GET_TICKET_LIST_REQUEST';
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS';
export const GET_TICKET_LIST_FAILURE = 'GET_TICKET_LIST_FAILURE';

export const GET_TICKET_DETAIL_REQUEST = 'GET_TICKET_DETAIL_REQUEST';
export const GET_TICKET_DETAIL_SUCCESS = 'GET_TICKET_DETAIL_SUCCESS';
export const GET_TICKET_DETAIL_FAILURE = 'GET_TICKET_DETAIL_FAILURE';

export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';

export const UPDATE_TICKET_REQUEST = 'UPDATE_TICKET_REQUEST';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_FAILURE = 'UPDATE_TICKET_FAILURE';

export const CLOSE_TICKET_REQUEST = 'CLOSE_TICKET_REQUEST';
export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS';
export const CLOSE_TICKET_FAILURE = 'CLOSE_TICKET_FAILURE';
