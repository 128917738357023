import React from 'react';
import ContractIdRegistration from '../../../components/ContractIdRegistration';

const ContractIdForm = () => (
  <div className="form-container">
    <ContractIdRegistration />
  </div>
);

export default ContractIdForm;
