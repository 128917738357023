/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { object, string, addMethod, mixed } from 'yup';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ROUTES } from 'enum';
import PageTitleWrapper from 'components/PageTitleWrapper';
import ExpiryWarning from 'components/ExpiryWarning';
import LoadingSpinner from 'components/LoadingSpinner';
import Header from 'components/Header/Header';
import { createSupportTicket } from 'store/support/actions';
import { fetchEcosystems } from 'views/Ecosystems/scenario-actions';
import { getUserInfo } from 'store/user/actions';
import RichEditor from 'components/RichEditor';
import { contentStateToHtml, htmlToEditorState, htmlToPlainText, validateNonEmptyString } from 'utils/html-to-draft';
import FileZone from '../FileZone';

const CATEGORIES = [
  { label: 'Connectivity', value: 'Connectivity' },
  { label: 'Authentication', value: 'Authentication' },
  { label: 'Address Translation', value: 'AddressTranslation' },
  { label: 'Decryption', value: 'Decryption' },
  { label: 'Security', value: 'Security' },
  { label: 'Content', value: 'Content' },
  { label: 'Logging', value: 'Logging' },
];

const PRIORITIES = [
  { label: 'P1 (Ecosystem-wide Outage)', value: 1 },
  { label: 'P2 (Ecosystem-wide Degradation)', value: 2 },
  { label: 'P3 (Individual Function / System / User issue)', value: 3 },
  { label: 'P4 (How do I ?)', value: 4 },
];

// Custom validation method for rich editor content
addMethod(mixed, 'contentRequired', function contentRequired(msg) {
  return this.test({
    name: 'contentRequired',
    exclusive: true,
    message: msg,
    test: (value) =>
      value.getCurrentContent().hasText() && validateNonEmptyString(htmlToPlainText(contentStateToHtml(value))),
  });
});

const validationSchema = object().shape({
  title: string().trim().max(200, 'Title can have maximum 200 characters').required('Issue title is required'),
  category: string().required('Please select a category'),
  priority: string().required('Please select a priority'),
  body: mixed().contentRequired('Issue description is required'),
});

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const SupportTicket = ({ createSupportTicket, fetchEcosystems, getUserInfo }) => {
  const history = useHistory();
  const { cusID } = useParams();
  const { currentCustomer: customer } = useSelector((state) => state.customers);
  const { items: ecosystems } = useSelector((state) => state.ecosystems);
  const user = useSelector((state) => state.user);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const userName = `${user?.firstName} ${user?.lastName}`;

  const handleSave = useCallback(
    async (values) => {
      try {
        const { ecosystem: ecosystemUUID, body, ...payload } = values;
        const ecosystem = ecosystems.find(({ uuid }) => uuid === ecosystemUUID);
        payload.content_type = 'text/html';
        if (!ecosystemUUID) {
          payload.ecosystem_name = '';
          payload.ecosystem_uuid = '';
        } else if (ecosystem) {
          payload.ecosystem_name = ecosystem.name;
          payload.ecosystem_uuid = ecosystem.uuid;
        }
        const attachments = await Promise.all(
          values.attachments.map(async (file) => {
            const base64Data = await toBase64(file);
            return { filename: file.name, data: base64Data.split(',')[1], mime_type: file.type };
          }),
        );
        payload.body = contentStateToHtml(body);
        payload.attachments = attachments;
        await createSupportTicket({ customerUUID: customer.uuid, payload });
        history.push(`${ROUTES.CUSTOMER_SUPPORT}/${customer.uuid}`);
      } catch (err) {
        if (err?.response?.status === 406) {
          const { data } = err.response;
          const infectedFiles = data?.filter((file) => !!file.is_infected).map(({ file_name: fileName }) => fileName);
          const message = `${
            infectedFiles.length > 1
              ? `These files ${infectedFiles[0]} (${infectedFiles.length - 1} more) contain`
              : `This file ${infectedFiles[0]} contains`
          } malicious content, therefore can't be uploaded`;
          enqueueSnackbar(message, { variant: 'error' });
        }
        console.log(err);
      }
    },
    [createSupportTicket, customer, ecosystems, history, enqueueSnackbar],
  );

  useEffect(() => {
    const getEcosystemData = async () => {
      try {
        setLoading(true);
        await Promise.all([fetchEcosystems(cusID), getUserInfo()]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getEcosystemData();
  }, [cusID, fetchEcosystems, getUserInfo]);

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue, isSubmitting } = useFormik({
    initialValues: {
      title: '',
      body: htmlToEditorState(''),
      category: '',
      priority: '',
      attachments: [],
      ecosystem: '',
    },
    validationSchema,
    onSubmit: handleSave,
  });

  const handleUploadFiles = (files) => {
    const newFiles = files.slice(uploadedFiles.length);
    setFieldValue('attachments', [...values.attachments, ...newFiles]);
    setUploadedFiles(files);
  };
  const handleRemoveFiles = (index) => {
    values.attachments.splice(index, 1);
    setFieldValue('attachments', values.attachments);
  };

  return (
    <>
      <div className="wrapper organizations-wrapper">
        <ExpiryWarning />
        <Header />
        <PageTitleWrapper size="lg">
          <Box
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h2" component="h2" sx={{ pb: 1 }}>
                {customer.name} New Support Request
              </Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal">
                Fill in the fields below to create a new Support Request
              </Typography>
            </Box>
          </Box>
        </PageTitleWrapper>
        <Container maxWidth="lg" sx={{ mb: 3 }}>
          <Box sx={{ backgroundColor: '#FFF', mb: 3, p: 5 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <InputLabel>Issue Title</InputLabel>
                <TextField
                  fullWidth
                  value={values.title}
                  name="title"
                  onChange={handleChange}
                  helperText={!!touched.title && errors.title}
                  error={Boolean(touched.title && errors.title)}
                  FormHelperTextProps={{ sx: { fontSize: 12, ml: 0 } }}
                  data-testid="input-title"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>User Name</InputLabel>
                <TextField fullWidth value={userName} data-testid="input-name" />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Ecosystem Name</InputLabel>
                <Select
                  fullWidth
                  name="ecosystem"
                  onChange={handleChange}
                  value={values.ecosystem}
                  data-testid="select-ecosystem"
                >
                  <MenuItem value="" sx={{ fontSize: 14 }}>
                    None
                  </MenuItem>
                  {ecosystems.map(({ name, uuid }) => (
                    <MenuItem key={uuid} value={uuid} sx={{ fontSize: 14 }}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Ecosystem ID</InputLabel>
                <TextField fullWidth value={values.ecosystem || ''} />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Organization Name</InputLabel>
                <TextField fullWidth value={customer.name} data-testid="input-org_name" />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Organization ID</InputLabel>
                <TextField fullWidth value={customer.uuid} data-testid="input-org_uuid" />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Category</InputLabel>
                <Select
                  fullWidth
                  name="category"
                  onChange={handleChange}
                  value={values.category}
                  error={Boolean(touched.category && errors.category)}
                  data-testid="select-category"
                >
                  {CATEGORIES.map(({ label, value }) => (
                    <MenuItem key={value} value={value} sx={{ fontSize: 14 }}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error sx={{ fontSize: 12 }}>
                  {!!touched.category && errors.category}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Priority</InputLabel>
                <Select
                  fullWidth
                  name="priority"
                  onChange={handleChange}
                  value={values.priority}
                  error={Boolean(touched.priority && errors.priority)}
                  data-testid="select-priority"
                >
                  {PRIORITIES.map(({ label, value }) => (
                    <MenuItem key={value} value={value} sx={{ fontSize: 14 }}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error sx={{ fontSize: 12 }}>
                  {!!touched.priority && errors.priority}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sx={{ height: '100%' }}>
                <InputLabel>Issue Description</InputLabel>
                <RichEditor value={values.body} onChangeText={(value) => setFieldValue('body', value)} />
                <FormHelperText error sx={{ fontSize: 12 }}>
                  {!!touched.body && errors.body}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FileZone files={values.attachments} onUpload={handleUploadFiles} onRemove={handleRemoveFiles} />
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              size="large"
              variant="contained"
              onClick={handleSubmit}
              sx={{ fontWeight: 600, width: 156, height: 56 }}
            >
              Submit
            </Button>
            <Button
              size="large"
              onClick={() => history.push(`${ROUTES.CUSTOMER_SUPPORT}/${cusID}`)}
              sx={{ fontWeight: 600, width: 120, height: 56, mr: 2.5 }}
            >
              Cancel
            </Button>
          </Box>
        </Container>
      </div>
      {(loading || isSubmitting || !customer.uuid) && <LoadingSpinner />}
    </>
  );
};

SupportTicket.propTypes = {
  createSupportTicket: PropTypes.func,
  fetchEcosystems: PropTypes.func,
  getUserInfo: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch(getUserInfo()),
  createSupportTicket: ({ customerUUID, payload }) => dispatch(createSupportTicket({ customerUUID, payload })),
  fetchEcosystems: (cusID) => dispatch(fetchEcosystems(cusID)),
});

export default connect(null, mapDispatchToProps)(SupportTicket);
