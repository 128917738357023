import { object, string, ref } from 'yup';

const passwordResetValidationSchema = object({
  password: string('Password')
    .min(8, 'Password cannot be less than 8 characters.')
    .max(100, 'Password cannot be more than 100 characters.')
    .required('Passwrod is required.'),
  passwordConfirmation: string()
    .oneOf([ref('password')], 'Passwords do not match.')
    .required('Password confirmation is required.'),
});

export default passwordResetValidationSchema;
