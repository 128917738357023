import { array, object, string } from 'yup';
import { GATEWAY_TYPE, MODEL_TYPE } from '../enum/gateway';
import { IP_MODES } from '../enum/ip';

export default object({
  name: string().max(250, 'Name cannot be more than 250 characters.').required('Name is required.'),
  desc: string().max(255, 'Description cannot be more than 255 characters.'),
  category: object().required('Category is required.').typeError('Category is required.'),
  gateway_type: string().oneOf(
    [GATEWAY_TYPE.IPSEC, GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD],
    'Invalid Gateway Type',
  ),
  gateway_internet_proto: string().when('gateway_type', {
    is: GATEWAY_TYPE.VGATEWAY,
    then: () => string().oneOf([IP_MODES.STATIC, IP_MODES.DHCP], 'Invalid Protocol'),
    otherwise: () => string().notRequired(),
  }),
  gateway_local_ip: string().when('gateway_type', {
    is: (val) => [GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(val),
    then: () => string().required('vGateway Local IP is required.'),
    otherwise: () => string().notRequired(),
  }),
  gateway_internet_ip: string().when('gateway_internet_proto', {
    is: IP_MODES.STATIC,
    then: () => string().required('vGateway Internet IP is required.'),
    otherwise: () => string().notRequired(),
  }),
  peer_address: string().when('model', {
    is: MODEL_TYPE.AWS_SITE,
    then: () => string().required('AWS Tunnel Outside IP Address is required.'),
    otherwise: () => string().notRequired(),
  }),
  peer_id: string().when('ownPubKey', {
    is: true,
    then: () => string().required('Client Public Key is required.'),
    otherwise: () => string(),
  }),
  gateway_internet_gw: string().when('gateway_internet_proto', {
    is: IP_MODES.STATIC,
    then: () => string().required('vGateway Default Route is required.'),
    otherwise: () => string().notRequired(),
  }),
  peer_networks: array()
    .of(
      object().shape({
        network: string().required('Network IP is required.'),
        hop: string(),
      }),
    )
    .min(1, 'Local Network is required.'),
});
