import * as REST from '../../api/rest';
import {
  loadEcosystemStarted,
  loadEcosystemSuccess,
  refreshEcosystemStarted,
  refreshEcosystemSuccess,
  fetchingEcosystemsSuccess,
  setCurrentEcosystem,
  loadEcosystemFailed,
} from '../../store/ecosystems/actions';
import { SET_ECOSYSTEM_CODE, SET_ECOSYSTEM_MFA_DATA } from '../../store/ecosystems/action-types';
import history from '../../history';

export function loadEcosystem({ ecosystemUUID, customerUUID }) {
  return async (dispatch, getState) => {
    try {
      dispatch(loadEcosystemStarted());
      let ecosystems = getState().ecosystems.items;
      if (!ecosystems.length) {
        ecosystems = await REST.fetchEcosystems({ customerUUID });
        dispatch(fetchingEcosystemsSuccess(ecosystems));
      }
      const ecosystem = await REST.fetchEcosystem({ customerUUID, ecosystemUUID });
      if (ecosystem) {
        dispatch(setCurrentEcosystem(ecosystem));
      }
      const params = { ecosystemUUID, customerUUID };
      const [nsps, groups, services, applications = []] = await Promise.all([
        REST.fetchNSPs(),
        REST.fetchGroups(params),
        REST.fetchServices(params),
        REST.fetchApplications(params),
      ]);
      dispatch(loadEcosystemSuccess({ groups, applications, services, nsps }));

      const code = await REST.fetchEcosystemCode(params);
      dispatch({ type: SET_ECOSYSTEM_CODE, payload: code });
      const [mfaData] = await REST.fetchEcosystemMFAStatus(params);
      dispatch({ type: SET_ECOSYSTEM_MFA_DATA, payload: { mfaData } });
    } catch (err) {
      loadEcosystemFailed(err);
    }
  };
}
export function refreshEcosystemStatus({ ecosystemUUID, customerUUID }) {
  return async (dispatch) => {
    try {
      dispatch(refreshEcosystemStarted());
      const params = { customerUUID, ecosystemUUID };
      const ecosystem = await REST.fetchEcosystem(params);
      dispatch(refreshEcosystemSuccess(ecosystem));
    } catch (err) {
      history.replace('/');
    }
  };
}
