import * as ACTION_TYPES from './action-types';

const initialState = {
  tickets: [],
  ticketDetail: null,
};

export default function supportReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.GET_TICKET_LIST_SUCCESS:
      return { ...state, tickets: payload };
    case ACTION_TYPES.GET_TICKET_DETAIL_SUCCESS:
      return { ...state, ticketDetail: payload };
    case ACTION_TYPES.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        ticketDetail: {
          ...state.ticketDetail,
          articles: [...state.ticketDetail.articles, payload],
        },
      };
    case ACTION_TYPES.CLOSE_TICKET_SUCCESS:
      return {
        ...state,
        tickets: state.tickets.map((ticket) => (ticket.id === payload ? { ...ticket, state_id: 7 } : ticket)),
      };
    default:
      return state;
  }
}
