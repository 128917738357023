import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Divider } from '@mui/material';

import LoadingSpinner from 'components/LoadingSpinner';
import { Helmet } from 'react-helmet-async';
import ErrorPanel from '../../components/ErrorPanel';
import { ROUTES } from '../../enum';
import { cleanLoginMessage } from '../../store/auth/actions';
import { clearError as clearLogoutError } from '../../store/user/actions';
import { errorSelector as logoutErrorSelector } from '../../store/user/selectors';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  needsLoginMessageSelector,
} from '../../store/utils/selectors';
import { LOGIN_EMAIL, LOGIN_PASSWORD } from '../../assets/Icons';
import { login, removeAuthError } from './scenario-actions';

import './login-form.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      code: '',
      mfaEnabled: false,
      authError: 'Please log in!',
      interval: 0,
      from: props.location.state && props.location.state.from ? props.location.state.from : { pathname: '/v1' },
    };
  }

  componentDidMount() {
    this.props.removeAuthError();
    const interval = setInterval(() => {
      this.clearError();
    }, 5000);
    this.setState({ interval });
  }

  static getDerivedStateFromProps({ logoutError, error }) {
    if (logoutError) {
      return {
        authError: logoutError,
        from: { pathname: '/v1' },
      };
    }
    if (error === 'MFA required') {
      return { mfaEnabled: true };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.logoutError && prevProps.logoutError !== this.props.logoutError) {
      clearInterval(this.state.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  clearError = () => {
    clearInterval(this.state.interval);
    this.props.clearLogoutError();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, code, mfaEnabled } = this.state;
    if (mfaEnabled) {
      this.props.login({ email, password, code }, this.state.from);
    } else {
      this.props.login({ email, password }, this.state.from);
    }
  };

  removeErrorMessage = () => {
    const { logoutError, clearLogoutError } = this.props;
    if (logoutError) clearLogoutError();
    else this.setState({ authError: '' });
  };

  handleLoginAnotherWay = () => {
    const { history } = this.props;
    history.push(ROUTES.LOGIN_METHODS);
  };

  render() {
    const { authError, mfaEnabled } = this.state;
    const { isLoading, isLoadingUser, cleanLoginMessage, needsLoginMessage, logoutError, error } = this.props;

    return (
      <>
        <Helmet>
          <title>Log in on Acreto</title>
        </Helmet>
        <div className="login-form-page--content">
          <div className="login-form">
            <h2 className="title">{mfaEnabled ? 'Two-Factor Authentication (2FA)' : 'Log in'}</h2>
            {error && error !== 'MFA required' && <div className="alert alert-danger">{error}</div>}
            {logoutError && <div className="alert alert-warning">{this.props.logoutError}</div>}
            {mfaEnabled ? (
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <p className="mfa-label">Enter the six-digit code you see in your Two-Factor Authentication app.</p>
                <div className="input-container">
                  <input
                    name="code"
                    placeholder="MFA Code"
                    required
                    autoComplete="off"
                    onChange={(e) => this.setState({ code: e.target.value })}
                    onFocus={cleanLoginMessage && this.removeErrorMessage}
                  />
                </div>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isLoading || isLoadingUser}
                  sx={{ fontSize: 18, p: 2 }}
                >
                  Continue
                </Button>
              </form>
            ) : (
              <form onSubmit={this.handleSubmit} autoComplete="off">
                {needsLoginMessage && <ErrorPanel message={authError} buttonClickHandler={this.removeErrorMessage} />}
                <div className="input-container">
                  <div className="icon-container">
                    <img src={LOGIN_EMAIL} className="input-icon" alt="input-icon" />
                  </div>
                  <input
                    name="email"
                    placeholder="Email"
                    required
                    autoComplete="off"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    onFocus={cleanLoginMessage && this.removeErrorMessage}
                  />
                </div>
                <div className="input-container">
                  <div className="icon-container">
                    <img src={LOGIN_PASSWORD} className="input-icon" alt="input-icon" />
                  </div>
                  <input
                    name="password"
                    placeholder="Password"
                    type="password"
                    required
                    onChange={(e) => this.setState({ password: e.target.value })}
                    onFocus={cleanLoginMessage && this.removeErrorMessage}
                  />
                </div>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isLoading || isLoadingUser}
                  sx={{ fontSize: 18, p: 2 }}
                  data-testid="btn-login"
                >
                  Log in
                </Button>
                <div className="login-links">
                  <Link to={ROUTES.SIGN_UP_EMAIL}>
                    <span className="sign sign-link">Register here</span>
                  </Link>
                  <Link to={ROUTES.PWD_RESET_EMAIL}>
                    <span className="sign sign-link">Reset password</span>
                  </Link>
                </div>
                <Divider sx={{ my: 2 }} />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={this.handleLoginAnotherWay}
                  sx={{ fontSize: 18, mt: 2, p: 2 }}
                  data-testid="btn-sso"
                >
                  Authenticate another way
                </Button>
              </form>
            )}
          </div>
        </div>
        {isLoadingUser && <LoadingSpinner />}
      </>
    );
  }
}

LoginForm.defaultProps = {
  error: '',
};

LoginForm.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingUser: PropTypes.bool,
  login: PropTypes.func.isRequired,
  removeAuthError: PropTypes.func.isRequired,
  error: PropTypes.string,
  location: PropTypes.object.isRequired,
  clearLogoutError: PropTypes.func.isRequired,
  logoutError: PropTypes.string.isRequired,
  needsLoginMessage: PropTypes.bool.isRequired,
  cleanLoginMessage: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const loadingSelector = createLoadingSelector(['LOGIN']);
const errorSelector = createErrorMessageSelector(['LOGIN']);

const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  isLoadingUser: state.auth.loadingUser,
  error: errorSelector(state),
  logoutError: logoutErrorSelector(state),
  needsLoginMessage: needsLoginMessageSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  login: (credentials, redirect) => dispatch(login(credentials, redirect)),
  clearLogoutError: () => dispatch(clearLogoutError()),
  cleanLoginMessage: () => dispatch(cleanLoginMessage),
  removeAuthError: () => dispatch(removeAuthError()),
});

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default withRouter(ConnectedLogin);
