import React, { useMemo } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import validator from 'validator';
import PropTypes from 'prop-types';

const HtmlTooltip = styled(Tooltip)(
  () => `
    .MuiTooltip-root {
      backgroundColor: #f5f5f9;
      color: rgba(0, 0, 0, 0.87);
      max-width: 220px;
      border: 1px solid #dadde9;
    }
  `,
);

const CustomTooltip = ({ eventCategory, attribute, value, ecoId, children }) => {
  const { cusID } = useParams();

  const title = useMemo(() => {
    let queryString = eventCategory ? `eq_EventCategory=${eventCategory}&` : '';
    let attr = attribute;
    if (attribute === 'Source' || attribute === 'Destination') {
      if (validator.isIP(value, 4)) attr = `${attribute}IP`;
      else attr = `${attribute}FQDN`;
    }
    queryString += `eq_${attr}=${value}`;
    return (
      <>
        <Typography sx={{ fontSize: 16 }}>{value}</Typography>
        <Typography
          sx={{ fontSize: 14 }}
          to={`/v1/customers/${cusID}/ecosystems/${ecoId}/logs/traffic?${queryString}`}
          component={NavLink}
        >
          Find host in the Traffic Logs
        </Typography>
      </>
    );
  }, [attribute, cusID, ecoId, eventCategory, value]);

  return <HtmlTooltip title={title}>{children}</HtmlTooltip>;
};

CustomTooltip.propTypes = {
  attribute: PropTypes.string,
  value: PropTypes.string,
  eventCategory: PropTypes.string,
  ecoId: PropTypes.string,
  children: PropTypes.any,
};

export default CustomTooltip;
