import { call, put, select } from 'redux-saga/effects';

import { getLDAPToken } from './apiCalls';
import { getLDAPTokenSuccess, getLDAPTokenFailure } from '../objects/actions';

function* getLDAPTokenSaga() {
  const globalState = yield select();
  const customerUUID = globalState.customers.currentCustomer.uuid;
  const ecosystemUUID = globalState.ecosystems.currentEcosystem.uuid;
  const userUUID = globalState.user.uuid;
  try {
    const { token } = yield call(() =>
      getLDAPToken({
        customerUUID,
        ecosystemUUID,
        userUUID,
      }),
    );
    yield put(getLDAPTokenSuccess({ ecosystemUUID, token }));
  } catch (error) {
    yield put(getLDAPTokenFailure(error));
  }
}

export default getLDAPTokenSaga;
