import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Card from 'components/Card';
import Field from 'components/Field';
import { GATEWAY_TYPE } from 'enum/gateway';
import { IP_MODES } from 'enum/ip';
import Translator from 'utils/enumTranslator';
import VPNParameters from './VPNParameters';
import ConfigOptions from './ConfigOptions';

const GatewayConfig = ({ data, addresses, onUpdateWGKeys }) => {
  const { peer_id: peerId } = data;

  const type = useMemo(() => Translator.type(data.element, data.gateway_type), [data]);
  const category = useMemo(() => Translator.category(data.category), [data]);
  const serverAddress = useMemo(() => addresses.find((addr) => addr.name === 'Default Tunnel').address, [addresses]);
  const isWireguard = useMemo(() => data.gateway_type === GATEWAY_TYPE.WIREGUARD, [data]);
  const isVGateway = useMemo(() => data.gateway_type === GATEWAY_TYPE.VGATEWAY, [data]);

  return (
    <div className="modal__content padded new-gateway-survey">
      <Card header={false}>
        <div className="form-row gateway__detail">
          <div className="gateway__detail-info flex-one">
            <div className="form-row">
              <Field.Group label="Name">
                <Field.Text extraClass="object-name" text={data.name} title={data.name} />
              </Field.Group>
            </div>
            <div className="form-row">
              <Field.Group label="Category / Type">
                <Field.Text text={`${category.label} / ${type.label}`} />
              </Field.Group>
            </div>
          </div>
          {isWireguard && (
            <div className="gateway__detail-vpn flex-one">
              <div>
                <Field.Group label="Server Public Key">
                  <Field.Text text={data.server_id} />
                </Field.Group>
                <Field.Group label="Server Port">
                  <Field.Text text={data.server_port} />
                </Field.Group>
                <Field.Group label="Server Endpoint IP">
                  <Field.Text text={serverAddress.split('/')[0]} />
                </Field.Group>
              </div>
            </div>
          )}
          {!isWireguard && <VPNParameters data={data} />}
        </div>
        <div className="divider" />
        <div className="form-row gateway__detail">
          <div className="gateway__detail-ip flex-one">
            <div className="space-above">
              {!isWireguard ? (
                <Field.Group label="Connections from:">
                  <Field.Text text={data.peer_address} />
                </Field.Group>
              ) : (
                <>
                  {data.peer_address && data.peer_address !== '0.0.0.0' && (
                    <Field.Group label="WireGuard Client Public IP">
                      <Field.Text text={`${data.peer_address}:${data.peer_port || ''}`} />
                    </Field.Group>
                  )}
                  {peerId && (
                    <Field.Group label="WireGuard Client Public Key">
                      <Field.Text extraClass="break-all" text={peerId} />
                    </Field.Group>
                  )}
                </>
              )}
            </div>
            {isVGateway && data.gateway_internet_proto === IP_MODES.STATIC && (
              <div className="gateway__detail-internet">
                <Field.Group label="vGateway WAN IP" extraClass="flex-one">
                  <Field.Text text={data.gateway_internet_ip} />
                </Field.Group>
                <Field.Group label="vGateway Default Route" extraClass="flex-one">
                  <Field.Text text={data.gateway_internet_gw} />
                </Field.Group>
              </div>
            )}
            {[GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(data.gateway_type) && (
              <div className="gateway__detail-local">
                <Field.Group label={isVGateway ? 'vGateway LAN IP' : 'WireGuard Client Local IP'}>
                  <Field.Text text={data.gateway_local_ip} />
                </Field.Group>
              </div>
            )}
            <div className="space-above">
              <Field.Group label={isWireguard ? 'Local Networks' : 'Additional Local Networks'}>
                <div className="local-networks--container">
                  <table>
                    <tbody>
                      <tr>
                        <th className="network">Network</th>
                      </tr>
                      {data.peer_networks &&
                        data.peer_networks.map((net, index) => (
                          <tr key={`additional-network-index-${index}`}>
                            <td className="network">{net.network}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Field.Group>
            </div>
          </div>
          <ConfigOptions data={data} addresses={addresses} onUpdateWGKeys={onUpdateWGKeys} />
        </div>
      </Card>
    </div>
  );
};

GatewayConfig.propTypes = {
  data: PropTypes.object.isRequired,
  addresses: PropTypes.array,
  onUpdateWGKeys: PropTypes.func.isRequired,
};

const addressSelector = (state) => {
  const ecosystem = state.ecosystems.currentEcosystem;
  return ecosystem && state.objects[ecosystem.uuid]
    ? state.objects[ecosystem.uuid].objects.filter((obj) => obj.element === 'address' && obj.scope === 'public')
    : [];
};

const mapStateToProps = (state) => ({
  addresses: addressSelector(state),
});

export default connect(mapStateToProps)(GatewayConfig);
