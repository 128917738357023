export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const replaceImgURL = (body) => {
  if (body.includes(`src="/v2/customer-support`)) {
    return body.replaceAll(`src="/v2/customer-support`, `src="${process.env.REACT_APP_API_URL}/v2/customer-support`);
  }
  return body;
};
