import { number, object, string } from 'yup';

const thingValidationSchema = object({
  name: string('Enter thing name').max(250, 'Name cannot be more than 250 characters.').required('Name is required.'),
  desc: string().max(255, 'Description cannot be more than 255 characters'),
  category: object().required('Category is required.').typeError('Category is required.'),
  type: object().required(),
  // asset: object().required(),
  asset: number('Enter operational importance')
    .positive('Operational importance must be positive')
    .min(1, 'Operational importance can be minimum 1')
    .max(10, 'Operational importance can be maximum 10')
    .required('Operational importance is required.')
    .typeError('Invalid number'),
  profile: object().required('Profile Group is required.').typeError('Profile Group is required.'),
});

export default thingValidationSchema;
