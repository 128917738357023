import { rest } from './rest';

export const getSupportTicketList = ({ customerUUID, status }) =>
  rest.get(`/v2/customer-support/${customerUUID}?status=${status}`).then(({ data }) => data);

export const getSupportTicketDetail = ({ customerUUID, ticketId }) =>
  rest.get(`/v2/customer-support/${customerUUID}/ticket/${ticketId}`).then(({ data }) => data);

export const createSupportTicket = ({ customerUUID, payload }) =>
  rest.post(`/v2/customer-support/${customerUUID}`, payload).then(({ data }) => data);

export const updateSupportTicket = ({ customerUUID, ticketId, payload }) =>
  rest.post(`/v2/customer-support/${customerUUID}/ticket/${ticketId}`, payload).then(({ data }) => data);

export const closeSupportTicket = ({ customerUUID, ticketId, payload }) =>
  rest
    .put(`/v2/customer-support/${customerUUID}/ticket/${ticketId}/request-close?resolved=${payload.resolved}`)
    .then(({ data }) => data);

export const downloadAttachment = ({ customerUUID, ticketId, articleId, attachmentId }) =>
  rest
    .get(`/v2/customer-support/${customerUUID}/ticket/${ticketId}/${articleId}/${attachmentId}`)
    .then(({ data }) => data);
