import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { isEmpty } from 'lodash';

import { createGroup } from '../../store/creational-scenario-actions';
import './styles.scss';

const validationSchema = object({
  name: string('Enter group name')
    .max(36, 'Name cannot be more than 36 characters.')
    .min(3, 'Name cannot be less than 3 characters.')
    .required('Name is required.'),
});

const CreaetGroupForm = ({ field, createGroup, closeField }) => {
  const initialValues = { name: '' };

  const handleSave = (values, { resetForm }) => {
    createGroup({ ...values, field });
    closeField();
    resetForm();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSave}>
      {({ errors }) => (
        <Form className="create-group-form">
          <div className="group-input-field">
            <Field
              name="name"
              placeholder="New Profile Group"
              onSelect={(e) => e.stopPropagation()}
              onFocus={(e) => e.preventDefault()}
              className="form__group-input"
            />
            <p className="error-text">{errors.name}</p>
          </div>
          <button disabled={!isEmpty(errors)} type="submit" className="save-button btn">
            Save
          </button>
        </Form>
      )}
    </Formik>
  );
};

CreaetGroupForm.propTypes = {
  createGroup: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  closeField: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createGroup: (group) => dispatch(createGroup(group)),
});

export default connect(null, mapDispatchToProps)(CreaetGroupForm);
