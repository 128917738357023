export const REMOTE_DEVICES = [
  {
    title: 'Mobile Devices',
    options: [
      {
        label: 'ACC - iOS VPN config',
        value: 'openvpn',
      },
      {
        label: 'ACC - Android VPN config',
        value: 'openvpn',
      },
    ],
  },
  {
    title: 'Personal Computers and Servers',
    options: [
      {
        label: 'ACC - Windows VPN config',
        value: 'openvpn',
      },
      {
        label: 'ACC - macOS VPN config',
        value: 'openvpn',
      },
      {
        label: 'ACC - Linux VPN config',
        value: 'openvpn',
      },
    ],
  },
  {
    title: 'Acreto Connect Client Installer',
    options: [
      {
        label: 'Acreto Connect Client for Ubuntu 18.04 LTS',
        value: 'tlsvpn',
      },
      {
        label: 'Acreto Connect Client for ArchLinux',
        value: 'tlsvpn',
      },
    ],
  },
];
