import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ROUTES } from 'enum';
import ProtectedRoute from 'components/ProtectedRoute';
import UnauthorizedRoute from 'components/UnauthorizedRoute';

import { createErrorMessageSelector, createLoadingSelector } from 'store/utils/selectors';
import { isAuthenticatedSelector } from 'store/user/selectors';

import OAuth from 'views/SignUpForm/OAuth';
import LoginMethods from 'views/LoginForm/LoginMethods';
import CustomersForm from 'views/CustomersForm/CustomersForm';
import LoginForm from 'views/LoginForm/LoginForm';
import Eula from 'views/LoginForm/Eula/eula';
import ResetPwdEmailConfirmation from 'views/LoginForm/ResetPassword/EmailConfirmation';
import ResetPwdResetEmailSubForm from 'views/LoginForm/ResetPassword/EmailSubForm';
import ResetPwdEmailEntered from 'views/LoginForm/ResetPassword/EmailEntered';
import ResetPwdForm from 'views/LoginForm/ResetPassword/ResetForm';
import SignUpForm from 'views/SignUpForm/SignUpForm';

import { ACRETO_LOGO, LOGIN_FOOTER } from 'assets/Icons';
import './login.scss';

const configs = {
  signup: {
    linkRoute: ROUTES.SIGN_UP_EMAIL,
    authButtonTitle: 'Sign up',
  },
  login: {
    linkRoute: ROUTES.LOGIN,
    authButtonTitle: 'Log in',
  },
  resetPwd: {
    linkRoute: ROUTES.PWD_RESET,
    authButtonTitle: 'Log in',
  },
};
class Login extends Component {
  render() {
    const {
      location: { pathname },
      isAuthenticated,
    } = this.props;
    let linkFlag = false;
    if (
      [configs.signup.linkRoute, configs.login.linkRoute, configs.resetPwd.linkRoute].some((str) =>
        pathname.includes(str),
      )
    ) {
      linkFlag = true;
    }
    const currentConfig = [configs.signup.linkRoute, configs.resetPwd.linkRoute].some((str) => pathname.includes(str))
      ? configs.login
      : configs.signup;
    const { linkRoute, authButtonTitle } = currentConfig;

    return (
      <div className="login-page">
        <div className="login-page--header header">
          <img src={ACRETO_LOGO} alt="acreto-logo" className="logo" />
          {linkFlag && (
            <Link
              to={linkRoute}
              className={cx('button', {
                'signup-btn': currentConfig === configs.signup,
                'login-btn': currentConfig === configs.login,
              })}
            >
              {authButtonTitle}
            </Link>
          )}
        </div>

        <Switch>
          <Route exact path={ROUTES.LOGIN} render={LoginForm} />
          <Route exact path="/v1/auth/:provider/callback" render={OAuth} />
          <Route exact path={ROUTES.LOGIN_METHODS} render={LoginMethods} />
          <ProtectedRoute exact path={ROUTES.CUSTOMERS} component={CustomersForm} />
          <ProtectedRoute exact path={ROUTES.EULA} component={Eula} />
          <UnauthorizedRoute exact path={ROUTES.PWD_RESET_EMAIL} component={ResetPwdResetEmailSubForm} />
          <UnauthorizedRoute path={ROUTES.PWD_RESET_EMAIL_CONFIRMATION} component={ResetPwdEmailConfirmation} />
          <UnauthorizedRoute exact path={ROUTES.PWD_RESET_RESET} component={ResetPwdForm} />
          <UnauthorizedRoute path={ROUTES.PWD_RESET_EMAIL_ENTERED} component={ResetPwdEmailEntered} />
          <UnauthorizedRoute path={ROUTES.SIGN_UP} component={SignUpForm} />
          <Redirect to={!isAuthenticated ? ROUTES.LOGIN : ROUTES.NOT_FOUND} />
        </Switch>

        <div className="login-page--footer footer">
          <img
            src={LOGIN_FOOTER}
            className={cx('footer--image', {
              'slow-shake': this.props.isLoading,
            })}
            alt="footer"
          />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool,
};

const loadingSelector = createLoadingSelector(['LOGIN']);
const errorSelector = createErrorMessageSelector(['LOGIN']);

const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
});

const ConnectedLogin = connect(mapStateToProps, null)(Login);

export default withRouter(ConnectedLogin);
