import { createAction } from 'redux-actions';

import { readUserData } from 'store/sagas/apiCalls';

import * as ACTION_TYPES from './actionTypes';
import { CLEAR_DATA } from '../common-action-types';

export const setUser = createAction(ACTION_TYPES.SET_USER);
export const setEmail = createAction(ACTION_TYPES.SET_EMAIL);
export const startLoading = createAction(ACTION_TYPES.START_LOADING);
export const finishLoading = createAction(ACTION_TYPES.FINISH_LOADING);
export const setError = createAction(ACTION_TYPES.SET_ERROR);
export const clearError = createAction(ACTION_TYPES.CLEAR_ERROR);
export const setPasswordError = createAction(ACTION_TYPES.SET_PASSWORD_ERROR);
export const clearPasswordError = createAction(ACTION_TYPES.CLEAR_PASSWORD_ERROR);
export const setToken = createAction(ACTION_TYPES.SET_TOKEN);
export const confirmEmail = createAction(ACTION_TYPES.CONFIRM_EMAIL);
export const setInitCompanyName = createAction(ACTION_TYPES.SET_INIT_COMPANY_NAME);
export const completeUser = createAction(ACTION_TYPES.COMPLETE_USER);
export const updateUser = createAction(ACTION_TYPES.UPDATE_USER);
export const resetPasswordUser = createAction(ACTION_TYPES.RESET_PASSWORD);
export const configOTP = createAction(ACTION_TYPES.CONFIG_OTP);
export const setUserOTP = createAction(ACTION_TYPES.SET_USER_OTP);

export const clearUserData = createAction(CLEAR_DATA);

export function updateUserFailed(error) {
  return {
    type: ACTION_TYPES.UPDATE_USER_FAILURE,
    payload: {
      message: error,
    },
  };
}

export function emailConfirmFailed(error) {
  return {
    type: ACTION_TYPES.EMAIL_CONFIRM_FAILURE,
    payload: {
      message: error,
    },
  };
}

export const getUserInfo = () => async (dispatch, getState) => {
  try {
    const email = getState().auth.username;
    const { data } = await readUserData(email);
    dispatch(setUser(data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};
