import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem, Nav, NavItem } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { currentCustomerSelector } from 'store/user/selectors';
import CommitReminder from '../CommitReminder';
import { ROUTES, ECOSYSTEM_STATUS } from '../../enum';
import { NAVBAR_USER } from '../../assets/Icons';
import { logout, acceptCommit, acceptRollback } from '../../store/common-scenario-actions';
import history from '../../history';
import CommitPopover from './CommitPopover';

import './header-links.scss';

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    const { showCommit } = this.props;

    this.state = {
      anchorEl: null,
      showCommit,
      showReminder: false,
    };
  }

  componentDidMount() {
    this.setState({ showReminder: localStorage.getItem('commit-reminder') === 'true' });
  }

  componentDidUpdate(prevProps) {
    const { showCommit, ecosystem } = this.props;
    const commitStatus = ecosystem?.commitStatus;

    if (commitStatus !== prevProps.ecosystem?.commitStatus && commitStatus === ECOSYSTEM_STATUS.COMMIT_ERROR) {
      this.setState({ showCommit: true, anchorEl: null });
    }
    if (
      showCommit !== prevProps.showCommit ||
      (prevProps.ecosystem && ecosystem && ecosystem.status !== prevProps.ecosystem.status)
    ) {
      this.setState({
        showCommit: showCommit && ecosystem.status === ECOSYSTEM_STATUS.PENDING_CHANGES,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.ecosystem &&
      props.ecosystem.status === ECOSYSTEM_STATUS.PENDING_CHANGES &&
      props.showCommit &&
      state.showCommit
    ) {
      return {
        showCommit: true,
      };
    }
    return null;
  }

  handleAcceptCommit = (e) => {
    e.stopPropagation();
    const { acceptCommit } = this.props;
    this.setState({ anchorEl: null, showCommit: false }, () => {
      acceptCommit();
    });
  };

  handleAcceptRollback = (e) => {
    e.stopPropagation();
    const { acceptRollback } = this.props;
    this.setState({ anchorEl: null, showCommit: false }, () => {
      acceptRollback();
    });
  };

  handleOpenCommit = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseCommit = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseReminder = (checked) => {
    if (checked) {
      localStorage.setItem('commit-reminder', 'false');
    }
    this.setState({ showReminder: false });
  };

  handleCommitFromReminder = (checked) => {
    const { acceptCommit } = this.props;
    if (checked) {
      localStorage.setItem('commit-reminder', 'false');
    }
    this.setState({ showReminder: false }, () => {
      acceptCommit();
    });
  };

  render() {
    const { showCommit, showReminder } = this.state;
    const {
      currentCustomer,
      location: { pathname },
      match: { params: urlParams },
      ecosystem,
    } = this.props;
    const commitShow = showCommit && ecosystem && ecosystem.status === 'pending_changes';
    const commitDisabled =
      this.props.showCommit && (!showCommit || (ecosystem && ecosystem.status !== 'pending_changes'));
    return (
      <div>
        <Nav pullRight>
          {commitDisabled && (
            <NavItem eventKey={0} href="#" className="navbar-item">
              <div className="nav-profile commit-container">
                <div className="commit-box disabled">
                  <span>Apply Changes</span>
                </div>
              </div>
            </NavItem>
          )}
          {commitShow && (
            <>
              <NavItem eventKey={0} href="#" className="navbar-item">
                <div className="nav-profile commit-container">
                  {commitShow && <div className="tooltip">Changes not in effect until applied</div>}
                  <div className="commit-box" onClick={this.handleOpenCommit} data-testid="button-apply_changes">
                    <span>Apply Changes</span>
                    <svg height="18" width="18">
                      <circle cx="9" cy="9" r="7" stroke="white" strokeWidth="2" fill="#25C8EA" />
                      <circle cx="9" cy="9" r="5" fill="#016BE6" />
                    </svg>
                  </div>
                  <CommitPopover
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleCloseCommit}
                    onCommit={this.handleAcceptCommit}
                    onRollback={this.handleAcceptRollback}
                  />
                </div>
              </NavItem>
              {showReminder && <CommitReminder onClose={this.handleCloseReminder} />}
            </>
          )}
          <NavItem href="#" className="navbar-item last">
            <div className="flex-row nav-profile">
              <Dropdown id="dropdown-profile-options">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={NAVBAR_USER} alt="navbar-user" className="navbar-user" />
                  <p>{this.props.username}</p>
                  <ExpandMoreIcon sx={{ color: '#6d8994' }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <MenuItem className="wedge-menu-item" onSelect={() => history.push(ROUTES.PROFILE)}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    className="wedge-menu-item"
                    onSelect={() => history.push({ pathname: ROUTES.CUSTOMERS, state: { from: pathname } })}
                  >
                    {currentCustomer.uuid ? `My Organization (${currentCustomer.name})` : 'My Organization'}
                  </MenuItem>
                  {urlParams.cusID && (
                    <MenuItem
                      className="wedge-menu-item"
                      onSelect={() => history.push(`${ROUTES.CUSTOMER_SUPPORT}/${urlParams.cusID}`)}
                    >
                      Support Requests
                    </MenuItem>
                  )}
                  <MenuItem
                    className="wedge-menu-item"
                    onSelect={() => window.open(process.env.REACT_APP_KB_URL, '_blank')}
                  >
                    Help / Knowledge Base
                  </MenuItem>
                  <MenuItem className="wedge-menu-item" onSelect={this.props.logout}>
                    Logout
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

HeaderLinks.defaultProps = {
  showCommit: false,
};

HeaderLinks.propTypes = {
  showCommit: PropTypes.bool,
  username: PropTypes.string.isRequired,
  currentCustomer: PropTypes.object,
  ecosystem: PropTypes.object,
  logout: PropTypes.func.isRequired,
  acceptCommit: PropTypes.func.isRequired,
  acceptRollback: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentCustomer: currentCustomerSelector(state),
  username: state.auth.username,
  ecosystem: state.ecosystems.currentEcosystem,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  acceptCommit: () => dispatch(acceptCommit()),
  acceptRollback: () => dispatch(acceptRollback()),
});

const ConnectedHeaderLinks = connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);

export default withRouter(ConnectedHeaderLinks);
