/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';

import useCopyToClipboard from 'hooks/useCopyToClipboard';
import ContextHelp from '../ContextHelp';
import { generateToken, generateImgTimeout } from '../../views/Objects/scenario-actions';

// Image expiry day
const EXPIRY_DAY = 1;
// Image generation limit time (minute)
const LIMIT_TIME = 10;

const getInitialConfig = (gateway, option) => {
  let configOption = localStorage.getItem(gateway.uuid)
    ? JSON.parse(localStorage.getItem(gateway.uuid))[option.value]
    : null;
  let generating = false;
  if (configOption) {
    if (
      configOption.updatedAt &&
      new Date(configOption.updatedAt) < new Date(new Date().getTime() - EXPIRY_DAY * 24 * 60 * 60 * 1000)
    ) {
      const storeGateway = JSON.parse(localStorage.getItem(gateway.uuid));
      storeGateway[option.value] = null;
      configOption = null;
      localStorage.setItem(gateway.uuid, JSON.stringify(storeGateway));
    } else {
      configOption.error = '';
      generating = configOption.url
        ? false
        : new Date(configOption.createdAt) > new Date(new Date().getTime() - LIMIT_TIME * 60 * 1000);
    }
  }
  return { configOption, generating };
};

const ConfigOptions = ({ gateway, option, generateToken, generateImgTimeout, imgGenerating }) => {
  const initialConfig = useMemo(() => getInitialConfig(gateway, option), []);
  const [configOption, setConfigOption] = useState(initialConfig.configOption);
  const [generating, setGenerating] = useState(initialConfig.generating);
  const copyIconRef = useRef(null);

  const { copy } = useCopyToClipboard();

  useEffect(() => {
    const { generating, configOption } = initialConfig;
    let timer = null;
    if (generating) {
      timer = setTimeout(() => {
        generateImgTimeout(gateway, option);
      }, new Date(configOption.createdAt).getTime() + LIMIT_TIME * 60 * 1000 - new Date().getTime());
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [initialConfig]);

  useEffect(() => {
    let timer = null;
    if (localStorage.getItem(gateway.uuid)) {
      const newConfig = JSON.parse(localStorage.getItem(gateway.uuid))[option.value];
      setConfigOption(newConfig);
      if (imgGenerating) {
        setGenerating(imgGenerating);
        timer = setTimeout(() => {
          generateImgTimeout(gateway, option);
        }, new Date(newConfig.createdAt).getTime() + LIMIT_TIME * 60 * 1000 - new Date().getTime());
      }
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [imgGenerating]);

  const handleDownload = (event) => {
    event.preventDefault();
    const link = document.createElement('a');
    link.download = configOption.platform;
    link.href = configOption.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleGenerate = async (event) => {
    event.preventDefault();
    generateToken(gateway, option);
  };

  const handleCopyUrl = (url) => {
    copy(url);
    copyIconRef.current?.open({
      anchorSelect: '#copy-icon',
      content: 'Copied',
    });
    setTimeout(() => copyIconRef.current?.close(), 3000);
  };

  const renderOptions = () => {
    const isError = !!configOption?.error;

    if (configOption && configOption.url) {
      return (
        <>
          <Tooltip componentsProps={{ tooltip: { sx: { fontSize: 12 } } }} title="Generate">
            <IconButton size="small" onClick={handleGenerate} data-testid={`regenerate-${option.value}`}>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
          <Tooltip componentsProps={{ tooltip: { sx: { fontSize: 12 } } }} title="Copy URL">
            <IconButton
              size="small"
              onClick={() => handleCopyUrl(configOption.url)}
              data-testid={`copy-${option.value}`}
            >
              <ContentCopyIcon id="copy-icon" />
            </IconButton>
          </Tooltip>
          <Tooltip componentsProps={{ tooltip: { sx: { fontSize: 12 } } }} title="Download">
            <IconButton size="small" onClick={handleDownload} data-testid={`download-${option.value}`}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    }
    if (configOption && generating) {
      return (
        <Tooltip componentsProps={{ tooltip: { sx: { fontSize: 12 } } }} title="Generating...">
          <Box sx={{ pr: 1 }}>
            <CircularProgress size={12} />
          </Box>
        </Tooltip>
      );
    }
    return (
      <>
        <Tooltip
          componentsProps={{ tooltip: { sx: { fontSize: 12 } } }}
          title={isError ? configOption.error : 'Generate'}
        >
          <IconButton size="small" onClick={handleGenerate} data-testid={`generate-${option.value}`}>
            {isError ? <ReplayIcon /> : <PlayArrowIcon />}
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <div className="download__option">
      <span>{option.label}</span>
      <div className="download__functions">
        {renderOptions()}
        <ContextHelp helpLink={option.help} />
      </div>
    </div>
  );
};

ConfigOptions.propTypes = {
  option: PropTypes.object.isRequired,
  gateway: PropTypes.object.isRequired,
  generateToken: PropTypes.func.isRequired,
  generateImgTimeout: PropTypes.func.isRequired,
  imgGenerating: PropTypes.object.isRequired,
};

const mapStateToProps = (state, { gateway, option }) => ({
  imgGenerating: state.objects.imgGenerating?.[gateway.uuid]?.[option.value],
});

const mapDispatchToProps = (dispatch) => ({
  generateToken: (gateway, option) => dispatch(generateToken(gateway, option)),
  generateImgTimeout: (gateway, option) => dispatch(generateImgTimeout(gateway, option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigOptions);
