/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/LoadingSpinner';
import Header from 'components/Header/Header';
import ExpiryWarning from 'components/ExpiryWarning';
import CustomersForm from 'views/CustomersForm/CustomersForm';
import { ROUTES } from 'enum';
import { LOGIN_FOOTER } from 'assets/Icons';
import { getCustomerList, removeCurrentCustomer } from 'store/customers/actions';

import './organizations.scss';

const Organizations = ({ removeCurrentCustomer, getCustomerList }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const getCustomers = useCallback(async () => {
    try {
      setLoading(true);
      const customers = await getCustomerList();
      if (!customers || !customers.length) {
        history.push(ROUTES.ORG_INFO);
      }
      if (customers.length === 1 && (!location.state || !location.state.from)) {
        history.push(`${ROUTES.CUSTOMERS}/${customers[0].uuid}/ecosystems`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [getCustomerList, history, location.state]);

  useEffect(() => {
    removeCurrentCustomer();
    getCustomers();
  }, [getCustomers, removeCurrentCustomer]);

  // useEffect(() => {
  //   removeCurrentCustomer();
  // }, [removeCurrentCustomer]);

  return (
    <>
      <Helmet>
        <title>Organizations - Acreto</title>
      </Helmet>
      <div className="wrapper organizations-wrapper">
        <ExpiryWarning />
        <Header brandText="All Organizations" />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Box sx={{ width: '100%', bgcolor: '#f9f9f9' }}>
              <CustomersForm />
            </Box>
            <div className="d-flex">
              <Link to={ROUTES.ORG_INFO} className="button">
                Add new
              </Link>
            </div>
          </>
        )}
      </div>
      <div className="login-page--footer footer">
        <img src={LOGIN_FOOTER} className="footer--image" alt="footer" />
      </div>
    </>
  );
};

Organizations.propTypes = {
  removeCurrentCustomer: PropTypes.func.isRequired,
  getCustomerList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
});

const mapDispatchToProps = (dispatch) => ({
  removeCurrentCustomer: () => dispatch(removeCurrentCustomer()),
  getCustomerList: () => dispatch(getCustomerList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
