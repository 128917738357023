import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import UtilChart from './UtilChart';
import Counters from './Counters';
import TopUsage from './TopUsage';

const Utilization = ({ timeStamp }) => (
  <Paper sx={{ p: 3 }}>
    <Typography component="h2" sx={{ color: '#1A2E42', fontSize: 24, lineHeight: '28px' }}>
      Utilization
    </Typography>
    <Grid container spacing={3} mt={3}>
      <Grid item xs={12} lg={6} data-testid="util-chart">
        <UtilChart timeStamp={timeStamp} />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="util-count">
        <Counters />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="network-top-application">
        <TopUsage attributes="application" logAttr="Application" label="Applications" timeStamp={timeStamp} />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="network-top-user">
        <TopUsage attributes="user" logAttr="User" label="Users" timeStamp={timeStamp} />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="network-top-destination">
        <TopUsage attributes="destination_fqdn" logAttr="Destination" label="Destinations" timeStamp={timeStamp} />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="network-top-source">
        <TopUsage attributes="source_fqdn" logAttr="Source" label="Sources" timeStamp={timeStamp} />
      </Grid>
    </Grid>
  </Paper>
);

Utilization.propTypes = {
  timeStamp: PropTypes.object,
};

export default Utilization;
