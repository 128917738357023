import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, string, shape, bool } from 'prop-types';
import { CircularProgress } from '@mui/material';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import WedgeModal from '../../../components/WedgeModal';
import ErrorPanel from '../../../components/ErrorPanel';
import { emailValidationSchema } from '../../../validationSchemas';
import { LOGIN_EMAIL } from '../../../assets/Icons';
import { checkIfTheTokenIsValid, registerEmail } from '../scenario-actions';

import PrivacyPolicy from './PrivacyPolicy';
import { clearError } from '../../../store/user/actions';
import { errorSelector, isLoadingSelector, emailSelector } from '../../../store/user/selectors';
import '../sign-up-form.scss';

class EmailSubForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      isAgreed: false,
      isOpen: false,
    };
  }

  static getDerivedStateFromProps({ serverError }) {
    if (serverError) return { showError: true };
    return null;
  }

  toggleModal = (event) => {
    event.preventDefault();
    this.setState((prev) => ({ isOpen: !prev.isOpen }));
  };

  resetError = () => {
    const { serverError, clearError } = this.props;
    if (serverError) clearError();
  };

  disableError = () => {
    this.setState({
      showError: false,
    });
    const { clearError } = this.props;
    clearError();
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { isValid, registerEmail, values, serverError } = this.props;
    if (isValid !== true || serverError) {
      this.setState({ showError: true });
    } else {
      const { email } = values;
      registerEmail({ email });
    }
  };

  handleInputChange = (event) => {
    const { handleChange } = this.props;
    this.setState({
      showError: false,
    });
    handleChange(event);
  };

  render() {
    const { values, setFieldTouched, isLoading, errors, serverError } = this.props;
    const { showError, isAgreed, isOpen } = this.state;
    const error = errors.email || serverError;
    const shouldErrorBeDisplayed = showError && !!error;
    return (
      <>
        <WedgeModal
          isOpen={isOpen}
          onClose={this.toggleModal}
          title="Privacy Policy"
          size="big"
          extraHeaderClass="centralHeader"
        >
          <PrivacyPolicy />
        </WedgeModal>

        <form onSubmit={this.onSubmit} className="form-container" autoComplete="off">
          {shouldErrorBeDisplayed && <ErrorPanel message={error} buttonClickHandler={this.disableError} />}
          <div className="input-container">
            <div className="icon-container">
              <img src={LOGIN_EMAIL} className="input-icon" alt="input-icon" />
            </div>
            <input
              value={values.email}
              name="email"
              placeholder="Email"
              required
              onFocus={this.resetError}
              onChange={this.handleInputChange}
              onBlur={() => setFieldTouched('email')}
              autoComplete="off"
            />
          </div>
          <label className="checkbox-label wedge-checkbox-container">
            <input
              type="checkbox"
              checked={isAgreed}
              onChange={(ev) => this.setState({ isAgreed: ev.target.checked })}
            />
            <span className="checkmark" />
            <p className="check_title">
              I agree to Acreto
              <span className="check_title_policy" onClick={this.toggleModal}>
                Privacy Policy
              </span>
            </p>
          </label>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <input type="submit" className="signup-button" disabled={!isAgreed} value="Sign up" />
          )}
          <div>
            <span />
          </div>
        </form>
      </>
    );
  }
}

EmailSubForm.propTypes = {
  values: shape({
    email: string,
  }).isRequired,
  errors: shape({
    email: string,
  }),
  isValid: bool.isRequired,
  setFieldTouched: func.isRequired,
  handleChange: func.isRequired,
  registerEmail: func.isRequired,
  serverError: string.isRequired,
  clearError: func.isRequired,
  isLoading: bool.isRequired,
};

const mapStateToProps = (state) => ({
  serverError: errorSelector(state),
  isLoading: isLoadingSelector(state),
  error: errorSelector(state),
  email: emailSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerEmail,
      clearError,
      checkIfTheTokenIsValid,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      email: '',
    }),
    validationSchema: emailValidationSchema,
  }),
)(EmailSubForm);
