export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOADING_USER_REQUEST = 'LOADING_USER_REQUEST';
export const LOADING_USER_SUCCESS = 'LOADING_USER_SUCCESS';
export const LOADING_USER_FAILURE = 'LOADING_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RENEW_TOKEN = 'RENEW_TOKEN';
export const CLEAR_LOG_IN_MESSAGE = 'CLEAR_LOG_IN_MESSAGE';

export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const ACTIVATE_2FA_REQUEST = 'ACTIVATE_2FA_REQUEST';
export const ACTIVATE_2FA_SUCCESS = 'ACTIVATE_2FA_SUCCESS';
export const ACTIVATE_2FA_FAILURE = 'ACTIVATE_2FA_FAILURE';

export const DEACTIVATE_2FA_REQUEST = 'DEACTIVATE_2FA_REQUEST';
export const DEACTIVATE_2FA_SUCCESS = 'DEACTIVATE_2FA_SUCCESS';
export const DEACTIVATE_2FA_FAILURE = 'DEACTIVATE_2FA_FAILURE';
