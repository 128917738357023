import * as API from 'api/support';
import Notification from 'components/Notification';
import * as ACTION_TYPES from './action-types';

export const getSupportTicketList =
  ({ customerUUID, status }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_TICKET_LIST_REQUEST });
      const tickets = await API.getSupportTicketList({ customerUUID, status });
      dispatch({
        type: ACTION_TYPES.GET_TICKET_LIST_SUCCESS,
        payload: tickets,
      });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_TICKET_LIST_FAILURE, payload: { message: err } });
    }
  };

export const getSupportTicketDetail = (customerUUID, ticketId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.GET_TICKET_DETAIL_REQUEST });
    const ticket = await API.getSupportTicketDetail({ customerUUID, ticketId });
    dispatch({
      type: ACTION_TYPES.GET_TICKET_DETAIL_SUCCESS,
      payload: ticket,
    });
  } catch (err) {
    dispatch({ type: ACTION_TYPES.GET_TICKET_DETAIL_FAILURE, payload: { message: err } });
    throw err;
  }
};

export const createSupportTicket =
  ({ customerUUID, payload }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_TYPES.CREATE_TICKET_REQUEST });
      const ticket = await API.createSupportTicket({ customerUUID, payload });
      dispatch({
        type: ACTION_TYPES.CREATE_TICKET_SUCCESS,
        payload: ticket,
      });
      Notification({ message: `Ticket #${ticket.ID} has been successfully created` });
    } catch (err) {
      if (err?.response?.status !== 406) {
        dispatch({ type: ACTION_TYPES.CREATE_TICKET_FAILURE, payload: { message: err } });
      }
      throw err;
    }
  };

export const updateSupportTicket =
  ({ customerUUID, ticketId, payload }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.UPDATE_TICKET_REQUEST });
      const ticket = await API.updateSupportTicket({ customerUUID, ticketId, payload });
      const { firstName, lastName } = getState().user;
      dispatch({
        type: ACTION_TYPES.UPDATE_TICKET_SUCCESS,
        payload: { ...ticket, updated_by: `${firstName} ${lastName}`, created_at: new Date().toISOString() },
      });
    } catch (err) {
      if (err?.response?.status !== 406) {
        dispatch({ type: ACTION_TYPES.UPDATE_TICKET_FAILURE, payload: { message: err } });
      }
      throw err;
    }
  };

export const closeSupportTicket =
  ({ customerUUID, ticketId, payload }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_TYPES.CLOSE_TICKET_REQUEST });
      await API.closeSupportTicket({ customerUUID, ticketId, payload });
      dispatch({
        type: ACTION_TYPES.CLOSE_TICKET_SUCCESS,
        payload: ticketId,
      });
      Notification({ message: `Request to close the Ticket #${ticketId} has been submitted` });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.CLOSE_TICKET_FAILURE, payload: { message: err } });
    }
  };
