import Resizer from 'react-image-file-resizer';
import jwt from 'jsonwebtoken';
import _ from 'lodash';

import { THREAT_INDEX_COLOR } from 'enum/constants';
import { OBJECT_MENU_CONFIG } from '../enum/object';

export function extractCustomers(roles) {
  if (!roles || roles === ' ') {
    return [];
  }
  const split = roles.split(' ');
  const customers = split.map((part) => ({
    name: part.split('.')[0],
    uuid: part.split('.')[1],
  }));

  return _.uniqBy(customers, 'uuid');
}

export function extractCustomerFromToken(token) {
  const decodedToken = jwt.decode(token, { json: true });
  return extractCustomers(decodedToken.roles);
}

export function extractCustomer(customers) {
  return (
    customers &&
    customers.map((customer) => ({
      uuid: customer.uuid,
      name: customer.name,
    }))
  );
}

export function decodeJWT(token) {
  return jwt.decode(token, { json: true });
}

export function extractUsernameFromToken(token) {
  const decodedToken = jwt.decode(token, { json: true });
  return decodedToken.username;
}

export function pathSlugToPageName(pathname) {
  const splittedPathName = pathname.split('/');
  let slug = splittedPathName[6];
  if (splittedPathName[6] === 'objects') {
    slug =
      splittedPathName.length > 7 && OBJECT_MENU_CONFIG.findIndex(({ url }) => url === splittedPathName[7]) >= 0
        ? OBJECT_MENU_CONFIG.find(({ url }) => url === splittedPathName[7]).title
        : 'objects';
  }
  if (splittedPathName[6] === 'security') {
    if (splittedPathName[7] === 'web-filter') slug = 'Website Filtering';
    else if (splittedPathName[7] === 'decryption') slug = 'Decryption Profiles';
    else slug = 'Security Policies';
  }
  if (splittedPathName[6] === 'logs') {
    slug = splittedPathName.length > 7 ? splittedPathName[7] : 'logs';
  }
  if (splittedPathName[6] === 'settings') slug = splittedPathName[7];
  switch (slug) {
    case 'objects':
      return 'Objects';
    case 'addresstranslations':
      return 'Address Translation';
    case 'profile':
      return 'Edit Profile';
    case 'security':
      return 'Security';
    case 'logs':
      return 'Logs';
    case 'traffic':
      return 'Traffic Logs';
    case 'gateway':
      return 'Gateway Logs';
    case 'usersandthings':
      return 'User and Thing Logs';
    case 'vpn-connections':
      return 'VPN Connections';
    case 'settings':
      return 'Log Settings';
    case 'Multi-Factor Auth':
      return 'Multi-Factor Authentication (MFA)';
    case 'admin-access':
      return 'Admin Access';
    case 'guided-tasks':
      return 'Guided Tasks';
    default:
      return slug;
  }
}

export function parseResponseError(error, errorsArray = {}) {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        return errorsArray['400'] || 'Wrong request';
      case 502:
        return errorsArray['502'] || 'Bad Gateway';
      case 424:
        return errorsArray['424'] || 'This operation requires multi-factor authentication.';
      default:
        return 'Something went wrong. Please try again later.';
    }
  }
  return 'Cannot connect to our servers.';
}

export function uuidValidation(items, uuid) {
  const valid = items.find((item) => item.uuid === uuid);

  if (!valid) return false;
  return true;
}

export function calcLatng([lat, lng]) {
  const numLat = Number(lat) % 90;
  const numLng = Number(lng) % 180;
  return {
    lat: Math.floor((Math.abs(lat) / 90) % 2)
      ? numLat - 90 * (numLat === 0 ? -Math.sign(lat) : Math.sign(lat))
      : numLat,
    lng: Math.floor((Math.abs(lng) / 180) % 2)
      ? numLng - 180 * (numLng === 0 ? -Math.sign(lng) : Math.sign(lng))
      : numLng,
  };
}

export function downloadFile({ href, fileName }) {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = href;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const getReverseKeyMappingForObject = (obj) =>
  Object.keys(obj).reduce((accObject, key) => {
    const newObj = accObject;
    newObj[obj[key]] = key;
    return newObj;
  }, {});

export const escapeDSLCharacters = (str) => str.replace(/:/g, '\\:');

export const getErrorConfigs = ({ code = 400, message = '', headers = null, status = 0 }) => ({
  errorCode: code,
  errorMsg: message,
  errorHeader: headers,
  errorStatus: status,
});

export const serviceLabel = (service) => {
  const { name, protocol, dest, type, code } = service;
  if (dest && dest.end) {
    if (dest.begin === dest.end) return `${protocol}/${dest.end} (${name})`;
    return `${protocol}/${dest.begin}-${dest.end} (${name})`;
  }
  if (type) return `${protocol}/T:${type} C:${code}`;
  return `${protocol} (${name})`;
};

export const sortByName = (objects) => _.sortBy(objects, ({ name }) => _.toLower(name));

export const numberWithCommas = (x) => new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(x || 0);

export const compactNumberFormat = (value) =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 3,
  }).format(value);

export const transformDataSize = (byte, fractionalDigit = 1) => {
  if (!byte) return '0 GB';

  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  if (byte > GB) return `${(byte / GB).toFixed(fractionalDigit)} GB`;
  if (byte > MB) return `${(byte / MB).toFixed(fractionalDigit)} MB`;
  return `${(byte / KB).toFixed(fractionalDigit)} KB`;
};

export const trimObjectName = (name, len = 50) => (name.length > len ? `${name.slice(0, len)}...` : name);

export const getThreatIndex = (threatIndex) => {
  if (!threatIndex) return { label: 'None', color: THREAT_INDEX_COLOR.NONE };
  if (threatIndex > 0 && threatIndex < 3) return { label: 'Low', color: THREAT_INDEX_COLOR.LOW };
  if (threatIndex >= 3 && threatIndex < 5) return { label: 'Medium', color: THREAT_INDEX_COLOR.MEDIUM };
  if (threatIndex >= 5 && threatIndex < 7) return { label: 'High', color: THREAT_INDEX_COLOR.HIGH };
  return { label: 'Critical', color: THREAT_INDEX_COLOR.CRITICAL };
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const resizeFile = (file, maxWidth, maxHeight, format = 'png') =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(file, maxWidth, maxHeight, format, 100, 0, (uri) => resolve(uri), 'base64');
  });

export const stringIncludeSearchTerm = (str, searchTerm) => str.toLowerCase().includes(searchTerm.toLowerCase());

export const objectIncludeSearchTerm = (obj, searchTerm) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        // If value is an object, search recursively
        if (objectIncludeSearchTerm(value, searchTerm)) {
          return true;
        }
      } else if (String(value).toLowerCase().includes(searchTerm.toLowerCase())) {
        // Check if the string representation of the value includes the search term
        return true;
      }
    }
  }
  return false;
};
