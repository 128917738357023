import React from 'react';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';

const SeverityPillRoot = styled('span')(({ theme, serverity }) => {
  const backgroundColor =
    serverity === 'disabled' ? theme.palette.action.disabledBackground : alpha(theme.palette[serverity].main, 0.12);
  const color =
    // eslint-disable-next-line no-nested-ternary
    serverity === 'disabled'
      ? theme.palette.action.disabled
      : theme.palette.mode === 'dark'
      ? theme.palette[serverity].main
      : theme.palette[serverity].dark;

  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: 12,
    color,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 2,
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  };
});

const SeverityPill = (props) => {
  const { color = 'primary', children, ...other } = props;

  return (
    <SeverityPillRoot serverity={color} {...other}>
      {children}
    </SeverityPillRoot>
  );
};

SeverityPill.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'warning', 'success', 'disabled']),
};

export default SeverityPill;
