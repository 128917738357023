/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Card from '../components/Card';
import Form from '../components/Form';
import { Footer } from './commons';
import groupValidationSchema from '../validationSchemas/groupValidationSchema';

import './modals.scss';

class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'group',
      name: props.edit ? props.item.name : '',
      errors: [],
      isSubmitted: false,
    };
  }

  changeField = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onFinish = () => {
    this.setState(
      {
        errors: [],
        isSubmitted: true,
      },
      () => {
        if (this.validate()) {
          try {
            this.props.onFinish(this.state);
          } catch (err) {
            this.setState({
              errors: [this.props.edit ? 'Failed to Update Group' : 'Failed to Add Group'],
            });
          }
        }
      },
    );
  };

  validate() {
    if (!this.state.isSubmitted) {
      return true;
    }
    try {
      groupValidationSchema.validateSync(this.state, {
        abortEarly: false,
      });
      this.setState({
        errors: [],
      });
      return true;
    } catch (error) {
      this.contentElement.scrollIntoView({
        behavior: 'smooth',
      });
      this.setState({
        errors: error.errors,
      });
      return false;
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div
        ref={(node) => {
          this.contentElement = node;
        }}
      >
        <div className="modal__content padded new-address-survey">
          {errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
          <Card header={false}>
            <div className="form-row">
              <Form.Group required label="Name">
                <Form.Text
                  value={this.state.name}
                  onChange={(val) => this.changeField('name', val)}
                  placeholder="Name"
                />
              </Form.Group>
            </div>
          </Card>
        </div>
        <div className="wedge-modal__footer">
          <Footer onClick={this.onFinish} edit={this.props.edit} onDelete={this.props.onDelete} />
        </div>
      </div>
    );
  }
}

NewGroup.defaultProps = {
  edit: false,
};

NewGroup.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  edit: PropTypes.bool,
  item: PropTypes.object,
};
NewGroup.Footer = Footer;

export default NewGroup;
