import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { string } from 'yup';

export const validateNonEmptyString = (str) => {
  const schema = string().trim().min(1).required();
  return schema.isValidSync(str);
};

export const toolbarOptions = {
  options: ['inline', 'list', 'colorPicker', 'emoji'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
};

export const htmlToEditorState = (html) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  return EditorState.createEmpty();
};

export const contentStateToHtml = (content) => draftToHtml(convertToRaw(content.getCurrentContent()));

export const htmlToPlainText = (html) => htmlToEditorState(html).getCurrentContent().getPlainText() ?? '';
