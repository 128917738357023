import { object, string } from 'yup';

const publicAddressValidationSchema = object({
  name: string('Enter Allocated IP name')
    .max(250, 'Name cannot be more than 250 characters.')
    .min(3, 'Name cannot be less than 3 characters.')
    .required('Name is required.'),
  desc: string().max(255, 'Description cannot be more than 255 characters'),
});

export default publicAddressValidationSchema;
