/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import EcosystemHeader from 'components/Header/EcosystemHeader';
import ExpiryWarning from 'components/ExpiryWarning';
import LoadingSpinner from 'components/LoadingSpinner';
import EcosystemsPage from 'views/Ecosystems/Ecosystems';
import EcosystemDashboard from 'views/EcosystemDashboard';
import { fetchEcosystems } from 'views/Ecosystems/scenario-actions';
import { ECOSYSTEM_STATUS, ROUTES } from 'enum';

import './ecosystems.scss';

const Ecosystems = ({ ecosystems, fetchEcosystems, currentCustomer }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { cusID } = useParams();

  const isEcosystemAvailable = useMemo(
    () =>
      ecosystems.some((ecosystem) =>
        [ECOSYSTEM_STATUS.PENDING_CHANGES, ECOSYSTEM_STATUS.OK, ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS].includes(
          ecosystem.status,
        ),
      ),
    [ecosystems],
  );

  const [activeTab, setActiveTab] = useState(pathname.split('/').pop() === 'dashboard' ? 'summary' : 'all');
  const [loading, setLoading] = useState(true);

  const getEcosystems = useCallback(async () => {
    setLoading(true);
    await fetchEcosystems(cusID);
    setLoading(false);
  }, [fetchEcosystems, cusID]);

  useEffect(() => {
    if (pathname.split('/').at(-1) === 'summary') setActiveTab('summary');
    getEcosystems();
  }, [getEcosystems, cusID]);

  const handleChangeTab = (tab) => {
    if (!isEcosystemAvailable) {
      setActiveTab('all');
      return;
    }
    setActiveTab(tab);
    if (tab === 'summary') history.replace(`${ROUTES.CUSTOMERS}/${cusID}/ecosystems/dashboard`);
    else history.replace(`${ROUTES.CUSTOMERS}/${cusID}/ecosystems`);
  };

  const renderContents = () => {
    if (loading) {
      return <LoadingSpinner />;
    }
    if (activeTab === 'all') return <EcosystemsPage />;
    return <EcosystemDashboard />;
  };

  return (
    <>
      <Helmet>
        <title>Ecosystems - {currentCustomer.name} - Acreto</title>
      </Helmet>
      <div className="wrapper ecosystems-wrapper">
        <ExpiryWarning />
        <EcosystemHeader
          onChangeTab={handleChangeTab}
          activeTab={activeTab}
          isEcosystemAvailable={isEcosystemAvailable}
        />
        <Box sx={{ width: '100%', bgcolor: '#f9f9f9' }}>{renderContents()}</Box>
      </div>
    </>
  );
};

Ecosystems.propTypes = {
  fetchEcosystems: PropTypes.func.isRequired,
  ecosystems: PropTypes.array.isRequired,
  currentCustomer: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ecosystems: state.ecosystems.items,
  currentCustomer: state.customers.currentCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEcosystems: (cusID) => dispatch(fetchEcosystems(cusID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ecosystems);
