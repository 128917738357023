import { all, takeLatest, takeEvery } from 'redux-saga/effects';

import { REGISTER_CONTRACT_ID } from '../payment/actionTypes';
import { COMPLETE_USER, CONFIG_OTP, RESET_PASSWORD } from '../user/actionTypes';
import { GET_THING_TOKEN, GET_LDAP_TOKEN } from '../objects/action-types';

import { contractIdSaga } from './billingRegistrationSagas';
import completeUserSaga, { resetPasswordUser } from './completeUserSaga';
import getThingTokenSaga from './getThingTokenSaga';
import getLDAPTokenSaga from './getLDAPTokenSaga';
import { configUserOTP } from './userOTP';

import websocketChannelSaga from './webSocketSaga';
import { RENEW_TOKEN } from '../auth/action-types';

export default function* rootSaga() {
  yield all([
    takeLatest(REGISTER_CONTRACT_ID, contractIdSaga),
    takeLatest(COMPLETE_USER, completeUserSaga),
    takeLatest(GET_THING_TOKEN, getThingTokenSaga),
    takeLatest(GET_LDAP_TOKEN, getLDAPTokenSaga),
    takeEvery(RENEW_TOKEN, websocketChannelSaga),
    takeLatest(RESET_PASSWORD, resetPasswordUser),
    takeLatest(CONFIG_OTP, configUserOTP),
  ]);
}
