import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';

import { createTheme } from 'theme';
import CustomerSupport from 'views/CustomerSupport';
import SupportTicket from 'views/CustomerSupport/SupportTicket';
import SupportTIcketUpdate from 'views/CustomerSupport/TicketUpdate';
import { ROUTES } from './enum';
import history from './history';

import LoadingSpinner from './components/LoadingSpinner';
import GlobalLoader from './components/GlobalLoader';
import ProtectedRoute from './components/ProtectedRoute';
import FetchErrorMessage from './components/FetchErrorMessage';

import Dashboard from './layouts/Dashboard/Dashboard';
import Ecosystems from './layouts/Ecosystems/Ecosystems';
import Organizations from './layouts/Organizations/Organizations';
import Login from './layouts/Login/Login';

import NotFound from './views/NotFound/NotFound';
import UserProfile from './views/Profiles';
import Eula from './views/LoginForm/Eula/eula';
import EcosystemDetail from './views/EcosystemDetail';

import { startup } from './store/common-scenario-actions';
import { isAuthenticatedSelector } from './store/user/selectors';

const App = ({ startup, startupFinished, isLoading, isAuthenticated }) => {
  useEffect(() => {
    startup();
  }, [startup]);

  return (
    <Suspense>
      <ThemeProvider theme={createTheme()}>
        <FetchErrorMessage />
        {startupFinished ? (
          <Router history={history}>
            <Switch>
              <ProtectedRoute exact path={ROUTES.CUSTOMERS} component={Organizations} />
              <ProtectedRoute exact path={`${ROUTES.CUSTOMER_SUPPORT}/:cusID`} component={CustomerSupport} />
              <ProtectedRoute exact path={`${ROUTES.CUSTOMER_SUPPORT}/:cusID/new`} component={SupportTicket} />
              <ProtectedRoute
                exact
                path={`${ROUTES.CUSTOMER_SUPPORT}/:cusID/ticket/:ticketId`}
                component={SupportTIcketUpdate}
              />
              <ProtectedRoute exact path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems`} component={Ecosystems} />
              <ProtectedRoute exact path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/dashboard`} component={Ecosystems} />
              <ProtectedRoute exact path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/dashboard`} component={Ecosystems} />
              <ProtectedRoute
                exact
                path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/dashboard/:ecoID`}
                component={EcosystemDetail}
              />
              <ProtectedRoute path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID`} component={Dashboard} />
              <ProtectedRoute exact path={ROUTES.PROFILE} component={UserProfile} />
              <ProtectedRoute exact path={ROUTES.EULA} component={Eula} />
              <Redirect exact from="/" to={ROUTES.CUSTOMERS} />
              <Redirect exact from="/v1" to={ROUTES.CUSTOMERS} />
              <Redirect exact from={`${ROUTES.CUSTOMERS}/:cusID`} to={`${ROUTES.CUSTOMERS}/:cusID/ecosystems`} />
              <Route path="/v1/auth" component={Login} />
              <Route path={ROUTES.NOT_FOUND} component={NotFound} />
              <Route path="*" component={!isAuthenticated ? Login : NotFound} />
            </Switch>
          </Router>
        ) : (
          <GlobalLoader />
        )}
        {isLoading && <LoadingSpinner />}
      </ThemeProvider>
    </Suspense>
  );
};

App.propTypes = {
  startup: func.isRequired,
  startupFinished: bool.isRequired,
  isLoading: bool,
  isAuthenticated: bool,
};

const mapStateToProps = (state) => ({
  startupFinished: state.global.startupFinished,
  isLoading: state.global.isLoading,
  isAuthenticated: isAuthenticatedSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(startup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
