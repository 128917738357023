import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import Card from '../components/Card';
import CustomMap from '../components/CustomMap';
import Field from '../components/Field';
import ThingDevices from '../components/ThingDevices';
import { getThingToken } from '../store/objects/actions';
import { LOCATION_TYPE_OPTIONS } from '../enum/location';
import Translator from '../utils/enumTranslator';
import './modals.scss';

class ThingDetailsModal extends React.Component {
  componentDidMount() {
    const { data, token, getThingToken } = this.props;
    if (!token) {
      getThingToken(data.uuid);
    }
  }

  renderConfig() {
    const { data } = this.props;
    const type = Translator.type(data.element);
    const category = Translator.category(data.category);
    const profileGroup = data.profileGroup || 'Unknown group';

    return (
      <div className="modal__content padded new-thing-survey">
        <Card header={false}>
          <div className="thing__details">
            <div className="thing__name-type">
              <div className="form-row">
                <Field.Group extraClass="thing-uuid">
                  <Field.Text copy text={data.uuid} />
                  <Field.Text extraClass="object-name" text={data.name} title={data.name} />
                </Field.Group>
              </div>
              <div className="form-row">
                <Field.Group label="Category / Type">
                  <Field.Text text={`${category.label} / ${type.label}`} />
                </Field.Group>
              </div>
            </div>
            <div className="thing__options">
              <Field.Group label="Profile Group">
                <Field.Text text={profileGroup.name} />
              </Field.Group>
              <Field.Group label="Operational Importance">
                <Field.Text text={data.assetValue} />
              </Field.Group>
              <Field.Group label="Configuration Options">
                <ThingDevices data={data} />
                <p className="thing__contribute">
                  {'Device not listed? '}
                  <span>Contribute your configuration</span>
                </p>
              </Field.Group>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  renderDesc() {
    const { data } = this.props;
    const locaton = data.geolocation ? LOCATION_TYPE_OPTIONS[0].label : LOCATION_TYPE_OPTIONS[1].label;

    return (
      <div className="modal__content padded new-thing-survey">
        <Card header={false}>
          {data.description && (
            <Field.Group label="Description" full>
              <Field.Text text={data.description} />
            </Field.Group>
          )}
          <Field.Group label="Location">
            <Field.Text text={locaton} />
          </Field.Group>
          {!!data.geolocation && (
            <React.Fragment>
              <div className="form-row">
                <Field.Group label="Latitude" extraClass="flex-3">
                  <Field.Text text={data.geolocation.latitude} />
                </Field.Group>
                <Field.Group label="Longitude" extraClass="flex-2">
                  <Field.Text text={data.geolocation.longitude} />
                </Field.Group>
              </div>
              <div>
                <CustomMap
                  center={{
                    lat: data.geolocation.latitude,
                    lng: data.geolocation.longitude,
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }

  render() {
    return (
      <Tabs defaultActiveKey={1} id="object__tabs">
        <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
          {this.renderConfig()}
        </Tab>
        <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
          {this.renderDesc()}
        </Tab>
      </Tabs>
    );
  }
}

ThingDetailsModal.propTypes = {
  data: PropTypes.object.isRequired,
  getThingToken: PropTypes.func.isRequired,
  token: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const ecosystemId = state.ecosystems.currentEcosystem.uuid;
  const { token } = state.objects[ecosystemId].objects.find((obj) => obj.uuid === ownProps.data.uuid);

  return {
    token,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getThingToken,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ThingDetailsModal);
