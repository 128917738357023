import * as ACTION_TYPES from './action-types';

const initialState = {
  certs: [],
  profiles: [],
  newProfile: null,
  newCert: null,
};

export default function decryptionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.GET_CERTS_SUCCESS:
      return {
        ...state,
        certs: payload,
      };
    case ACTION_TYPES.CREATE_CERT_SUCCESS:
      return {
        ...state,
        certs: [...state.certs, payload],
        newCert: payload,
      };
    case ACTION_TYPES.GET_DECRYPTIONS_SUCCESS:
      return {
        ...state,
        profiles: payload,
      };
    case ACTION_TYPES.CREATE_DECRYPTION_SUCCESS:
      return {
        ...state,
        profiles: [...state.profiles, payload],
        newProfile: payload,
      };
    case ACTION_TYPES.DELETE_DECRYPTION_SUCCESS:
      return {
        ...state,
        profiles: state.profiles.filter(({ uuid }) => uuid !== payload.uuid),
        certs: state.certs.filter(({ uuid }) => uuid !== payload.cert_uuid),
      };
    case ACTION_TYPES.RESET_NEW:
      return {
        ...state,
        newProfile: null,
        newCert: null,
      };
    default:
      return state;
  }
}
