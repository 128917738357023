import dayjs from 'dayjs';

import { CONSTANTS } from '../../enum';
import { decodeJWT } from '../../utils/utils';
import * as ACTION_TYPES from './action-types';

const initialState = {
  isAuthenticated: false,
  tokenExpireAt: '',
  username: '',
  needsLogInMessage: false,
  loadingUser: false,
};

export function authReducer(state = initialState, { type, payload }) {
  const expiryTime = dayjs().add(10, 'minute').toISOString();

  switch (type) {
    case ACTION_TYPES.LOGIN_FAILURE:
      localStorage.removeItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
      return { ...initialState };
    case ACTION_TYPES.LOGIN_SUCCESS:
      localStorage.setItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY, payload.accessToken);
      // eslint-disable-next-line no-case-declarations
      const decodedToken = decodeJWT(payload.accessToken);
      return {
        ...state,
        isAuthenticated: true,
        tokenExpireAt: expiryTime,
        username: decodedToken.username,
        iss: decodedToken.iss,
      };
    case ACTION_TYPES.LOADING_USER_REQUEST:
      return { ...state, loadingUser: true };
    case ACTION_TYPES.LOADING_USER_SUCCESS:
    case ACTION_TYPES.LOADING_USER_FAILURE:
      return { ...state, loadingUser: false };
    case ACTION_TYPES.LOGOUT_USER:
      localStorage.removeItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
      return { ...initialState };
    case ACTION_TYPES.RENEW_TOKEN:
      return {
        ...state,
        tokenExpireAt: expiryTime,
      };
    case ACTION_TYPES.CLEAR_LOG_IN_MESSAGE:
      return {
        ...state,
        needsLogInMessage: false,
      };
    default:
      return state;
  }
}
