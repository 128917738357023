/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { useParams, useHistory, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Link,
  Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormik } from 'formik';
import { object, addMethod, mixed } from 'yup';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { ROUTES } from 'enum';
import PageTitleWrapper from 'components/PageTitleWrapper';
import ExpiryWarning from 'components/ExpiryWarning';
import LoadingSpinner from 'components/LoadingSpinner';
import Header from 'components/Header/Header';
import RichEditor from 'components/RichEditor';
import { closeSupportTicket, getSupportTicketDetail, updateSupportTicket } from 'store/support/actions';
import { getUserInfo } from 'store/user/actions';
import { downloadAttachment } from 'api/support';
import { downloadFile } from 'utils/utils';
import { contentStateToHtml, htmlToEditorState, htmlToPlainText, validateNonEmptyString } from 'utils/html-to-draft';

import FileZone from '../FileZone';
import FileChip from '../FileZone/FileChip';
import CloseDialog from '../CloseDialog';
import { replaceImgURL, toBase64 } from './utils';
import StatusCard from './StatusCard';
import './styles.scss';

const CATEGORIES = [
  { label: 'Connectivity', value: 'Connectivity' },
  { label: 'Authentication', value: 'Authentication' },
  { label: 'Address Translation', value: 'AddressTranslation' },
  { label: 'Decryption', value: 'Decryption' },
  { label: 'Security', value: 'Security' },
  { label: 'Content', value: 'Content' },
  { label: 'Logging', value: 'Logging' },
];

const PRIORITIES = [
  { label: 'P1 (Ecosystem-wide Outage)', value: 1 },
  { label: 'P2 (Ecosystem-wide Degradation)', value: 2 },
  { label: 'P3 (Individual Function / System / User issue)', value: 3 },
  { label: 'P4 (How do I ?)', value: 4 },
];

// Custom validation method for rich editor content
addMethod(mixed, 'contentRequired', function contentRequired(msg) {
  return this.test({
    name: 'contentRequired',
    exclusive: true,
    message: msg,
    test: (value) =>
      value.getCurrentContent().hasText() && validateNonEmptyString(htmlToPlainText(contentStateToHtml(value))),
  });
});

const validationSchema = object().shape({
  body: mixed().contentRequired('Comment is required'),
});

const TicketUpdate = ({ getSupportTicketDetail, updateSupportTicket, closeSupportTicket }) => {
  const history = useHistory();
  const { cusID, ticketId } = useParams();
  const { currentCustomer: customer } = useSelector((state) => state.customers);
  const { ticketDetail } = useSelector((state) => state.support);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isClosed = useMemo(() => ticketDetail?.state_id === 4, [ticketDetail]);
  const isPendingClosed = useMemo(() => ticketDetail?.state_id === 7, [ticketDetail]);

  const handleSave = useCallback(
    async (values, { resetForm }) => {
      try {
        const payload = { content_type: 'text/html', body: contentStateToHtml(values.body) };
        const attachments = await Promise.all(
          values.attachments.map(async (file) => {
            const base64Data = await toBase64(file);
            return { filename: file.name, data: base64Data.split(',')[1], mime_type: file.type };
          }),
        );
        payload.attachments = attachments;
        await updateSupportTicket({ customerUUID: customer.uuid, ticketId, payload });
        enqueueSnackbar('Your comment has been successfully added to the ticket', { variant: 'success' });
        resetForm();
      } catch (err) {
        if (err?.response?.status === 406) {
          const { data } = err.response;
          const infectedFiles = data?.filter((file) => !!file.is_infected).map(({ file_name: fileName }) => fileName);
          const message = `${
            infectedFiles.length > 1
              ? `These files ${infectedFiles[0]} (${infectedFiles.length - 1} more) contain`
              : `This file ${infectedFiles[0]} contains`
          } malicious content, therefore can't be uploaded`;
          enqueueSnackbar(message, { variant: 'error' });
        }
        console.log(err);
      }
    },
    [customer, history, ticketId],
  );

  const handleDownload = useCallback(
    async (articleId, attachment) => {
      try {
        setDownloading(true);
        const base64 = await downloadAttachment({
          customerUUID: customer.uuid,
          ticketId,
          articleId,
          attachmentId: attachment.id,
        });
        const href = `data:${attachment.preferences.mime_type};base64,${base64}`;
        downloadFile({ href, fileName: attachment.file_name });
      } catch (err) {
        console.log(err);
      } finally {
        setDownloading(false);
      }
    },
    [customer, ticketId],
  );

  useEffect(() => {
    const getTicketDetail = async () => {
      try {
        setLoading(true);
        await getSupportTicketDetail(cusID, ticketId);
      } catch (err) {
        console.log(err);
        if (err.response.status === 403) {
          history.push(`${ROUTES.CUSTOMER_SUPPORT}/${cusID}`);
        }
      } finally {
        setLoading(false);
      }
    };
    getTicketDetail();
  }, [cusID, getSupportTicketDetail, ticketId]);

  const { values, touched, errors, handleSubmit, setFieldValue, isSubmitting } = useFormik({
    initialValues: {
      body: htmlToEditorState(''),
      attachments: [],
    },
    validationSchema,
    onSubmit: handleSave,
  });

  const handleUploadFiles = (files) => {
    const newFiles = files.slice(uploadedFiles.length);
    setFieldValue('attachments', [...values.attachments, ...newFiles]);
    setUploadedFiles(files);
  };
  const handleRemoveFiles = (index) => {
    values.attachments.splice(index, 1);
    setFieldValue('attachments', values.attachments);
  };

  const handleClose = () => {
    if (ticketDetail.state_id === 7) {
      enqueueSnackbar('The request to close this ticket has already been received', { variant: 'error' });
      return;
    }
    setOpenCloseDialog(true);
  };

  const handleCloseTicket = useCallback(
    async (resolved) => {
      await closeSupportTicket(cusID, ticketDetail.id, { resolved });
      setOpenCloseDialog(false);
      history.push(`${ROUTES.CUSTOMER_SUPPORT}/${cusID}`);
    },
    [closeSupportTicket, cusID, ticketDetail],
  );

  return (
    <>
      <div className="wrapper organizations-wrapper">
        <ExpiryWarning />
        <Header />
        <PageTitleWrapper size="lg">
          <Box
            display="flex"
            alignItems={{ xs: 'stretch', md: 'end' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Box sx={{ maxWidth: { md: 'calc(100% - 360px)', xs: '90%' } }}>
              <Box sx={{ display: 'inline-block', mb: 1 }}>
                <Link
                  component={NavLink}
                  to={`${ROUTES.CUSTOMER_SUPPORT}/${cusID}`}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  underline="hover"
                >
                  <ArrowBackIcon sx={{ mr: 1 }} fontSize="large" />
                  <Typography variant="body1" sx={{ fontSize: 20 }}>
                    Support Tickets
                  </Typography>
                </Link>
              </Box>
              <Typography variant="h2" component="h2" sx={{ py: 1 }} noWrap title={ticketDetail?.title}>
                {ticketDetail?.title}
              </Typography>
              <Typography variant="h5" color="text.secondary">
                Created by {ticketDetail?.created_by} at&nbsp;
                {dayjs(ticketDetail?.created_at).format('DD MMM YYYY, hh:mm A')}
              </Typography>
            </Box>
            <StatusCard ticket={ticketDetail} />
          </Box>
        </PageTitleWrapper>
        {loading || !ticketDetail ? (
          <LoadingSpinner />
        ) : (
          <Container maxWidth="lg" sx={{ mb: 3 }}>
            <Box sx={{ backgroundColor: '#FFF', mb: 3, p: 5 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <InputLabel>Ecosystem Name</InputLabel>
                  <TextField
                    fullWidth
                    value={ticketDetail?.ecosystem_name}
                    disabled={!ticketDetail?.ecosystem_name}
                    data-testid="input-ecosystem_name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Ecosystem ID</InputLabel>
                  <TextField fullWidth value={ticketDetail?.ecosystem_uuid} disabled={!ticketDetail?.ecosystem_uuid} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Organization Name</InputLabel>
                  <TextField fullWidth value={customer.name} data-testid="input-org_name" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Organization ID</InputLabel>
                  <TextField fullWidth value={customer.uuid} data-testid="input-org_uuid" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    fullWidth
                    disabled
                    name="category"
                    defaultValue={ticketDetail?.category}
                    data-testid="select-category"
                  >
                    {CATEGORIES.map(({ label, value }) => (
                      <MenuItem key={value} value={value} sx={{ fontSize: 14 }}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Priority</InputLabel>
                  <Select
                    fullWidth
                    disabled
                    name="priority"
                    defaultValue={ticketDetail?.priority}
                    data-testid="select-priority"
                  >
                    {PRIORITIES.map(({ label, value }) => (
                      <MenuItem key={value} value={value} sx={{ fontSize: 14 }}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {sortBy(ticketDetail.articles, 'created_at').map((article) => (
                  <Grid key={article.created_at} item xs={12}>
                    <Box>
                      <InputLabel>
                        <strong>{article.created_by}</strong>&nbsp;(
                        {dayjs(article.created_at).format('DD MMM YYYY, hh:mm A')})
                      </InputLabel>
                      <div
                        className={classNames('article_body', { zammad: article.body.includes('<br>') })}
                        dangerouslySetInnerHTML={{ __html: replaceImgURL(article.body) }}
                        data-testid="card-article"
                      />
                    </Box>
                    {article.attachments?.length && (
                      <Box
                        sx={{
                          p: 2,
                          mt: 2,
                          border: '2px solid #D1D5D9',
                          borderRadius: '8px',
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                      >
                        {article.attachments?.map((attachment) => (
                          <Box key={`${article.id}-${attachment.id}`} sx={{ maxWidth: 160 }}>
                            <FileChip
                              label={attachment.file_name}
                              onDownload={() => handleDownload(article.id, attachment)}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Grid>
                ))}
                {!isClosed && (
                  <>
                    <Grid item xs={12} sx={{ height: '100%' }}>
                      <InputLabel>Write New Comment</InputLabel>
                      <RichEditor value={values.body} onChangeText={(value) => setFieldValue('body', value)} />
                      <FormHelperText error sx={{ fontSize: 12 }}>
                        {!!touched.body && errors.body}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <FileZone files={values.attachments} onUpload={handleUploadFiles} onRemove={handleRemoveFiles} />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="contained"
                color="error"
                size="large"
                disabled={isClosed || isPendingClosed}
                onClick={handleClose}
                data-testid="button-close_ticket"
              >
                Close this request
              </Button>
              <Stack direction="row" spacing={2}>
                <Button
                  component={NavLink}
                  to={`${ROUTES.CUSTOMER_SUPPORT}/${cusID}`}
                  size="large"
                  sx={{ fontWeight: 600, width: 156 }}
                  data-testid="button-back"
                >
                  Back
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  disabled={isClosed}
                  onClick={handleSubmit}
                  sx={{ fontWeight: 600, width: 156 }}
                  data-testid="button-submit_ticket"
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </Container>
        )}
      </div>
      {openCloseDialog && (
        <CloseDialog
          ticketId={ticketDetail.id}
          open={openCloseDialog}
          onClose={() => setOpenCloseDialog(false)}
          onCloseTicket={handleCloseTicket}
        />
      )}
      {(downloading || isSubmitting || !customer.uuid) && <LoadingSpinner />}
    </>
  );
};

TicketUpdate.propTypes = {
  updateSupportTicket: PropTypes.func,
  getSupportTicketDetail: PropTypes.func,
  closeSupportTicket: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch(getUserInfo()),
  getSupportTicketDetail: (customerUUID, ticketId) => dispatch(getSupportTicketDetail(customerUUID, ticketId)),
  updateSupportTicket: ({ customerUUID, ticketId, payload }) =>
    dispatch(updateSupportTicket({ customerUUID, ticketId, payload })),
  closeSupportTicket: (customerUUID, ticketId, payload) =>
    dispatch(closeSupportTicket({ customerUUID, ticketId, payload })),
});

export default connect(null, mapDispatchToProps)(TicketUpdate);
