import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, CircularProgress } from '@mui/material';
import { func, string, shape, bool } from 'prop-types';
import { compose } from 'recompose';
import { withFormik } from 'formik';

import ErrorPanel from '../../../components/ErrorPanel';
import { emailValidationSchema } from '../../../validationSchemas';
import { LOGIN_EMAIL } from '../../../assets/Icons';
import { passwordResetEmail } from '../../SignUpForm/scenario-actions';
import { clearError } from '../../../store/user/actions';
import { errorSelector, isLoadingSelector, emailSelector } from '../../../store/user/selectors';
import '../login-form.scss';

class PasswordResetEmailSubForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      isOpen: false,
    };
  }

  static getDerivedStateFromProps({ serverError }) {
    if (serverError) return { showError: true };
    return null;
  }

  toggleModal = (event) => {
    event.preventDefault();
    this.setState((prev) => ({ isOpen: !prev.isOpen }));
  };

  resetError = () => {
    const { serverError, clearError } = this.props;
    if (serverError) clearError();
  };

  disableError = () => {
    this.setState({
      showError: false,
    });
    const { clearError } = this.props;
    clearError();
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { isValid, passwordResetEmail, values, serverError } = this.props;
    if (!isValid || serverError) {
      this.setState({
        showError: true,
      });
    } else {
      const { email: username } = values;
      passwordResetEmail({
        username,
      });
    }
  };

  handleInputChange = (event) => {
    const { handleChange } = this.props;
    this.setState({
      showError: false,
    });
    handleChange(event);
  };

  render() {
    const { values, setFieldTouched, isLoading, errors, serverError } = this.props;
    const { showError } = this.state;
    const error = errors.email || serverError;
    const shouldErrorBeDisplayed = showError && !!error;
    return (
      <div className="login-form-page--content">
        <div className="login-form">
          <h2 className="title">Reset password</h2>
          <form onSubmit={this.onSubmit} className="form-container" autoComplete="off">
            {shouldErrorBeDisplayed && <ErrorPanel message={error} buttonClickHandler={this.disableError} />}
            <div className="input-container">
              <div className="icon-container">
                <img src={LOGIN_EMAIL} className="input-icon" alt="input-icon" />
              </div>
              <input
                value={values.email}
                name="email"
                placeholder="Email"
                required
                onFocus={this.resetError}
                onChange={this.handleInputChange}
                onBlur={(evt) => setFieldTouched(evt.target.name)}
                autoComplete="off"
              />
            </div>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={isLoading || !values.email}
                sx={{ fontSize: 18, p: 2 }}
              >
                Reset password
              </Button>
            )}
          </form>
        </div>
      </div>
    );
  }
}

PasswordResetEmailSubForm.propTypes = {
  values: shape({
    email: string,
  }).isRequired,
  errors: shape({
    email: string,
  }),
  isValid: bool.isRequired,
  setFieldTouched: func.isRequired,
  handleChange: func.isRequired,
  serverError: string.isRequired,
  clearError: func.isRequired,
  isLoading: bool.isRequired,
  passwordResetEmail: func.isRequired,
};

const mapStateToProps = (state) => ({
  serverError: errorSelector(state),
  isLoading: isLoadingSelector(state),
  error: errorSelector(state),
  email: emailSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      passwordResetEmail,
      clearError,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      email: '',
    }),
    validationSchema: emailValidationSchema,
  }),
)(PasswordResetEmailSubForm);
