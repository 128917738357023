import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Box, Divider, Grid, Hidden, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';

import { closeSupportTicket } from 'store/support/actions';
import CloseDialog from '../CloseDialog';
import StatusChip from './StatusChip';

const PRIORITY = {
  1: 'P1 (Ecosystem-wide Outage)',
  2: 'P2 (Ecosystem-wide Degradation)',
  3: 'P3 (Individual Function / System / User issue)',
  4: 'P4 (How Do I?)',
};

const TicketCard = ({ ticket, cusID, closeSupportTicket }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const menuRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [menuOpen, setMenuOpen] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);

  const { state_id: stateId } = ticket;
  const isClosed = stateId === 4;

  const handleClose = () => {
    if (stateId >= 4 && stateId <= 7) {
      enqueueSnackbar(`This Ticket #${ticket.id} is already closed`, { variant: 'warning' });
      return;
    }
    setOpenCloseDialog(true);
  };

  const handleCloseTicket = useCallback(
    async (resolved) => {
      await closeSupportTicket(cusID, ticket.id, { resolved });
      setOpenCloseDialog(false);
    },
    [closeSupportTicket, cusID, ticket.id],
  );

  const handleOpenDetail = () => {
    history.push(`${pathname}/ticket/${ticket.id}`);
  };

  return (
    <>
      <Box
        sx={{
          p: 4,
          border: '2px solid #CFEFFE',
          borderRadius: '6px',
          background: '#FFF',
          borderLeftWidth: 6,
          borderLeftColor: '#11AEFB',
        }}
      >
        <Box>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'start', sm: 'center' }}
            sx={{ mb: 1.5 }}
          >
            <Typography
              fontSize={24}
              fontWeight={600}
              component="a"
              color="text.primary"
              href={`${pathname}/ticket/${ticket.id}`}
              sx={{ textDecoration: 'underline' }}
            >
              Ticket #{ticket.id}
            </Typography>
            <Box display="flex" alignItems="center">
              <DateRangeOutlinedIcon fontSize="large" />
              <Typography fontSize={14}>
                &nbsp;Created On:&nbsp;
                {dayjs(ticket.created_at).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
              <IconButton onClick={() => setMenuOpen(true)} ref={menuRef} sx={{ ml: 1 }}>
                <MoreVertIcon fontSize="large" />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          divider={
            <>
              <Hidden mdUp>
                <Divider flexItem />
              </Hidden>
              <Hidden mdDown>
                <Divider orientation="vertical" flexItem />
              </Hidden>
            </>
          }
          justifyContent="space-evenly"
          spacing={3}
        >
          <Box sx={{ width: 320 }}>
            <Box display="flex" sx={{ height: 80 }}>
              <Typography fontSize={12} sx={{ color: '#76828E', lineHeight: '24px', minWidth: 80 }}>
                Title:
              </Typography>
              <Typography fontSize={16} noWrap title={ticket.title}>
                {ticket.title}
              </Typography>
            </Box>
            <Box display="flex" alignItems="start">
              <Typography fontSize={12} sx={{ color: '#76828E', lineHeight: '24px', minWidth: 80 }}>
                Ecosystem:
              </Typography>
              <Box>
                <Typography
                  fontSize={16}
                  component="a"
                  color="text.primary"
                  href={`/v1/customers/${cusID}/ecosystems/${ticket.ecosystem_uuid}`}
                  sx={{ textDecoration: 'underline' }}
                  data-testid="text-ecosystem_name"
                >
                  {ticket.ecosystem_name}
                </Typography>
                <Typography fontSize={12} sx={{ color: '#76828E' }} data-testid="text-ecosystem_uuid">
                  {ticket.ecosystem_uuid}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={4}>
                <Box sx={{ height: 72 }}>
                  <Typography fontSize={12} sx={{ color: '#76828E', mb: 0.5 }}>
                    Priority
                  </Typography>
                  <Typography fontSize={16} data-testid="text-priority">
                    {PRIORITY[ticket.priority]}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box sx={{ height: 72 }}>
                  <Typography fontSize={12} sx={{ color: '#76828E', mb: 0.5 }}>
                    Category
                  </Typography>
                  <Typography fontSize={16} data-testid="text-category">
                    {ticket.category}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box sx={{ height: 72 }}>
                  <Typography fontSize={12} sx={{ color: '#76828E', mb: 0.5 }}>
                    Status
                  </Typography>
                  <Box sx={{ mt: 0.25 }}>
                    <StatusChip stateId={stateId} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box sx={{ height: 72 }}>
                  <Typography fontSize={12} sx={{ color: '#76828E', mb: 0.5 }}>
                    Owner
                  </Typography>
                  <Typography fontSize={16} data-testid="text-owner">
                    {ticket.owner}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box sx={{ height: 72 }}>
                  <Typography fontSize={12} sx={{ color: '#76828E', mb: 0.5 }}>
                    Created By
                  </Typography>
                  <Typography fontSize={16} data-testid="text-created_by">
                    {ticket.created_by}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box sx={{ height: 72 }}>
                  <Typography fontSize={12} sx={{ color: '#76828E', mb: 0.5 }}>
                    Last Updated
                  </Typography>
                  <Typography fontSize={16} data-testid="text-updated_at">
                    {dayjs(ticket.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>
      {!isClosed && (
        <Menu
          disableScrollLock
          keepMounted
          anchorEl={menuRef.current}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          data-testid="ticket-menu"
        >
          <MenuItem sx={{ fontSize: 14 }} onClick={handleOpenDetail} data-testid="menu-update">
            Update
          </MenuItem>
          <MenuItem sx={{ fontSize: 14 }} onClick={handleClose} disabled={stateId === 7} data-testid="menu-close">
            Close
          </MenuItem>
        </Menu>
      )}
      {openCloseDialog && (
        <CloseDialog
          ticketId={ticket.id}
          open={openCloseDialog}
          onClose={() => setOpenCloseDialog(false)}
          onCloseTicket={handleCloseTicket}
        />
      )}
    </>
  );
};

TicketCard.propTypes = {
  ticket: PropTypes.object,
  cusID: PropTypes.string,
  closeSupportTicket: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeSupportTicket: (customerUUID, ticketId, payload) =>
    dispatch(closeSupportTicket({ customerUUID, ticketId, payload })),
});

export default connect(null, mapDispatchToProps)(TicketCard);
