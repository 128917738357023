/* eslint-disable no-undefined */
import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const MapWithControlledZoom = compose(
  withProps((props) => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: props.center,
    onCenterChange: props.onCenterChange,
  })),
  withHandlers(() => {
    const refs = {
      map: undefined,
    };

    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onCenterChanged:
        ({ onCenterChange }) =>
        () => {
          if (onCenterChange) {
            onCenterChange(refs.map.getCenter());
          }
        },
    };
  }),
  withScriptjs,
  withGoogleMap,
)((props) => (
  <GoogleMap
    center={props.center}
    zoom={12}
    clickableIcons={false}
    ref={props.onMapMounted}
    onCenterChanged={props.onCenterChanged}
  >
    <Marker position={props.center} />
  </GoogleMap>
));

export default MapWithControlledZoom;
