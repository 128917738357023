import React from 'react';
import PropTypes from 'prop-types';

import Card from '../components/Card';
import Field from '../components/Field';
import Translator from '../utils/enumTranslator';

class AllocatedIPDetail extends React.Component {
  render() {
    const { data } = this.props;
    const ipType = Translator.protocolType(data.type);

    return (
      <div className="modal__content padded new-address-survey">
        <Card header={false}>
          <div className="form-row">
            <Field.Group label="Name" extraClass="flex-3">
              <Field.Text extraClass="object-name" text={data.name} title={data.name} />
            </Field.Group>
            <Field.Group label={ipType.label} extraClass="flex-2">
              <Field.Text text={data.address} />
            </Field.Group>
          </div>
          {!!data.description && (
            <Field.Group label="Description" full>
              <Field.Text text={data.description} />
            </Field.Group>
          )}
        </Card>
      </div>
    );
  }
}

AllocatedIPDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AllocatedIPDetail;
