import { sortByName } from 'utils/utils';

import { CLEAR_DATA } from '../common-action-types';
import * as ACTION_TYPES from './action-types';
import { CREATION_POLICY_SUCCESS, FETCHING_POLICIES_SUCCESS, UPDATE_POLICY_SUCCESS } from './action-types';

const initialState = {
  urlLists: [],
  urlCategories: [],
  urlFilteringProfiles: [],
};

export function policyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCHING_POLICIES_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem]: {
          ...state[payload.ecosystem],
          policies: payload.results,
        },
      };
    }
    case CREATION_POLICY_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          policies: [payload.result, ...state[payload.ecosystem.uuid].policies],
        },
      };
    }
    case UPDATE_POLICY_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          policies: state[payload.ecosystem.uuid].policies.map((ob) =>
            ob.uuid === payload.result.uuid ? payload.result : ob,
          ),
        },
      };
    }
    case ACTION_TYPES.GET_URL_FILTERING_PROFILES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case ACTION_TYPES.CREATE_URL_FILTERING_PROFILES_SUCCESS:
    case ACTION_TYPES.UPDATE_URL_FILTERING_PROFILES_SUCCESS:
    case ACTION_TYPES.DELETE_URL_FILTERING_PROFILES_SUCCESS:
      return {
        ...state,
        urlFilteringProfiles: sortByName(payload),
      };
    case CLEAR_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
