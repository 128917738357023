import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, Typography, Grid, CircularProgress } from '@mui/material';
import get from 'lodash/get';

import ChartIcon from 'assets/img/SVG/ChartIcon.svg';
import TransferIcon from 'assets/img/SVG/DataTransferIcon.svg';
import { transformDataSize } from 'utils/utils';

const Counters = () => {
  const isLoading = useSelector((state) => get(state.ecosystems.ecosystem.loading, 'count', false));
  const {
    network: { direction: usageData },
    countData: data,
  } = useSelector((state) => state.ecosystems.ecosystem);
  const total = usageData?.[0]?.Total || 0 + usageData?.[1]?.Total || 0;

  return (
    <Box sx={{ border: '1px solid #EFF2F7', borderRadius: 1, height: '100%' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '28px',
            width: '28px',
            backgroundColor: '#7255E322',
            borderRadius: '4px',
          }}
        >
          <img src={ChartIcon} alt="count" />
        </Box>
        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: '20px', color: '#1A2E42', ml: 1 }}>
          Count
        </Typography>
      </Box>
      <Divider variant="fullWidth" />
      {isLoading ? (
        <Box sx={{ height: 200 }} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={8} sx={{ p: '12px' }}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={1}>
                  <Grid item xs={6} sx={{ borderBottom: '1px solid #EFF2F7' }}>
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 24, fontWeight: 800, color: '#1A2E42', mb: 1 }}
                        data-testid="util-count-users"
                      >
                        {data?.users}
                      </Typography>
                      <Typography sx={{ fontSize: '1em', fontWeight: 500, color: '#A3ABB3', lineHeight: '20px' }}>
                        Users
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 24, fontWeight: 800, color: '#1A2E42', mb: 1 }}
                        data-testid="util-count-things"
                      >
                        {data?.things}
                      </Typography>
                      <Typography sx={{ fontSize: '1em', fontWeight: 500, color: '#A3ABB3', lineHeight: '20px' }}>
                        Things
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={1}>
                  <Grid item xs={6} sx={{ borderBottom: '1px solid #EFF2F7', borderLeft: '1px solid #EFF2F7' }}>
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 24, fontWeight: 800, color: '#1A2E42', mb: 1 }}
                        data-testid="util-count-gateways"
                      >
                        {data?.gateways}
                      </Typography>
                      <Typography sx={{ fontSize: '1em', fontWeight: 500, color: '#A3ABB3', lineHeight: '20px' }}>
                        Gateways
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sx={{ borderLeft: '1px solid #EFF2F7' }}>
                    <Box p={1}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 24, fontWeight: 800, color: '#1A2E42', mb: 1 }}
                        data-testid="util-count-applications"
                      >
                        {data?.applications}
                      </Typography>
                      <Typography sx={{ fontSize: '1em', fontWeight: 500, color: '#A3ABB3', lineHeight: '20px' }}>
                        Applications
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ borderLeft: '1px solid #EFF2F7' }}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                px: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '28px',
                  width: '28px',
                  backgroundColor: '#7255E322',
                  borderRadius: '4px',
                }}
              >
                <Box sx={{ backgroundColor: '#7255E3', opacity: 0.1, borderRadius: '4px' }} />
                <img src={TransferIcon} alt="transfer" />
              </Box>
              <Typography variant="body1" sx={{ fontSize: 24, fontWeight: 800, color: '#1A2E42', py: 2 }}>
                {transformDataSize(total)}
              </Typography>
              <Typography sx={{ fontSize: '1em', fontWeight: 500, color: '#A3ABB3', lineHeight: '20px' }}>
                Transferred
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Counters;
