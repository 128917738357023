import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  CircularProgress,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Tooltip from '../Tooltip';
import TopChart from './TopChart';

const TopThreats = ({ attributes, logAttr, label }) => {
  const ecoId = useSelector((state) => state.ecosystems.ecosystem.uuid);
  const isLoading = useSelector((state) => get(state.ecosystems.ecosystem.loading, `threat.${attributes}`, false));
  const data = useSelector((state) => get(state.ecosystems.ecosystem, `threat.${attributes}`, []));

  const availableRows = (data || []).filter(({ Attributes }) => !!Attributes[attributes]);

  return (
    <TableContainer component={Paper} sx={{ border: '1px solid #EFF2F7', borderRadius: 1 }}>
      <Table>
        <TableHead sx={{ backgroundColor: '#FFF8EF' }}>
          <TableRow>
            <TableCell sx={{ fontSize: 14, fontWeight: 600, lineHeight: '16px', color: '#1A2E42' }} align="left">
              Top {label}
            </TableCell>
            <TableCell sx={{ fontSize: 14, fontWeight: 600, lineHeight: '16px', color: '#1A2E42' }} align="right">
              Count
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow sx={{ height: 200 }}>
              <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            availableRows.map((row) => (
              <TableRow key={row.Attributes[attributes]}>
                <TableCell align="left">
                  <Tooltip eventCategory="Threat" attribute={logAttr} ecoId={ecoId} value={row.Attributes[attributes]}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: '#1A2E42',
                        width: '100%',
                        maxWidth: '120px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {row.Attributes[attributes]}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right" sx={{ display: 'flex' }}>
                  <TopChart
                    from={new Date(row.From).getTime()}
                    to={new Date(row.To).getTime()}
                    total={row.Total}
                    values={row.Value}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TopThreats.propTypes = {
  attributes: PropTypes.string,
  logAttr: PropTypes.string,
  label: PropTypes.string,
};

export default TopThreats;
