import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import cx from 'classnames';

import { ECOSYSTEM_STATUS } from 'enum';
import { ACRETO_LOGO, ACRETO_LOGO_CIRCLE } from '../../../assets/Icons';
import HeaderLinks from '../HeaderLinks';

import './styles.scss';

const EcosystemHeader = ({ customer, ecosystems, activeTab, onChangeTab }) => {
  const isEcosystemAvailable = useMemo(
    () =>
      ecosystems.some((ecosystem) =>
        [ECOSYSTEM_STATUS.PENDING_CHANGES, ECOSYSTEM_STATUS.OK, ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS].includes(
          ecosystem.status,
        ),
      ),
    [ecosystems],
  );

  return (
    <div className="ecosystems__navbar">
      <div className="logo">
        <a href="/" className="simple-text logo-normal full-width">
          <div className="logo-img">
            <img src={customer.logo || ACRETO_LOGO} className="bigLogo" alt="logo_image" />
            <img src={customer.logo || ACRETO_LOGO_CIRCLE} className="smallLogo" alt="logo_image" />
          </div>
        </a>
      </div>
      <div className="main-panel">
        <Navbar fluid>
          <Navbar.Header>
            <Navbar.Brand>
              <div className={cx({ active: activeTab === 'all' })} onClick={() => onChangeTab('all')}>
                All Ecosystems
              </div>
            </Navbar.Brand>
            <Navbar.Brand className="ecosystem_summary">
              <div
                className={cx({ active: activeTab === 'summary', disabled: !isEcosystemAvailable })}
                onClick={() => onChangeTab('summary')}
              >
                Dashboard
              </div>
            </Navbar.Brand>
          </Navbar.Header>
          <Navbar.Collapse>
            <HeaderLinks showSearch={false} />
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

EcosystemHeader.propTypes = {
  customer: PropTypes.object,
  activeTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  ecosystems: PropTypes.array,
};

const mapStateToProps = (state) => ({
  customer: state.customers.currentCustomer,
  ecosystems: state.ecosystems.items,
});

export default connect(mapStateToProps)(EcosystemHeader);
