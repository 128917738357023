import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import ErrorPanel from '../ErrorPanel';
import { registerCustomerWithContractId, clearContractIdError } from '../../store/payment/actions';
import { contractIdErrorSelector } from '../../store/payment/selectors';
import { isLoadingSelector } from '../../store/user/selectors';

import './styles.scss';

const ContractIdRegistration = ({
  registerCustomerWithContractId,
  contractIdError,
  clearContractIdError,
  isLoading,
}) => {
  const [isAgreed, setAgreed] = useState(false);
  const [contractId, setContractId] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      payment_ref: contractId,
      payment_channel: 'contract',
    };
    registerCustomerWithContractId(payload);
  };

  return (
    <form className="form-container-contract" autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        {!!contractIdError && (
          <Grid item xs={12}>
            <ErrorPanel message={contractIdError} buttonClickHandler={() => clearContractIdError()} />
          </Grid>
        )}
        <Grid item xs={12}>
          <label>
            <span className="labelForm">Acreto Contract ID</span>
            <TextField
              fullWidth
              value={contractId}
              placeholder="Enter Acreto Contract ID"
              onChange={(e) => setContractId(e.target.value)}
              sx={{ bgcolor: '#fff' }}
              data-testid="input-contract_id"
            />
          </label>
        </Grid>
        <Grid item xs={12}>
          <label className="checkbox-label wedge-checkbox-container">
            <input type="checkbox" checked={isAgreed} onChange={() => setAgreed((prev) => !prev)} />
            <span className="checkmark" />
            <p className="check_title">I am authorized by my organization to use this contract ID</p>
          </label>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading || !isAgreed || !contractId}
            sx={{
              fontSize: 18,
              p: 1.5,
              bgcolor: '#4986c5',
              textTransform: 'unset',
              '&:hover': { bgcolor: '#4986c5' },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ContractIdRegistration.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  registerCustomerWithContractId: PropTypes.func.isRequired,
  clearContractIdError: PropTypes.func.isRequired,
  contractIdError: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  contractIdError: contractIdErrorSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  registerCustomerWithContractId: (payload) => dispatch(registerCustomerWithContractId(payload)),
  clearContractIdError: () => dispatch(clearContractIdError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractIdRegistration);
