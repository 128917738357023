/**
 * ROUTES
 */

export default {
  SIGN_UP: '/v1/auth/sign-up',
  SIGN_UP_EMAIL: '/v1/auth/sign-up/email',
  SIGN_UP_EMAIL_ENTERED: '/v1/auth/sign-up/email-entered',
  SIGN_UP_EMAIL_CONFIRMATION: '/v1/auth/sign-up/email-confirmation',

  LOGIN: '/v1/auth/login',
  LOGIN_METHODS: '/v1/auth/methods',
  EULA: '/v1/auth/eula',

  PWD_RESET: '/v1/auth/password-reset',
  PWD_RESET_EMAIL: '/v1/auth/password-reset/email',
  PWD_RESET_EMAIL_ENTERED: '/v1/auth/password-reset/email-entered',
  PWD_RESET_EMAIL_CONFIRMATION: '/v1/auth/password-reset/email-confirmation',
  PWD_RESET_RESET: '/v1/auth/password-reset/reset',

  CUSTOMERS: '/v1/customers',
  CUSTOMER_SUPPORT: '/v1/customer-support',
  PROFILE: '/v1/auth/profile',

  CONTRACT: '/v1/auth/sign-up/contract-id',
  PERSONAL_INFO: '/v1/auth/sign-up/personal-info',
  ORG_INFO: '/v1/auth/sign-up/org-info',

  NOT_FOUND: '/notFound',
};
