import get from 'lodash/get';

export const createLoadingSelector = (actions) => (state) => actions.some((action) => get(state, `loading.${action}`));

export const createErrorMessageSelector = (action) => (state) =>
  state.errors.errorKey === action[0] ? state.errors.error : '';

export const needsLoginMessageSelector = (state) => {
  const needsLoginMessage = get(state, 'auth.needsLogInMessage');
  return needsLoginMessage;
};
