import React from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { Box, Typography, Divider, CircularProgress, useTheme } from '@mui/material';
import get from 'lodash/get';

import ThreatIcon from 'assets/img/SVG/ThreatIcon.svg';
import AlertIcon from 'assets/img/SVG/AlertIcon.svg';
import ThreatChip from 'components/ThreatChip';
import { THREAT_INDEX_COLOR } from 'enum/constants';

const chartOptions = {
  chart: {
    type: 'donut',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },
  colors: ['#C0383D', '#FF771D', '#FFD88C', '#5764F5', '#8ab6fc'],
  labels: ['Critical', 'High', 'Medium', 'Low', 'Informational'],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      customScale: 0.9,
      donut: {
        size: '75%',
        labels: {
          total: {
            show: true,
            color: '#A3ABB3',
          },
        },
      },
    },
  },
};

const EmptyChart = () => (
  <Box sx={{ position: 'relative', display: 'inline-flex', py: 2, justifyContent: 'center' }}>
    <Box sx={{ position: 'relative' }}>
      {/** background progress bar */}
      <CircularProgress
        value={100}
        variant="determinate"
        size={108}
        thickness={4}
        sx={{ color: (theme) => theme.palette.grey[200] }}
      />
      {/** actual progress bar */}
      <CircularProgress
        value={0}
        variant="determinate"
        size={108}
        thickness={4}
        sx={{ color: '#ccc', position: 'absolute', left: 0 }}
      />
    </Box>
  </Box>
);

const ThreatChart = () => {
  const theme = useTheme();

  const isLoading = useSelector((state) => get(state.ecosystems.ecosystem.loading, 'threat.event_severity', false));
  const {
    threat: { event_severity: data },
    countData: counters,
  } = useSelector((state) => state.ecosystems.ecosystem);

  const criticalData = (data || []).find(({ Attributes }) => Attributes.event_severity === 'Critical');
  const highData = (data || []).find(({ Attributes }) => Attributes.event_severity === 'High');
  const mediumData = (data || []).find(({ Attributes }) => Attributes.event_severity === 'Medium');
  const lowData = (data || []).find(({ Attributes }) => Attributes.event_severity === 'Low');
  const infoData = (data || []).find(({ Attributes }) => Attributes.event_severity === 'Informational');

  const series = [
    criticalData?.Total || 0,
    highData?.Total || 0,
    mediumData?.Total || 0,
    lowData?.Total || 0,
    infoData?.Total || 0,
  ];

  return (
    <Box
      sx={{ border: '1px solid #E3E6E8', borderRadius: 1, height: '258px' }}
      display="flex"
      justifyContent="space-between"
    >
      {isLoading ? (
        <Box sx={{ height: '100%' }} display="flex" alignItems="center" justifyContent="center" flex={1}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box p={2} flex={1} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" mb="12px">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '28px',
                  width: '28px',
                  backgroundColor: '#FFEED8',
                  opacity: 0.8,
                  borderRadius: '4px',
                }}
              >
                <img src={ThreatIcon} alt="threat" />
              </Box>
              <Typography sx={{ color: '#1A2E42', fontSize: 16, fontWeight: 600, lineHeight: '20px', ml: 1 }}>
                Threats
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ height: '100%' }}>
              <Box>
                {data?.length ? (
                  <Chart options={chartOptions} series={series} type="donut" height="80%" />
                ) : (
                  <EmptyChart />
                )}
                <Typography data-testid="text-total_threats" sx={{ fontSize: 20, fontWeight: 500, lineHeight: '24px' }}>
                  {counters?.['total.threats']}
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: '16px', color: '#A3ABB3' }}>
                  Total Threats
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="space-around" pl={1}>
                <ThreatChip
                  color={THREAT_INDEX_COLOR.CRITICAL}
                  text={criticalData?.Total || 0}
                  textProps={{ fontSize: 16, fontWeight: 500, lineHeight: '20px' }}
                  label="Critical"
                  testId="critical"
                  spacing={8}
                />
                <ThreatChip
                  color={THREAT_INDEX_COLOR.HIGH}
                  text={highData?.Total || 0}
                  textProps={{ fontSize: 16, fontWeight: 500, lineHeight: '20px' }}
                  label="High"
                  testId="high"
                  spacing={8}
                />
                <ThreatChip
                  color={THREAT_INDEX_COLOR.MEDIUM}
                  text={mediumData?.Total || 0}
                  textProps={{ fontSize: 16, fontWeight: 500, lineHeight: '20px' }}
                  label="Medium"
                  testId="medium"
                  spacing={8}
                />
              </Box>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              [theme.breakpoints.between('xl', 1920)]: {
                maxWidth: 108,
              },
              [theme.breakpoints.between('sm', 'lg')]: {
                width: '40%',
              },
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '28px',
                  width: '28px',
                  backgroundColor: '#FFEED8',
                  opacity: 0.8,
                  borderRadius: '4px',
                }}
              >
                <img src={AlertIcon} alt="alert" />
              </Box>
              <Typography sx={{ color: '#1A2E42', fontSize: 16, fontWeight: 600, lineHeight: '20px', ml: 1 }}>
                Blocks
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ color: '#1A2E42', fontSize: 24, fontWeight: 800, lineHeight: '24px' }}
                data-testid="text-access_blocks"
              >
                {counters?.['blocked.access']}
              </Typography>
              <Typography sx={{ color: '#A3ABB3', fontSize: 14, fontWeight: 500, lineHeight: '16px', mt: 1 }}>
                Access Blocks
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Typography
                sx={{ color: '#1A2E42', fontSize: 24, fontWeight: 800, lineHeight: '24px' }}
                data-testid="text-threat_blocks"
              >
                {counters?.['blocked.threats']}
              </Typography>
              <Typography sx={{ color: '#A3ABB3', fontSize: 14, fontWeight: 500, lineHeight: '16px', mt: 1 }}>
                Threat Blocks
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ThreatChart;
