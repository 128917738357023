import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { toolbarOptions } from 'utils/html-to-draft';

import './style.scss';

const RichEditor = ({ value, onChangeText }) => (
  <Editor
    toolbar={toolbarOptions}
    editorState={value}
    wrapperClassName="input-text-editor"
    onEditorStateChange={onChangeText}
  />
);

RichEditor.propTypes = {
  value: PropTypes.any,
  onChangeText: PropTypes.func,
};

export default RichEditor;
