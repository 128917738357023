import get from 'lodash/get';
import { createSelector } from 'reselect';

export const userReducer = (state) => get(state, ['user']);
export const authReducer = (state) => get(state, ['auth']);
export const customersReducer = (state) => get(state, ['customers']);

export const emailSelector = createSelector(userReducer, (user) => get(user, ['email']));
export const isLoadingSelector = createSelector(userReducer, (user) => get(user, ['isLoading']));
export const errorSelector = createSelector(userReducer, (user) => get(user, ['error']));
export const pwdErrorSelector = createSelector(userReducer, (user) => get(user, ['pwdError']));

export const isAuthenticatedSelector = createSelector(authReducer, (auth) => get(auth, ['isAuthenticated']));

export const isActivatedSelector = createSelector(userReducer, (user) => get(user, ['isActivated']));

export const isEmailConfirmedSelector = createSelector(userReducer, (user) => get(user, ['isEmailConfirmed']));

export const isEulaAcceptedSelector = createSelector(userReducer, (user) => get(user, ['eulaAccepted']));

export const initialCompanyNameSelector = createSelector(userReducer, (user) => get(user, ['initialCompanyName']));

export const customersSelector = createSelector(customersReducer, ({ customers }) => customers || []);

export const currentCustomerSelector = createSelector(customersReducer, ({ currentCustomer }) => currentCustomer);
