import { object, string } from 'yup';

const groupValidationSchema = object({
  name: string('Enter group name')
    .max(36, 'Name cannot be more than 36 characters.')
    .min(3, 'Name cannot be less than 3 characters.')
    .required('Name is required.'),
});

export default groupValidationSchema;
