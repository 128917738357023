import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Box, Divider, Grid, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import './styles.scss';

const CardHeader = ({ timeWindow }) => (
  <Grid container spacing={4} columns={18}>
    <Grid item xs={3}></Grid>
    <Grid item xs={6}>
      <Typography variant="body1" fontWeight="bold" fontSize={22} textAlign="center">
        Usage
      </Typography>
      <Divider sx={{ backgroundColor: '#7255E3', height: 2, mt: 1 }} />
      <Box pt={2} px={1} display="flex" justifyContent="space-between" color="#A3ABB3" fontSize={14}>
        <Box>
          <Typography component="span" sx={{ mr: 3 }}>
            X: {timeWindow} hours
          </Typography>
          <Typography component="span">Y: Mbps</Typography>
        </Box>
        <Box display="flex">
          <Box display="flex" alignItems="center" mr={3}>
            <Box sx={{ backgroundColor: '#7255E3', mr: 1, borderRadius: '2px' }} width={10} height={10} />
            In
          </Box>
          <Box display="flex" alignItems="center">
            <Box sx={{ backgroundColor: '#75A0AE', mr: 1, borderRadius: '2px' }} width={10} height={10} />
            Out
          </Box>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="body1" fontWeight="bold" fontSize={22} textAlign="center">
        Blocked
      </Typography>
      <Divider sx={{ backgroundColor: '#FF771D', height: 2, mt: 1, mb: 2 }} />
      <Typography textAlign="center">Total</Typography>
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body1" fontWeight="bold" fontSize={22} textAlign="center">
        Threats
      </Typography>
      <Divider sx={{ backgroundColor: '#FF771D', height: 2, mt: 1, mb: 2 }} />
      <Grid container spacing={2} color="#A3ABB3">
        <Grid item xs={6}>
          <Typography textAlign="center">Critical</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="center">High</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={4}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1" fontWeight="bold" fontSize={22} textAlign="center">
          Threat Index
        </Typography>
        <HelpIcon sx={{ ml: 1 }} data-for="threat-tooltip" data-tip="threat-tooltip" />
        <ReactTooltip id="threat-tooltip" type="info" effect="solid" place="bottom" className="threatTooltip">
          Threat Index is a formulation that reflects the type, severity, perspective (direction) and volume of threats.
        </ReactTooltip>
      </Box>
      <Divider sx={{ backgroundColor: '#FF7B80', height: 2, mt: 1, mb: 2 }} />
      <Grid container spacing={2} color="#A3ABB3">
        <Grid item xs={4}>
          <Typography textAlign="center">Internal</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="center">External</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="center">Combined</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

CardHeader.propTypes = {
  timeWindow: PropTypes.number,
};

export default CardHeader;
