import { oAuthCallback } from 'api/auth';
import { extractUsernameFromToken } from 'utils/utils';
import { loginSuccess } from 'store/auth/actions';
import { CONSTANTS, ROUTES } from '../../enum';
import { createResponseErrorMessage, extractResponseErrorStatus } from '../../utils/responseErrorHandler';
import { createUser, readUserData, resetPasswordEmail } from '../../store/sagas/apiCalls';
import {
  startLoading,
  finishLoading,
  setError,
  setEmail,
  setUser,
  confirmEmail,
  emailConfirmFailed,
} from '../../store/user/actions';
import history from '../../history';

export const registerEmail =
  ({ email }) =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      await createUser({ email });
      dispatch(setEmail(email));
      history.replace(ROUTES.SIGN_UP_EMAIL_ENTERED);
    } catch (error) {
      const status = extractResponseErrorStatus(error);
      const specificErrorHandler = {
        409: 'This email is already taken!',
        400: 'Wrong payload, missing email!',
        default: 'Error while registering email!',
      };
      const errorMessage = createResponseErrorMessage({
        status,
        specificErrorHandler,
      });
      dispatch(setError(errorMessage));
    } finally {
      dispatch(finishLoading());
    }
  };

export const checkIfTheTokenIsValid =
  ({ token, username }) =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      localStorage.setItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY, token);
      const data = await readUserData(username);
      dispatch(setUser(data));
      dispatch(confirmEmail());
      if (data.firstName) {
        history.replace('/v1');
      } else {
        history.replace(ROUTES.PERSONAL_INFO);
      }
    } catch (error) {
      const status = extractResponseErrorStatus(error);
      const specificErrorHandler = {
        403: 'Incorrect link!',
        401: 'Your activation link is invalid or expired. Please contact support to receive new activation link.',
        400: 'Wrong payload, missing username',
        404: 'User not found',
        default: 'Error while confirming email!',
      };
      const errorMessage = createResponseErrorMessage({
        specificErrorHandler,
        status,
      });
      dispatch(setError(errorMessage));
      dispatch(emailConfirmFailed(error));
    } finally {
      dispatch(finishLoading());
    }
  };

export const removeAuthError = () => (dispatch) => {
  dispatch(setError(''));
};

export const passwordResetEmail =
  ({ username }) =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      await resetPasswordEmail({ username });
      dispatch(setEmail(username));
      history.replace(ROUTES.PWD_RESET_EMAIL_ENTERED);
    } catch (error) {
      const status = extractResponseErrorStatus(error);
      const specificErrorHandler = {
        409: 'This email is already taken!',
        400: 'Wrong payload, missing email!',
        default: 'Error while sending you the password reset Link!',
      };
      const errorMessage = createResponseErrorMessage({
        status,
        specificErrorHandler,
      });
      dispatch(setError(errorMessage));
    } finally {
      dispatch(finishLoading());
    }
  };

export const redirectToPWDReset =
  ({ token, username }) =>
  async (dispatch) => {
    localStorage.setItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY, token);
    dispatch(setEmail(username));
    dispatch(confirmEmail());
    history.replace(ROUTES.PWD_RESET_RESET);
  };

export const oAuthProviderCallback =
  ({ provider, query }) =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      const authResult = await oAuthCallback({ provider, query });
      dispatch(loginSuccess(authResult));
      const username = extractUsernameFromToken(authResult.accessToken);
      const data = await readUserData(username);
      dispatch(setUser(data));
      dispatch(confirmEmail());
      if (data.firstName) {
        history.replace(ROUTES.CUSTOMERS);
      } else {
        history.replace(ROUTES.PERSONAL_INFO);
      }
    } catch (error) {
      const status = extractResponseErrorStatus(error);
      const specificErrorHandler = {
        403: 'Incorrect link!',
        401: 'Your activation link is invalid or expired. Please contact support to receive new activation link.',
        400: 'Wrong payload, missing username',
        404: 'User not found',
        default: 'Error while confirming email!',
      };
      const errorMessage = createResponseErrorMessage({
        specificErrorHandler,
        status,
      });
      dispatch(setError(errorMessage));
      dispatch(emailConfirmFailed(error));
      throw error;
    } finally {
      dispatch(finishLoading());
    }
  };
