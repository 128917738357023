import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import { Field, Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import { createRole } from '../../store/creational-scenario-actions';
import './style.scss';

const validationSchema = object({
  name: string('Enter role name')
    .min(3, 'Name cannot be less than 3 characters.')
    .max(250, 'Name cannot be more than 250 characters.')
    .required('Name is required.'),
});

const CreateRoleForm = ({ createRole, field, closeField }) => {
  const initialValues = { name: '' };

  const handleSave = (values, { resetForm }) => {
    createRole({
      roleName: values.name,
      field,
    });
    closeField();
    resetForm();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSave}>
      {({ errors }) => (
        <Form className="create-group-form">
          <div className="group-input-field">
            <Field
              name="name"
              placeholder="New Role"
              onSelect={(e) => e.stopPropagation()}
              onFocus={(e) => e.preventDefault()}
              className="form__group-input"
            />
            <p className="error-text">{errors.name}</p>
          </div>
          <button disabled={!isEmpty(errors)} type="submit" className="save-button btn">
            Save
          </button>
        </Form>
      )}
    </Formik>
  );
};

CreateRoleForm.propTypes = {
  createRole: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  closeField: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createRole: (role) => dispatch(createRole(role)),
});

export default connect(null, mapDispatchToProps)(CreateRoleForm);
