import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Popover } from '@mui/material';
import { useSnackbar } from 'notistack';

import Field from 'components/Field';
import GatewayDevices from 'components/GatewayDevices';
import Translator from 'utils/enumTranslator';
import { GATEWAY_TYPE } from 'enum/gateway';
import { LOCATION_TYPE_OPTIONS, DEFAULT_LOCATION } from 'enum/location';
import { generateKeypair } from 'utils/wireguardUtils';
import { acceptCommit } from 'store/common-scenario-actions';

import { downloadConfig } from './utils';

const ConfigOptions = ({ data, acceptCommit, addresses, ecosystem, onUpdateWGKeys }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleGenerateClientKeys = async (event) => {
    event.preventDefault();
    setAnchorEl(null);
    const { publicKey: peerId, privateKey: acretoPrivateKey } = generateKeypair();
    try {
      await onUpdateWGKeys(
        {
          ...data,
          peer_id: peerId,
          acretoPrivateKey,
          category: Translator.category(data.category),
          uuid: data.uuid,
          location: !data.geolocation ? LOCATION_TYPE_OPTIONS[1] : LOCATION_TYPE_OPTIONS[0],
          lat: (!!data.geolocation && data.geolocation.latitude) || DEFAULT_LOCATION.LAT,
          lng: (!!data.geolocation && data.geolocation.longitude) || DEFAULT_LOCATION.LNG,
        },
        false, // commit
        true, // preserveCurrModalState,
      );
    } catch (err) {
      const errMsg = err.response?.data?.message || 'Failed to generate new configuration';
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  };

  const openClientKeyPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeClientKeyPop = () => {
    setAnchorEl(null);
  };

  if (data.gateway_type !== GATEWAY_TYPE.WIREGUARD) {
    return (
      <div className="gateway__detail-config flex-one space-above">
        <Field.Group label="Configuration Options">
          <GatewayDevices data={data} />
        </Field.Group>
      </div>
    );
  }

  const handleDownload = () => {
    downloadConfig({ gateway: data, addresses, ecosystem });
  };

  return (
    <div className="gateway__detail-config flex-one space-above">
      <Field.Group label="Configuration">
        <div>
          {data.server_id ? (
            <div>
              {!data.acretoPrivateKey ? (
                <div className="key-actions">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openClientKeyPop}
                    sx={{ textTransform: 'unset', fontSize: 16, mt: 2 }}
                  >
                    Generate new private and public key
                  </Button>
                  <Popover
                    id="popover-clientKey"
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={closeClientKeyPop}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box className="popover-clientkey">
                      <p>
                        Note: Once you generate a new configuration, the old configuration and keys will be replaced.
                      </p>
                      <p>
                        Your active WireGuard connections will disconnect until the new configuration is installed and
                        applied.
                      </p>
                      <p className="confirm">Are you sure?</p>
                      <div className="generate_buttons">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleGenerateClientKeys}
                          data-testid="generate-clientkey"
                        >
                          Yes
                        </Button>
                        <Button variant="outlined" onClick={closeClientKeyPop}>
                          No
                        </Button>
                      </div>
                    </Box>
                  </Popover>
                </div>
              ) : (
                <div className="key-actions">
                  <div className="space-above-8 pb-5">
                    <p>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownload}
                        sx={{ textTransform: 'unset', fontSize: 16, mt: 1 }}
                      >
                        Download configuration
                      </Button>
                    </p>
                  </div>
                  <p>Download and install the configuration in the WireGuard client app.</p>
                  <p className="commit-msg">
                    The new configuration will be activated once you &nbsp;
                    <span className="link" onClick={acceptCommit}>
                      apply your changes
                    </span>
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <p className="commit-msg">
                <span className="link" onClick={acceptCommit}>
                  Apply your changes
                </span>
                &nbsp;to generate configuration
              </p>
            </div>
          )}
        </div>
      </Field.Group>
    </div>
  );
};

ConfigOptions.propTypes = {
  data: PropTypes.object,
  onUpdateWGKeys: PropTypes.func,
  acceptCommit: PropTypes.func,
  addresses: PropTypes.array,
  ecosystem: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ecosystem: state.ecosystems.currentEcosystem,
});

const mapDispatchToProps = (dispatch) => ({
  acceptCommit: () => dispatch(acceptCommit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigOptions);
