import { downloadFile } from 'utils/utils';

export const downloadConfig = ({ gateway, addresses, ecosystem }) => {
  const {
    name,
    server_id: serverId,
    server_port: serverPort,
    acretoPrivateKey,
    gateway_local_ip: gatewayLocalIP,
    peer_address: peerAddr,
    peer_port: peerPort,
  } = gateway;
  const serverAddress = addresses.find((addr) => addr.name === 'Default Tunnel').address;
  let content = `[Interface]
Address = ${gatewayLocalIP}
PrivateKey = ${acretoPrivateKey}`;
  if (peerAddr && peerPort) {
    content = content.concat(`
ListenPort = ${peerPort}`);
  }
  content = content.concat(`
[Peer]
PublicKey = ${serverId}
AllowedIPs = 0.0.0.0/1,128.0.0.0/1
Endpoint = ${serverAddress.split('/')[0]}:${serverPort}`);

  const downloadData = new Blob([content], { type: 'text/plain' });
  const blobUrl = URL.createObjectURL(downloadData);
  const fileName = `${ecosystem.name}-${name}.conf`;
  downloadFile({ href: blobUrl, fileName });
};
