import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmFooter } from './commons';

const UpgradeEcosystem = ({ onYes, onNo }) => (
  <div>
    <div className="action__confirm">
      <p>The upgrade process can take up to 5 minutes.</p>
      <p>During the upgrade, the Ecosystem will be temporarily unavailable.</p>
      <p>
        Please check your Gateways and Things to ensure all your systems are functioning as expected after the upgrade.
      </p>
      <br />
      <p>Do you want to perform the upgrade now?</p>
    </div>

    <div className="wedge-modal__footer">
      <ConfirmFooter onYes={onYes} onNo={onNo} />
    </div>
  </div>
);

UpgradeEcosystem.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};

export default UpgradeEcosystem;
