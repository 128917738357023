import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import ThreatChart from './ThreatChart';
import ThreatIndex from './ThreatIndex';
import TopThreats from './TopThreats';

const Security = ({ timeStamp }) => (
  <Paper sx={{ p: 3 }}>
    <Typography component="h2" sx={{ color: '#1A2E42', fontSize: 24, lineHeight: '28px' }}>
      Security
    </Typography>
    <Grid container spacing={3} mt={3}>
      <Grid item xs={12} lg={6} data-testid="threat-chart">
        <ThreatChart timeStamp={timeStamp} />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="threat-index">
        <ThreatIndex timeStamp={timeStamp} />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="threat-top-details">
        <TopThreats timeStamp={timeStamp} logAttr="Details" attributes="details" label="Threats" />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="threat-top-user">
        <TopThreats timeStamp={timeStamp} logAttr="User" attributes="user" label="User Threats" />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="threat-top-destination">
        <TopThreats timeStamp={timeStamp} logAttr="Destination" attributes="destination_fqdn" label="Threat Targets" />
      </Grid>
      <Grid item xs={12} lg={6} data-testid="threat-top-source">
        <TopThreats timeStamp={timeStamp} logAttr="Source" attributes="source_fqdn" label="Attack Sources" />
      </Grid>
    </Grid>
  </Paper>
);

Security.propTypes = {
  timeStamp: PropTypes.object,
};

export default Security;
