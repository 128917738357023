/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { decodeJWT } from 'utils/utils';
import { CONSTANTS, ROUTES } from '../../enum';
import { customersSelector, isAuthenticatedSelector } from '../../store/user/selectors';

const UnauthorizedRoute = ({ component: Component, ...rest }) => {
  const {
    isAuthenticated,
    customers,
    curCustomer,
    location: { pathname },
    user,
  } = rest;
  const internalUrls = [ROUTES.CONTRACT, ROUTES.PERSONAL_INFO, ROUTES.ORG_INFO];
  const isInternalUrl = internalUrls.some((url) => pathname.includes(url));
  const accessToken = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (pathname.includes(ROUTES.PERSONAL_INFO)) {
            const jwtData = decodeJWT(accessToken);
            if (jwtData.iss !== 'userpass' && (!user.firstName || !user.lastName)) {
              return <Component {...props} jwtData={jwtData} />;
            }
          }
          if (pathname.includes(ROUTES.ORG_INFO) || pathname.includes(ROUTES.CONTRACT)) {
            return pathname.includes(ROUTES.ORG_INFO) || curCustomer.name ? (
              <Component {...props} />
            ) : (
              <Redirect push to={ROUTES.ORG_INFO} />
            );
          }
          if (!customers || !customers.length) {
            if (pathname.includes(ROUTES.CONTRACT)) {
              return curCustomer.name ? <Component {...props} /> : <Redirect push to={ROUTES.ORG_INFO} />;
            }
            return <Redirect push to={ROUTES.ORG_INFO} />;
          }
          return <Redirect push to="/v1" />;
        }
        if (isInternalUrl) {
          if (accessToken) {
            if (pathname.includes(ROUTES.CONTRACT)) {
              return curCustomer.name ? <Component {...props} /> : <Redirect push to={ROUTES.ORG_INFO} />;
            }
            return <Component {...props} />;
          }
          return <Redirect push to={ROUTES.SIGN_UP_EMAIL} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

UnauthorizedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  customers: customersSelector(state),
  curCustomer: state.customers.currentCustomer,
  user: state.user,
});

export default compose(withRouter, connect(mapStateToProps))(UnauthorizedRoute);
