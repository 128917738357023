/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { sum } from 'lodash';

import { numberWithCommas } from 'utils/utils';

const ThreatChart = ({ data }) => {
  const theme = useTheme();

  const xLables = useMemo(() => (data.critical || data.high || []).map(({ timeStamp }) => timeStamp), [data]);

  const options = useMemo(
    () => ({
      chart: {
        background: 'transparent',
        toolbar: { show: false },
        sparkline: { enabled: true },
        zoom: { enabled: false },
      },
      colors: ['#FF9140'],
      dataLabels: { enabled: false },
      theme: { mode: theme.palette.mode },
      stroke: {
        show: true,
        colors: ['#FF9140'],
        curve: 'smooth',
        width: 2,
      },
      legend: { show: false },
      xaxis: {
        type: 'datetime',
        categories: xLables,
        labels: { show: false },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: false,
        // x: {
        //   format: 'hh:mm',
        // },
        // y: {
        //   formatter: (value) => value.toFixed(3),
        // },
      },
    }),
    [xLables],
  );

  const HighData = useMemo(
    () => [
      {
        name: 'High',
        data: data.high ? data.high.map(({ value }) => value) : [],
      },
    ],
    [],
  );

  const criticalData = useMemo(
    () => [
      {
        name: 'Critical',
        data: data.critical ? data.critical.map(({ value }) => value) : [],
      },
    ],
    [],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {criticalData[0].data?.length ? (
          <>
            <Box sx={{ maxHeight: 80 }}>
              <Chart options={options} series={criticalData} type="area" height="100%" />
            </Box>
            <Typography textAlign="center" fontSize={14} fontWeight="bold" data-testid="threat-critical">
              {numberWithCommas(sum(criticalData[0].data))}
            </Typography>
          </>
        ) : (
          <Typography
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
            sx={{ height: '100%', fontSize: '16', fontWeight: 'bold' }}
            data-testid="threat-critical"
          >
            0
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        {HighData[0].data?.length ? (
          <>
            <Box sx={{ maxHeight: 80 }}>
              <Chart options={options} series={HighData} type="area" height="100%" />
            </Box>
            <Typography textAlign="center" fontSize={14} fontWeight="bold" data-testid="threat-high">
              {numberWithCommas(sum(HighData[0].data))}
            </Typography>
          </>
        ) : (
          <Typography
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
            sx={{ height: '100%', fontSize: '16', fontWeight: 'bold' }}
            data-testid="threat-high"
          >
            0
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

ThreatChart.propTypes = {
  data: PropTypes.object,
};

export default ThreatChart;
