import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from '@mui/material';
import get from 'lodash/get';

import Tooltip from '../Tooltip';
import TopChart from './TopChart';

const TopUsage = ({ attributes, label, logAttr }) => {
  const ecoId = useSelector((state) => state.ecosystems.ecosystem.uuid);
  const data = useSelector((state) => get(state.ecosystems.ecosystem, `network.${attributes}`, []));
  const isLoading = useSelector((state) => get(state.ecosystems.ecosystem.loading, `network.${attributes}`, false));
  const { timeWindow } = useSelector((state) => state.ecosystems);

  const availableRows = (data || []).filter(({ Attributes }) => !!Attributes[attributes]);

  return (
    <TableContainer component={Paper} sx={{ border: '1px solid #EFF2F7', borderRadius: 1 }}>
      <Table>
        <TableHead sx={{ backgroundColor: '#EFF2F7' }}>
          <TableRow>
            <TableCell sx={{ fontSize: 14, fontWeight: 600, lineHeight: '16px', color: '#1A2E42' }}>
              Top {label}
            </TableCell>
            <TableCell sx={{ fontSize: 14, fontWeight: 600, lineHeight: '16px', color: '#1A2E42' }} align="center">
              Usage
            </TableCell>
            <TableCell sx={{ fontSize: 14, fontWeight: 600, lineHeight: '16px', color: '#1A2E42' }} align="center">
              Kbps
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TableRow sx={{ height: 200 }}>
              <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            availableRows.map((row) => {
              const from = new Date(row.From).getTime();
              const to = new Date(row.To).getTime();
              const kbps = (row.Total * 8) / (timeWindow * 60 * 60 * 1000);

              return (
                <TableRow key={row.Attributes[attributes]}>
                  <TableCell>
                    <Tooltip attribute={logAttr} ecoId={ecoId} value={row.Attributes[attributes]}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: '#1A2E42',
                          maxWidth: '120px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {row.Attributes[attributes]}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TopChart from={from} to={to} total={row.Total} values={row.Value} />
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: 24, fontWeight: 700, lineHeight: '32px', color: '#1A2E42' }}
                    align="center"
                  >
                    {kbps > 1 ? kbps.toFixed(0) : kbps.toFixed(1)}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TopUsage.propTypes = {
  attributes: PropTypes.string,
  label: PropTypes.string,
  logAttr: PropTypes.string,
};

export default TopUsage;
