import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)(
  () => `
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1393;
`,
);

const Spinner = styled(CircularProgress)(
  () => `
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
`,
);

const LoadingSpinner = () => (
  <Wrapper>
    <Spinner />
  </Wrapper>
);

export default LoadingSpinner;
