import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';

import HeaderLinks from '../Header/HeaderLinks';
import { OBJECT_MENU_CONFIG } from '../../enum/object';
import { ROUTES } from '../../enum';
import {
  ACRETO_LOGO,
  BACK_ARROW,
  MENU_ADDRESS,
  MENU_ELEMENTS,
  MENU_OBJECTS,
  MENU_SECURITY,
  MENU_POLICY,
  MENU_PROFILE,
  MENU_WEBFILTER,
  MENU_REPORT,
} from '../../assets/Icons';
import ThingIcon from '../../assets/img/PNG/device.png';
import SettingSVG from '../../assets/img/SVG/gear.svg';
import DesktopSVG from '../../assets/img/SVG/desktop.svg';
import './styles.scss';

function objRouteExists(url) {
  return !!url && ['objects', ...OBJECT_MENU_CONFIG.map((obj) => obj.url)].includes(url);
}

const Sidebar = ({ ecosystem, customer }) => {
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const [objOpened, setObjOpened] = useState(true);
  const [profileOpened, setProfileOpened] = useState(false);

  // Handle pathname changes
  useEffect(() => {
    const splitURL = location.pathname.split('/');
    if (objRouteExists(splitURL[6])) {
      setObjOpened(true);
    } else {
      setObjOpened(false);
      if (splitURL[6] !== 'security' || !splitURL[7] || !['web-filter', 'decryption'].includes(splitURL[7])) {
        setProfileOpened(false);
      }
    }
  }, [location.pathname]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  // Handle window resize event
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const activeRoute = (routeName) => (location.pathname === routeName ? 'active' : '');

  const splittedPathname = location.pathname.split('/');
  const customersURL = splittedPathname.slice(0, 5).join('/');
  const ecosystemURL = splittedPathname.slice(0, 6).join('/');

  return (
    <div id="sidebar" className="sidebar wedge" data-color="gray">
      <div className="logo">
        <a href={ROUTES.CUSTOMERS} className="simple-text logo-normal full-width">
          <div className="logo-img">
            <img src={customer.logo || ACRETO_LOGO} alt="logo_image" />
          </div>
        </a>
        <div className="divider" />
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          {width <= 991 ? <HeaderLinks /> : null}
          <li>
            <NavLink to={customersURL} className="nav-link ecosystem">
              <img src={BACK_ARROW} alt="back-arrow" className="back-arrow" />
              <p className="ecosystem--text">All Ecosystems</p>
            </NavLink>
            <div className="sidebar-divider" />
          </li>
          <div className="ecosystem--name" title={ecosystem?.name}>
            {ecosystem?.name}
          </div>
          <li className={activeRoute(`${ecosystemURL}/guided-tasks`)}>
            <NavLink to={`${ecosystemURL}/guided-tasks`} className="nav-link root">
              <img className="small-image" alt="guided-tasks" src={MENU_REPORT} />
              <p>Guided Tasks</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${ecosystemURL}/objects`}
              className="nav-link root with-arrow"
              onClick={() => setObjOpened(false)}
            >
              <div className="left-container">
                <img className="small-image" alt="Elements" src={MENU_ELEMENTS} />
                <p>Elements</p>
              </div>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/objects`)} nested`}>
            <NavLink to={`${ecosystemURL}/objects`} className="nav-link" onClick={() => setObjOpened(true)}>
              <img className="small-image" alt="Objects" src={MENU_OBJECTS} />
              <p>Objects</p>
            </NavLink>
          </li>
          <div className="submenu">
            <Collapse in={objOpened}>
              <div>
                {OBJECT_MENU_CONFIG.filter(({ objIdentity }) => !objIdentity).map((objType) => (
                  <NavLink
                    key={`submenu-${objType.url}`}
                    to={`${ecosystemURL}/objects/${objType.url}`}
                    className={`${activeRoute(`${ecosystemURL}/objects/${objType.url}`)} nav-link`}
                  >
                    <div className="obj-icon">
                      <img src={objType.icon} alt={`obj-icon-${objType.url}`} style={objType.iconStyle} />
                    </div>
                    <p>{objType.title}</p>
                  </NavLink>
                ))}
                <div className="identity">
                  <div className="obj-icon">
                    <img src={ThingIcon} alt="object-identity" style={{ width: 14 }} />
                  </div>
                  <p>Identity</p>
                </div>
                <div className="obj-identities">
                  {OBJECT_MENU_CONFIG.filter(({ objIdentity }) => !!objIdentity).map((objType) => (
                    <NavLink
                      key={`submenu-${objType.url}`}
                      to={`${ecosystemURL}/objects/${objType.url}`}
                      className={`${activeRoute(`${ecosystemURL}/objects/${objType.url}`)} nav-link`}
                    >
                      <div className="obj-icon">
                        <img src={objType.icon} alt={`obj-icon-${objType.url}`} style={objType.iconStyle} />
                      </div>
                      <p>{objType.title}</p>
                    </NavLink>
                  ))}
                </div>
              </div>
            </Collapse>
          </div>
          <li className={activeRoute(`${ecosystemURL}/addresstranslations`)}>
            <NavLink to={`${ecosystemURL}/addresstranslations`} className="nav-link root">
              <img className="small-image translations" alt="Address Translation" src={MENU_ADDRESS} />
              <p>Address Translation</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${ecosystemURL}/security`} className="nav-link root">
              <img className="small-image security" alt="Policies" src={MENU_SECURITY} />
              <p>Security</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/security`)} nested`}>
            <NavLink to={`${ecosystemURL}/security`} className="nav-link" activeClassName="active">
              <img className="small-image policy" alt="Security" src={MENU_POLICY} />
              <p>Policies</p>
            </NavLink>
          </li>
          <li className="nested">
            <NavLink
              to={`${ecosystemURL}/security/web-filter`}
              className="nav-link"
              onClick={() => setProfileOpened(true)}
            >
              <img className="small-image profile" alt="Security Profile" src={MENU_PROFILE} />
              <p>Profiles</p>
            </NavLink>
          </li>
          <div className="submenu">
            <Collapse in={profileOpened}>
              <div>
                <NavLink
                  to={`${ecosystemURL}/security/web-filter`}
                  className={`${activeRoute(`${ecosystemURL}/security/web-filter`)} nav-link`}
                >
                  <img className="icon_webfilter" src={MENU_WEBFILTER} alt="Web Ftiler" />
                  <p>Website Filtering</p>
                </NavLink>
                <NavLink
                  to={`${ecosystemURL}/security/decryption`}
                  className={`${activeRoute(`${ecosystemURL}/security/decryption`)} nav-link`}
                >
                  <img className="icon_webfilter" src={MENU_WEBFILTER} alt="Web Ftiler" />
                  <p>Decryption</p>
                </NavLink>
              </div>
            </Collapse>
          </div>
          <li>
            <NavLink to={`${ecosystemURL}/logs`} className="nav-link root">
              <img className="small-image" alt="Logs" src={MENU_REPORT} />
              <p>Logs</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/logs`)} nested`}>
            <NavLink to={`${ecosystemURL}/logs`} className="nav-link" activeClassName="active">
              <img className="small-image" alt="Reports" src={MENU_REPORT} />
              <p>All</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/logs/traffic`)} nested`}>
            <NavLink to={`${ecosystemURL}/logs/traffic`} className="nav-link" activeClassName="active">
              <img className="small-image" alt="Reports" src={MENU_REPORT} />
              <p>Traffic</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/logs/gateway`)} nested`}>
            <NavLink to={`${ecosystemURL}/logs/gateway`} className="nav-link" activeClassName="active">
              <img className="small-image" alt="Reports" src={MENU_REPORT} />
              <p>Gateways</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/logs/usersandthings`)} nested`}>
            <NavLink to={`${ecosystemURL}/logs/usersandthings`} className="nav-link" activeClassName="active">
              <img className="small-image" alt="Reports" src={MENU_REPORT} />
              <p>Users and Things</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/logs/vpn-connections`)} nested`}>
            <NavLink to={`${ecosystemURL}/logs/vpn-connections`} className="nav-link" activeClassName="active">
              <img className="small-image" alt="Reports" src={MENU_REPORT} />
              <p>VPN connections</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/logs/settings`)} nested`}>
            <NavLink to={`${ecosystemURL}/logs/settings`} className="nav-link" activeClassName="active">
              <img className="small-image" alt="Settings" src={MENU_REPORT} />
              <p>Settings</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${ecosystemURL}/settings/admin-access`} className="nav-link root">
              <img className="small-image" alt="Settings" src={SettingSVG} />
              <p>Ecosystem Settings</p>
            </NavLink>
          </li>
          <li className={`${activeRoute(`${ecosystemURL}/settings/admin-access`)} nested`}>
            <NavLink to={`${ecosystemURL}/settings/admin-access`} className="nav-link" activeClassName="active">
              <img className="small-image" alt="Settings" src={DesktopSVG} />
              <p>Admin Access</p>
            </NavLink>
          </li>
        </ul>
        <div className="sidebar-divider" />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  ecosystem: PropTypes.object,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ecosystem: state.ecosystems.currentEcosystem,
  customer: state.customers.currentCustomer,
});

export default connect(mapStateToProps, null)(Sidebar);
