import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFormik } from 'formik';
import { bool, func, object, string, shape } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';

import EmailDisplay from '../../../components/EmailDisplay';
import ErrorPanel from '../../../components/ErrorPanel';
import { passwordResetValidationSchema } from '../../../validationSchemas';
import { LOGIN_PASSWORD } from '../../../assets/Icons';
import { clearError, resetPasswordUser } from '../../../store/user/actions';
import { logoutUser } from '../../../store/auth/actions';
import { emailSelector, errorSelector, isLoadingSelector } from '../../../store/user/selectors';
import '../login-form.scss';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: true,
      unitedErrors: [],
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serverError !== this.props.serverError) {
      this.setState({
        showError: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup = () => {
    const { clearError, serverError, logoutUser } = this.props;
    logoutUser();
    if (serverError) clearError();
  };

  onSubmit = (event) => {
    event.preventDefault();
    let { unitedErrors } = this.state;
    const { isValid, resetPasswordUser, serverError, errors, touched } = this.props;
    const { values } = this.props;
    if (!isValid) {
      unitedErrors = [serverError];
      Object.keys(errors).forEach((key) => {
        if (touched[key]) {
          unitedErrors.push(errors[key]);
        }
      });
      this.setState({
        showError: true,
        unitedErrors,
      });
      return;
    }
    resetPasswordUser(values);
  };

  handleInputChange = (event) => {
    const { handleChange, clearError, serverError } = this.props;
    const { showError } = this.state;
    if (serverError) clearError();
    if (showError) this.closeErrorPanel();
    handleChange(event);
  };

  closeErrorPanel = () => {
    const { clearError, serverError } = this.props;
    if (serverError) clearError();
    this.setState({
      showError: false,
      unitedErrors: [],
    });
  };

  render() {
    const { values, setFieldTouched, isLoading, serverError } = this.props;
    const { unitedErrors } = this.state;

    return (
      <div className="login-form-page--content">
        <div className="login-form">
          <h2 className="title reset-pwd">Set your new password</h2>
          <form onSubmit={this.onSubmit}>
            <EmailDisplay />
            {serverError ? (
              <ErrorPanel message={serverError} buttonClickHandler={this.closeErrorPanel} key={serverError} />
            ) : (
              unitedErrors.map((error) =>
                error ? <ErrorPanel message={error} buttonClickHandler={this.closeErrorPanel} key={error} /> : null,
              )
            )}
            <div className="input-container">
              <div className="icon-container">
                <img src={LOGIN_PASSWORD} className="person-icon" alt="person-icon" />
              </div>
              <input
                type="password"
                value={values.password}
                name="password"
                placeholder="Password"
                required
                onChange={this.handleInputChange}
                onBlur={(evt) => setFieldTouched(evt.target.name)}
              />
            </div>
            <div className="input-container">
              <div className="icon-container">
                <img src={LOGIN_PASSWORD} className="person-icon" alt="person-icon" />
              </div>
              <input
                type="password"
                value={values.passwordConfirmation}
                name="passwordConfirmation"
                placeholder="Confirm Your Password"
                required
                onChange={this.handleInputChange}
                onBlur={(evt) => setFieldTouched(evt.target.name)}
              />
            </div>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={isLoading || !values.password || values.password !== values.passwordConfirmation}
              sx={{
                fontSize: 18,
                p: 2,
                bgcolor: '#4986c5',
                textTransform: 'unset',
                '&:hover': { bgcolor: '#4986c5' },
              }}
            >
              {isLoading ? <CircularProgress /> : 'Submit'}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.defaultProps = {
  isLoading: true,
};

ResetPasswordForm.propTypes = {
  isValid: bool.isRequired,
  setFieldTouched: func.isRequired,
  handleChange: func.isRequired,
  logoutUser: func.isRequired,
  isLoading: bool,
  serverError: string.isRequired,
  clearError: func.isRequired,
  touched: object.isRequired,
  resetPasswordUser: func.isRequired,
  errors: object,
  values: shape({
    password: string,
    passwordConfirmation: string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector(state),
  serverError: errorSelector(state),
  email: emailSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPasswordUser,
      clearError,
      logoutUser,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      password: '',
      passwordConfirmation: '',
    }),
    validationSchema: passwordResetValidationSchema,
  }),
)(ResetPasswordForm);
