/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Card from '../components/Card';
import Form from '../components/Form';
import SelectWithSearch from '../components/SelectWithSearch';
import CreateRoleForm from '../components/CreateRoleForm';
import AddButton from '../components/AddButton';
import populator from '../utils/populator';
import UserValidationSchema from '../validationSchemas/userValidation';

import { StepFooter, UsersTable } from './commons';

import './modals.scss';

class NewUserManual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedUsers: [],
      errors: [],
      newFirstName: '',
      newLastName: '',
      newEmail: '',
      newPassword: '',
      newRole: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { createdObject } = this.props;
    if (createdObject !== prevProps.createdObject && createdObject.field === 'user') {
      const role = {
        value: createdObject.object.uuid,
        label: createdObject.object.name,
      };
      this.setState({ newRole: role });
    }
  }

  handleSubmit = async () => {
    if (!(await this.handleAddUser())) {
      return;
    }
    try {
      const { addedUsers } = this.state;
      this.props.onFinish({ type: 'user', data: addedUsers });
    } catch (err) {
      this.setState({
        errors: ['Failed to Add User'],
      });
    }
  };

  goBack = () => {
    this.props.navigateBack();
  };

  handleAddUser = () => {
    const { newFirstName, newLastName, newEmail, newRole, newPassword, addedUsers } = this.state;

    if (!newFirstName && !newLastName && !newEmail && !newPassword && !newRole) return true;
    if (!newRole) {
      this.setState({ errors: ['Please select role'] });
      return false;
    }
    const addUser = {
      firstName: newFirstName,
      lastName: newLastName,
      email: newEmail,
      password: newPassword,
      securityGroup: '',
      role: newRole,
      source: '',
      status: 'Pending',
    };
    if (this.validate(addUser)) {
      this.setState({
        addedUsers: addedUsers.some(
          (user) =>
            user.firstName === newFirstName &&
            user.lastName === newLastName &&
            user.email === newEmail &&
            user.role === newRole,
        )
          ? addedUsers
          : [...addedUsers, addUser],
        newFirstName: '',
        newLastName: '',
        newEmail: '',
        newRole: null,
        newPassword: '',
      });
      return true;
    }
    return false;
  };

  removeUser = (index) => {
    const { addedUsers } = this.state;
    this.setState({
      addedUsers: addedUsers.filter((val, ind) => ind !== index),
    });
  };

  validate(newUserData) {
    try {
      UserValidationSchema.validateSync(newUserData, { abortEarly: false });
      this.setState({
        errors: [],
      });
      return true;
    } catch (err) {
      this.setState({
        errors: err.errors,
      });
      return false;
    }
  }

  renderConfig() {
    const { addedUsers, errors } = this.state;
    const { roles } = this.props;

    return (
      <div className="modal__content padded ldap-config new-user-survey-manual">
        {errors.map((err, index) => (
          <p className="error" key={index}>
            {err}
          </p>
        ))}
        <Card header={false}>
          <div className="form-row gateway-config">
            <Form.Group full>
              <UsersTable users={addedUsers} removeUser={this.removeUser}>
                <tr className="new-entry entries">
                  <td className="fName">
                    <Form.Text
                      value={this.state.newFirstName}
                      onChange={(val) => this.setState({ newFirstName: val })}
                      extraClass="light fName"
                      placeholder="First Name"
                    />
                  </td>
                  <td className="lName">
                    <Form.Text
                      value={this.state.newLastName}
                      onChange={(val) => this.setState({ newLastName: val })}
                      extraClass="light lName"
                      placeholder="Last Name"
                    />
                  </td>
                  <td className="role">
                    <Form.Group>
                      <SelectWithSearch
                        selected={this.state.newRole}
                        onChange={(val) => this.setState({ newRole: val })}
                        placeholder="Select role"
                        groupCreative
                        field="user"
                        createFormComponent={CreateRoleForm}
                        options={roles.map(populator.role)}
                      />
                    </Form.Group>
                  </td>
                  <td className="email">
                    <Form.Text
                      value={this.state.newEmail}
                      onChange={(val) => this.setState({ newEmail: val })}
                      extraClass="light email"
                      placeholder="Email"
                    />
                  </td>
                  <td className="pwd">
                    <Form.Text
                      type="password"
                      value={this.state.newPassword}
                      onChange={(val) => this.setState({ newPassword: val })}
                      extraClass="light email"
                      placeholder="Password"
                    />
                  </td>
                  <td className="last ">
                    <AddButton onClick={this.handleAddUser} />
                  </td>
                </tr>
              </UsersTable>
            </Form.Group>
          </div>
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfig()}
        <div className="wedge-modal__footer">
          <StepFooter edit={false} submit onClick={this.handleSubmit} onBack={this.goBack} />
        </div>
      </div>
    );
  }
}

NewUserManual.propTypes = {
  onFinish: PropTypes.func.isRequired,
  navigateBack: PropTypes.func,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  createdObject: PropTypes.object,
};

NewUserManual.Footer = StepFooter;

const mapStateToProps = (state) => ({
  roles: state.ecosystems.dictionaries.groups,
  createdObject: state.objects.createdObject,
});

export default connect(mapStateToProps)(NewUserManual);
