import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio } from '@mui/material';

const CustomRadio = (props) => {
  const { value, label, sx = {}, testId = '', ...rest } = props;

  return (
    <div data-testid={testId}>
      <FormControlLabel
        label={label}
        value={value}
        control={<Radio size="large" sx={{ px: 1, py: 0.5 }} />}
        {...rest}
        componentsProps={{ typography: { sx: { fontSize: 15 } } }}
        sx={{ my: '2px', ...sx }}
      />
    </div>
  );
};

CustomRadio.propTypes = {
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  testId: PropTypes.string,
};
export default CustomRadio;
