import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import WedgeModal from '../../components/WedgeModal';
import { ROUTES } from '../../enum';
import ConfirmAction from '../../Modals/ConfirmAction';
import { deleteCustomer } from './scenario-actions';

import './customers-form.scss';

const CustomersForm = ({ customers, deleteCustomer }) => {
  const history = useHistory();
  const [removeOpen, setRemoveOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleSelectCustomer = (customer) => {
    history.push(`${ROUTES.CUSTOMERS}/${customer.uuid}/ecosystems`);
  };

  const handleRemoveCustomer = async () => {
    await deleteCustomer(selectedCustomer);
    handleCloseRemoveModal();
  };

  const handleOpenRemoveModal = (customer) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setRemoveOpen(true);
    setSelectedCustomer(customer);
  };

  const handleCloseRemoveModal = () => {
    setRemoveOpen(false);
    setSelectedCustomer(null);
  };

  const renderCustomers = () => {
    if (customers?.length) {
      return (
        <div className="customers-container">
          {customers.map((customer, index) => {
            const last = index + 1 === length;
            return (
              <Fragment key={`customer-index-${customer.uuid}`}>
                <div className="single-customer" onClick={() => handleSelectCustomer(customer)}>
                  <div className="single-customer-info">
                    <p className="single-customer-info__name">{customer.name}</p>
                    <p className="single-customer-info__uuid">{customer.uuid}</p>
                  </div>
                  <div className="customer-actions">
                    <IconButton size="small" onClick={handleOpenRemoveModal(customer)}>
                      <DeleteOutlineIcon fontSize="large" color="primary" />
                    </IconButton>
                    <ArrowForwardIcon fontSize="large" color="primary" />
                  </div>
                </div>
                {!last && <div className="divider" />}
              </Fragment>
            );
          })}
        </div>
      );
    }
    return <h4>You have no customers!</h4>;
  };

  return (
    <div className="customers-form-page--content">
      <div className="customers-form">{renderCustomers()}</div>
      {selectedCustomer && (
        <WedgeModal title="Are you sure?" onClose={handleCloseRemoveModal} isOpen={removeOpen} size="tiny">
          <ConfirmAction
            item={{ type: 'Organization', name: selectedCustomer.name }}
            action="delete"
            onYes={handleRemoveCustomer}
            onNo={handleCloseRemoveModal}
            confirmByTyping
          />
        </WedgeModal>
      )}
    </div>
  );
};

CustomersForm.defaultProps = {
  customers: [],
};

CustomersForm.propTypes = {
  deleteCustomer: PropTypes.func.isRequired,
  customers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
});

const mapDispatchToProps = (dispatch) => ({
  deleteCustomer: (customer) => dispatch(deleteCustomer(customer)),
});

const ConnectedCustomersForm = connect(mapStateToProps, mapDispatchToProps)(CustomersForm);
export default withRouter(ConnectedCustomersForm);
