import { object, string } from 'yup';

const addressValidationSchema = object({
  name: string('Enter thing name').max(250, 'Name cannot be more than 250 characters.').required('Name is required.'),
  desc: string().max(255, 'Description cannot be more than 255 characters'),
  category: object().required('Category is required.').typeError('Category is required.'),
  address: string().required('Address is required.'),
  type: object().required(),
});

export default addressValidationSchema;
