import { sortByName } from 'utils/utils';
import * as ACTION_TYPES from './action-types';
import { CLEAR_DATA } from '../common-action-types';

const initialState = {
  customers: [],
  currentCustomer: {},
  fetchingCustomer: false,
};

export function customersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.SET_CUSTOMERS:
    case ACTION_TYPES.GET_CUSTOMER_LIST_SUCCESS:
      return { ...state, customers: sortByName(payload) };
    case ACTION_TYPES.FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        fetchingCustomer: true,
      };
    case ACTION_TYPES.FETCH_CUSTOMER_SUCCESS:
    case ACTION_TYPES.UPDATE_CUSTOMER_SUCCESS:
    case ACTION_TYPES.SET_CUR_CUSTOMER:
      return {
        ...state,
        currentCustomer: payload,
        fetchingCustomer: false,
      };
    case CLEAR_DATA:
    case ACTION_TYPES.REMOVE_CUR_CUSTOMER:
      return {
        ...state,
        currentCustomer: initialState.currentCustomer,
      };
    case ACTION_TYPES.FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        fetchingCustomer: false,
      };
    case ACTION_TYPES.REMOVE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter((customer) => customer.uuid !== payload.uuid),
      };
    default:
      return {
        ...state,
      };
  }
}
