import { createAction } from 'redux-actions';

import * as ACTION_TYPES from './action-types';

export function fetchingObjectsStarted() {
  return {
    type: ACTION_TYPES.FETCHING_OBJECT_REQUESTED,
    payload: {
      isLoading: true,
    },
  };
}
export function fetchingObjectsSuccess(results, ecosystemUUID) {
  return {
    type: ACTION_TYPES.FETCHING_OBJECT_SUCCESS,
    payload: {
      results,
      ecosystemUUID,
      success: true,
      isLoading: false,
    },
  };
}
export function fetchingObjectsFailed(err) {
  return {
    type: ACTION_TYPES.FETCHING_OBJECT_FAILURE,
    payload: {
      message: err,
      success: false,
      isLoading: false,
    },
  };
}

export function fetchingAddressStarted() {
  return {
    type: ACTION_TYPES.FETCHING_ADDRESS_REQUESTED,
  };
}
export function fetchingAddressSuccess(results) {
  return {
    type: ACTION_TYPES.FETCHING_ADDRESS_SUCCESS,
    payload: {
      results,
    },
  };
}
export function fetchingAddressFailed(err) {
  return {
    type: ACTION_TYPES.FETCHING_ADDRESS_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function creationObjectStarted() {
  return {
    type: ACTION_TYPES.CREATION_OBJECT_REQUESTED,
    payload: {
      message: '',
    },
  };
}
export function creationObjectSuccess(result, ecosystem) {
  return {
    type: ACTION_TYPES.CREATION_OBJECT_SUCCESS,
    payload: {
      result,
      ecosystem,
    },
  };
}

export function createPolicyObject({ object, field }) {
  return {
    type: ACTION_TYPES.CREATE_POLICY_OBJECT,
    payload: {
      object,
      field,
    },
  };
}
export function creationObjectFailed(err) {
  return {
    type: ACTION_TYPES.CREATION_OBJECT_FAILURE,
    payload: {
      message: err,
      isLoading: false,
    },
  };
}

export function updateObjectStarted() {
  return {
    type: ACTION_TYPES.UPDATE_OBJECT_REQUESTED,
  };
}
export function updateObjectSuccess(result, ecosystem) {
  return {
    type: ACTION_TYPES.UPDATE_OBJECT_SUCCESS,
    payload: {
      result,
      ecosystem,
    },
  };
}

export function updateObjectFailed(err) {
  return {
    type: ACTION_TYPES.UPDATE_OBJECT_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function deleteObjectStarted() {
  return {
    type: ACTION_TYPES.DELETE_OBJECT_REQUESTED,
  };
}
export function deleteObjectSuccess() {
  return {
    type: ACTION_TYPES.DELETE_OBJECT_SUCCESS,
  };
}
export function deleteObjectFailed(err) {
  return {
    type: ACTION_TYPES.DELETE_OBJECT_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function generateTokenStarted() {
  return {
    type: ACTION_TYPES.GENERATE_TOKEN_REQUESTED,
  };
}
export function generateTokenSuccess() {
  return {
    type: ACTION_TYPES.GENERATE_TOKEN_SUCCESS,
  };
}
export function generateTokenFailed(err) {
  return {
    type: ACTION_TYPES.GENERATE_TOKEN_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function generateImageStarted(payload) {
  return {
    type: ACTION_TYPES.GENERATE_IMAGE_STARTED,
    payload,
  };
}
export function generateImageFailed(payload) {
  return {
    type: ACTION_TYPES.GENERATE_IMAGE_FAILURE,
    payload,
  };
}
export const generateImageSuccess = createAction(ACTION_TYPES.GENERATE_IMAGE_SUCCESS);

export function thingConfStarted() {
  return {
    type: ACTION_TYPES.THING_CONF_REQUESTED,
  };
}
export function thingConfSuccess(configs) {
  return {
    type: ACTION_TYPES.THING_CONF_SUCCESS,
    payload: {
      configs,
    },
  };
}
export function thingConfFailed(err) {
  return {
    type: ACTION_TYPES.THING_CONF_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function regenPSKStarted() {
  return {
    type: ACTION_TYPES.REGEN_PSK_REQUESTED,
  };
}
export function regenPSKSuccess(payload) {
  return {
    type: ACTION_TYPES.REGEN_PSK_SUCCESS,
    payload,
  };
}
export function regenPSKFailed(err) {
  return {
    type: ACTION_TYPES.REGEN_PSK_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function readGatewayStarted() {
  return {
    type: ACTION_TYPES.READ_GATEWAY_REQUESTED,
  };
}
export function readGatewaySuccess(payload) {
  return {
    type: ACTION_TYPES.READ_GATEWAY_SUCCESS,
    payload,
  };
}
export function readGatewayFailed(err) {
  return {
    type: ACTION_TYPES.READ_GATEWAY_FAILURE,
    payload: {
      message: err,
    },
  };
}

export const getThingToken = createAction(ACTION_TYPES.GET_THING_TOKEN);

export function getLDAPTokenSuccess(payload) {
  return {
    type: ACTION_TYPES.GET_LDAP_TOKEN_SUCCESS,
    payload,
  };
}
export function getLDAPTokenFailure(err) {
  return {
    type: ACTION_TYPES.GET_LDAP_TOKEN_FAILURE,
    payload: {
      message: err,
    },
  };
}
export const getLDAPToken = createAction(ACTION_TYPES.GET_LDAP_TOKEN);
export function updateLDAPPasswordStarted() {
  return {
    type: ACTION_TYPES.UPDATE_LDAP_PASSOWRD_REQUESTED,
  };
}

export function updateLDAPPasswordSuccess(payload) {
  return {
    type: ACTION_TYPES.UPDATE_LDAP_PASSOWRD_SUCCESS,
    payload,
  };
}

export function updateLDAPPasswordFailure(err) {
  return {
    type: ACTION_TYPES.UPDATE_LDAP_PASSOWRD_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function userInvitationStarted() {
  return {
    type: ACTION_TYPES.USER_INVITATION_REQUESTED,
  };
}

export function userInvitationSuccess(results) {
  return {
    type: ACTION_TYPES.USER_INVITATION_SUCCESS,
    payload: results,
  };
}
export function userInvitationFailed(err) {
  return {
    type: ACTION_TYPES.USER_INVITATION_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function changeUserStatusStarted() {
  return {
    type: ACTION_TYPES.CHANGE_USER_STATUS_REQUESTED,
  };
}

export function changeUserStatusSuccess(results) {
  return {
    type: ACTION_TYPES.CHANGE_USER_STATUS_SUCCESS,
    payload: results,
  };
}
export function changeUserStatusFailed(err) {
  return {
    type: ACTION_TYPES.CHANGE_USER_STATUS_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function resetMFAStarted() {
  return {
    type: ACTION_TYPES.RESET_MFA_REQUESTED,
  };
}

export function resetMFASuccess(results) {
  return {
    type: ACTION_TYPES.RESET_MFA_SUCCESS,
    payload: results,
  };
}
export function resetMFAFailed(err) {
  return {
    type: ACTION_TYPES.RESET_MFA_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function updateLdapStatusStarted(payload) {
  return {
    type: ACTION_TYPES.LDAP_STATUS_REQUESTED,
    payload,
  };
}
export function updateLdapStatusSuccess(payload) {
  return {
    type: ACTION_TYPES.LDAP_STATUS_SUCCESS,
    payload,
  };
}
export function updateLdapStatusFailed(err) {
  return {
    type: ACTION_TYPES.LDAP_STATUS_FAILURE,
    payload: {
      message: err,
    },
  };
}
