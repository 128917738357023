import orderBy from 'lodash/orderBy';
import { CLEAR_DATA } from '../common-action-types';
import * as ACTION_TYPES from './action-types';

const initialState = {
  isLoading: true,
  items: [],
  success: true,
  logSettings: [],
  deviceSessions: [],
};

const appendReportItems = (state, payload) => {
  if (payload.append === 0) return payload.results;
  if (payload.append === 1) return [...state.items, ...payload.results];
  return [...payload.results, ...state.items];
};

export function reportsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.FETCHING_REPORTS_SUCCESS: {
      const items = appendReportItems(state, payload);

      return {
        ...state,
        items: orderBy(items, ['date'], ['desc']),
        isLoading: false,
        success: payload.success,
      };
    }
    case ACTION_TYPES.FETCHING_REPORTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        success: payload.success,
      };
    }
    case ACTION_TYPES.FLUSH_REPORTS: {
      return {
        ...state,
        items: [],
      };
    }
    case ACTION_TYPES.FETCHING_LOG_SETTINGS_SUCCESS: {
      return {
        ...state,
        logSettings: payload.results,
      };
    }
    case ACTION_TYPES.CREATION_LOG_SETTINGS_SUCCESS: {
      return {
        ...state,
        logSettings: [...state.logSettings, payload.result],
      };
    }
    case ACTION_TYPES.UPDATE_LOG_SETTINGS_SUCCESS: {
      return {
        ...state,
        logSettings: [...state.logSettings.map((ob) => (ob.uuid === payload.result.uuid ? payload.result : ob))],
      };
    }
    case ACTION_TYPES.DELETE_LOG_SETTING_SUCCESS: {
      return {
        ...state,
        logSettings: [...state.logSettings.filter((setting) => setting.uuid !== payload.setting.uuid)],
      };
    }
    case ACTION_TYPES.GET_DEVICE_SESSIONS_SUCCESS: {
      return {
        ...state,
        deviceSessions: payload,
      };
    }
    case CLEAR_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
