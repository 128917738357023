import React from 'react';
import PropTypes from 'prop-types';

import Card from '../components/Card';
import Field from '../components/Field';

const GruopDetail = ({ data }) => (
  <div className="modal__content padded new-address-survey">
    <Card header={false}>
      <div className="form-row">
        <Field.Group label="Name" extraClass="flex-3">
          <Field.Text text={data.name} />
        </Field.Group>
      </div>
    </Card>
  </div>
);

GruopDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GruopDetail;
