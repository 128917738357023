/* eslint-disable no-extend-native */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import configureStore from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/proximaNova.css';
import './assets/sass/light-bootstrap-dashboard.scss';
import './reset.scss';

const { persistor, store } = configureStore;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={3000}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
        >
          <App />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
    ,
  </HelmetProvider>,
);

if (module.hot) {
  module.hot.accept('./App', () => {
    root.render(<App />);
  });
}
