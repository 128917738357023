import React from 'react';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import cx from 'classnames';

import useCopyToClipboard from 'hooks/useCopyToClipboard';
import './styles.scss';

const FieldGroup = ({
  children,
  label,
  secondaryLabel,
  center = false,
  full = false,
  self = false,
  extraClass = '',
}) => {
  const renderSecondLabel = () => (
    <React.Fragment>
      <div className="divider--small" />
      <span className="field__label secondary">{secondaryLabel}</span>
    </React.Fragment>
  );

  return (
    <div className={cx(center ? 'field__group-center' : 'field__group', extraClass, { full, self })}>
      <div className="field__label">
        <span>{label}</span>
        {secondaryLabel && renderSecondLabel()}
      </div>
      {children}
    </div>
  );
};

FieldGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  label: PropTypes.string,
  center: PropTypes.bool,
  full: PropTypes.bool,
  self: PropTypes.bool,
  extraClass: PropTypes.string,
  secondaryLabel: PropTypes.string,
};

const TextField = ({ to = '', text = '', full = false, copy = false, extraClass = '', title = '', testId = '' }) => {
  const { copy: handleCopy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickCopy = () => {
    handleCopy(text);
    enqueueSnackbar('Copied!', {
      variant: 'success',
      autoHideDuration: 1500,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  if (copy) {
    return (
      <div data-testid={testId} className={`field__copyfield ${extraClass || ''}`}>
        <div className="copy-button">
          <span className="copy__textfield">
            {to ? (
              <a href={to} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            ) : (
              text
            )}
          </span>
          <ContentCopyIcon onClick={handleClickCopy} sx={{ color: '#6d8994', mx: 0.5 }} />
        </div>
      </div>
    );
  }

  return (
    <p data-testid={testId} title={title} className={cx('primary field__textfield', extraClass, { full })}>
      {text}
    </p>
  );
};

TextField.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.string,
  full: PropTypes.bool,
  copy: PropTypes.bool,
  extraClass: PropTypes.string,
  title: PropTypes.string,
  testId: PropTypes.string,
};

export default {
  Group: FieldGroup,
  Text: TextField,
};
