import * as REST from 'api/rest';
import Notification from 'components/Notification';
import * as ACTION_TYPES from 'store/customers/action-types';
import { fecthCustomerStarted, fetchCustomerFailed, fetchCustomerSuccess } from 'store/customers/actions';

export function selectCustomer(customerUUID) {
  return async (dispatch) => {
    try {
      dispatch(fecthCustomerStarted());
      const result = await REST.fecthCustomerDetail({ customerUUID });
      const logo = await REST.getCustomerLogo(customerUUID);
      result.logo = logo;
      dispatch(fetchCustomerSuccess(result));
    } catch (error) {
      dispatch(fetchCustomerFailed(error));
    }
  };
}

export function deleteCustomer(customer) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_TYPES.REMOVE_CUSTOMER_REQUEST });
      await REST.removeCustomer(customer.uuid);
      dispatch({
        type: ACTION_TYPES.REMOVE_CUSTOMER,
        payload: customer,
      });
      Notification({
        message: `${customer.name} has been removed`,
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.REMOVE_CUSTOMER_FAILURE, payload: { message: error } });
    }
  };
}
