import axios from 'axios';
import qs from 'query-string';

import { CONSTANTS } from '../enum';
import Translator from '../utils/enumTranslator';
import configStore from '../store';
import { logoutUser, renewToken } from '../store/auth/actions';
import { loading } from '../store/global/actions';

const { store } = configStore;

export const rest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

rest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
    const { method, url } = config;

    if (token === null) {
      store.dispatch(logoutUser());
      return config;
    }
    if (['post', 'put', 'delete', 'patch'].findIndex((meth) => meth === method) !== -1) {
      if (!url.includes('/logs/_search')) {
        store.dispatch(loading(true));
      }
    }
    const newConfig = config;
    newConfig.headers.Authorization = `Bearer ${token}`;

    return newConfig;
  },
  (err) => Promise.reject(err),
);

rest.interceptors.response.use(
  (response) => {
    store.dispatch(renewToken());
    const {
      config: { method, url },
    } = response;
    if (['post', 'put', 'delete', 'patch'].findIndex((meth) => meth === method) !== -1) {
      if (!url.includes('/logs/_search')) {
        store.dispatch(loading(false));
      }
    }
    return response;
  },
  (error) => {
    if (['post', 'put', 'delete', 'patch'].findIndex((meth) => meth === error.config.method) !== -1) {
      store.dispatch(loading(false));
    }
    if (error.status === 401 || error.response.status === 401) {
      localStorage.removeItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
      store.dispatch(logoutUser());
    }
    return Promise.reject(error);
  },
);

export async function checkAPIStatus() {
  const response = await rest.get('/v2/status');
  return response.data;
}

export async function getUser(email) {
  const response = await rest.get(`/v2/users/${email}`);
  return response.data;
}

export const getCustomersList = () => rest.get('/v2/customers').then((response) => response.data);

export async function fecthCustomerDetail({ customerUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}`);
  return response.data;
}

export async function fetchEcosystems({ customerUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}/ecosystems`);
  return response.data;
}

export async function fetchEcosystem({ customerUUID, ecosystemUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}`);
  return response.data;
}

export async function createEcosystem({ customer, entity }) {
  const response = await rest.post(`/v2/customers/${customer.uuid}/ecosystems`, entity);
  return response.data;
}

export async function editEcosystem({ ecosystem, customer }) {
  const response = await rest.post(`/v2/customers/${customer}/ecosystems/${ecosystem.uuid}`, ecosystem);
  return response.data;
}

export async function renameEcosystem({ ecosystem, customer }) {
  const response = await rest.put(`/v2/customers/${customer}/ecosystems/${ecosystem.uuid}/rename`, ecosystem);
  return response.data;
}

export async function removeEcosystem({ customer, ecosystem }) {
  const response = await rest.delete(`v2/customers/${customer}/ecosystems/${ecosystem}`);
  return response.data;
}

export async function getEcosystemUsers({ customer, ecosystem }) {
  const response = await rest.get(`v2/customers/${customer}/ecosystems/${ecosystem}/users`);
  return response.data;
}

export async function grantAccess({ customer, ecosystem, user }) {
  const response = await rest.post(`v2/customers/${customer}/ecosystems/${ecosystem}/access/${user.role}`, {
    username: user.email,
  });
  return response.data;
}

export async function getAccessUser({ customer, ecosystem }) {
  const response = await rest.get(`v2/customers/${customer}/ecosystems/${ecosystem}/access`);
  return response.data;
}

export async function removeUserAcces({ customer, ecosystem, user }) {
  const response = await rest.delete(
    `v2/customers/${customer}/ecosystems/${ecosystem}/access/${user.role}/${user.email}`,
  );
  return response.data;
}

export async function upgradeEcosystem({ customer, ecosystem }) {
  const response = await rest.post(`v2/customers/${customer}/ecosystems/${ecosystem}/upgrade`);
  return response.data;
}

export async function recoverEcosystem({ customer, ecosystem }) {
  const response = await rest.post(`v2/customers/${customer}/ecosystems/${ecosystem}/recover`);
  return response.data;
}

export async function fetchObjects({ customer, ecosystem, type, payload = {} }) {
  const response = await rest.get(getUrlForType({ type, ecosystem, customer }), payload);
  const { data } = response;
  return data.map((d) => ({ ...d, element: type }));
}

export async function fetchAddresses({ customer, ecosystem }) {
  const response = await rest.get(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/addresses`);
  return response.data;
}

export async function fetchPolicies({ customerUUID, ecosystemUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/policies`);
  return response.data;
}
export async function fetchDNATs({ customer, ecosystem }) {
  const response = await rest.get(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/nat`);
  return response.data;
}

export async function createPolicy({ customerUUID, ecosystemUUID, payload }) {
  const response = await rest.post(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/policies`, payload);
  return response.data;
}
export async function createDNAT({ customer, ecosystem, entity }) {
  const response = await rest.post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/nat`, entity);
  return response.data;
}

export const getUrlCategories = ({ customer, ecosystem }) =>
  rest
    .get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/categories`)
    .then((response) => response.data);

export const getUrlLists = ({ customer, ecosystem }) =>
  rest.get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/list`).then((response) => response.data);

export const getSingleUrlList = ({ customer, ecosystem, uuid }) =>
  rest
    .get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/list/${uuid}`)
    .then((response) => response.data);

export const getUrlFilteringProfiles = ({ customer, ecosystem }) =>
  rest.get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile`).then((response) => response.data);

export const getSingleUrlFilteringProfile = ({ customer, ecosystem, uuid }) =>
  rest
    .get(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile/${uuid}`)
    .then((response) => response.data);

export const createUrlFilteringProfile = ({ customer, ecosystem, profile }) =>
  rest
    .post(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile`, profile)
    .then((response) => response.data);

export const updateUrlFilteringProfile = ({ customer, ecosystem, profile }) =>
  rest
    .put(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile/${profile.uuid}`, profile)
    .then((response) => response.data);

export const removeUrlFilteringProfile = ({ customer, ecosystem, profile }) =>
  rest
    .delete(`/v2/customers/${customer}/ecosystems/${ecosystem}/url-filtering/profile/${profile}`)
    .then((response) => response.data);

export async function fetchGroups({ customerUUID, ecosystemUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/groups`);
  return response.data;
}

export async function fetchApplications({ customerUUID, ecosystemUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/applications`);
  return response.data;
}

export async function fetchServices({ customerUUID, ecosystemUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/services`);
  return response.data;
}

export async function fetchEcosystemCode({ customerUUID, ecosystemUUID }) {
  const response = await rest.post(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/code`);
  return response.data;
}

export async function updateEcosystemMFAData({ customerUUID, ecosystemUUID, mfaTypes }) {
  const response = await rest.put(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/auth/mfa`, mfaTypes);
  return response.data;
}
export async function fetchEcosystemMFAStatus({ customerUUID, ecosystemUUID }) {
  const response = await rest.get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/auth/mfa`);
  return response.data;
}

export async function createGroup({ customerUUID, ecosystemUUID, name }) {
  const response = await rest.post(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/groups`, {
    name,
  });
  return response.data;
}

export async function updateGroup({ customer, ecosystem, name, uuid }) {
  const response = await rest.put(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/groups/${uuid}`, {
    name,
  });
  return response.data;
}

export async function deleteGroup({ customer, ecosystem, uuid }) {
  const response = await rest.delete(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/groups/${uuid}`);
  return response.data;
}

export async function createObject({ object, type, ecosystem, customer }) {
  const response = await rest.post(getUrlForType({ type, ecosystem, customer }), object);
  const { data } = response;
  return { ...data, element: type };
}

export async function updateObject({ object, type, ecosystem, customer, uuid }) {
  const response = await rest.put(`${getUrlForType({ type, ecosystem, customer })}/${uuid}`, object);
  const { data } = response;
  return { ...data, element: type };
}

export async function deleteObject({ customer, ecosystem, object }) {
  const response = await rest.delete(`${getUrlForType({ type: object.element, ecosystem, customer })}/${object.uuid}`);
  return response.data;
}

export async function updatePolicy({ entity, uuid, ecosystemUUID, customerUUID }) {
  const response = await rest.put(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/policies/${uuid}`, entity);
  return response.data;
}

export async function updateDNAT({ entity, uuid, ecosystem, customer }) {
  const response = await rest.put(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/nat/${uuid}`, entity);
  return response.data;
}

export async function deletePolicy({ policy, ecosystem, customer }) {
  const response = await rest.delete(`/v2/customers/${customer}/ecosystems/${ecosystem}/policies/${policy}`);
  return response.data;
}
export async function deleteDNAT({ dNAT, ecosystem, customer }) {
  const response = await rest.delete(`/v2/customers/${customer}/ecosystems/${ecosystem}/nat/${dNAT}`);
  return response.data;
}

export async function reorderPolicy({ policy, after, ecosystem, customer }) {
  const response = await rest.post(
    `/v2/customers/${customer}/ecosystems/${ecosystem}/policies/${policy}/reorder`,
    after,
  );
  return response.data;
}

export async function createService({ customer, service, ecosystem }) {
  const response = await rest.post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/services`, service);
  return response.data;
}

export const login = (credentials) => rest.post(`v2/auth/login`, credentials).then((response) => response.data);

export const loginMFA = (credentials, code) =>
  rest.post(`v2/auth/login`, credentials, { headers: { 'X-OTP': code } }).then((response) => response.data);

export async function logout() {
  const response = await rest.post('/v2/auth/logout', {});
  return response.data;
}

export async function readGateway({ customer, ecosystem, gateway }) {
  const response = await rest.get(`/v2/customers/${customer}/ecosystems/${ecosystem}/gateways/${gateway}`);
  return response.data;
}

export async function regeneratePSK({ customer, ecosystem, gateway }) {
  const response = await rest.put(`/v2/customers/${customer}/ecosystems/${ecosystem}/gateways/${gateway}/psk`);
  return response.data;
}

export async function getGatewayToken({ customer, ecosystem, gateway, platform }) {
  const response = await rest.post(`/v2/customers/${customer}/ecosystems/${ecosystem}/gateways/${gateway}/token`, {
    gateway_platform: platform,
  });
  return response.data;
}

export function getConfigLink(token, platform) {
  return `${process.env.REACT_APP_API_URL}/v2/gateways/ipsec/config/${platform}?_token=${token}`;
}

export async function acceptCommit({ customer, ecosystem }) {
  const response = await rest.post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/commits`, {});
  return response.headers;
}

export async function acceptRollback({ customer, ecosystem }) {
  const response = await rest.post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/rollback`, {});
  return response.headers;
}

function getUrlForType({ type, ecosystem, customer }) {
  return `/v2/customers/${customer.uuid}/ecosystems/${ecosystem}/${Translator.urlType(type)}`;
}

export const getCustomerLogo = (customer) =>
  rest
    .get(`/v2/customers/${customer}/avatar?noerror=true`, { headers: { Accept: 'text/plain' } })
    .then((response) => response.data);

export async function updateCustomerInfo({ customerUUID, customer }) {
  const response = await rest.put(`/v2/customers/${customerUUID}`, customer);
  return response.data;
}

export async function removeCustomer(customerUUID) {
  const response = await rest.delete(`v2/customers/${customerUUID}`);
  return response.data;
}

export async function fetchNSPs() {
  const response = await rest.get(`/v2/nsps`);
  return response.data;
}

export async function fetchRoles({ customer, ecosystem }) {
  const response = await rest.get(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/roles`);
  return response.data;
}

export async function createRole({ customer, ecosystem, name }) {
  const response = await rest.post(`/v2/customers/${customer.uuid}/ecosystems/${ecosystem.uuid}/roles`, {
    name,
  });
  return response.data;
}

export async function sendUserInvitations({ customer, ecosystem, payload }) {
  const response = await rest.post(`/v2/customers/${customer}/ecosystems/${ecosystem}/invite`, {
    ...payload,
  });
  return response.data;
}
export async function enableDisableMFAForUsers({ customer, ecosystem, payload }) {
  const response = await rest.put(`/v2/customers/${customer}/ecosystems/${ecosystem}/users/auth`, {
    ...payload,
  });
  return response.data;
}
export async function resetMFAForUsers({ customer, ecosystem, payload }) {
  const response = await rest.put(`/v2/customers/${customer}/ecosystems/${ecosystem}/users/reset`, {
    ...payload,
  });
  return response.data;
}

export const updateLDAPPassword = ({ customerUUID, ecosystemUUID, ldapUUID, data }) =>
  rest.put(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/idp/ldap/${ldapUUID}/credentials`, data);

export const updateLdapStatus = ({ customerUUID, ecosystemUUID, ldapUUID }) =>
  rest
    .get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/idp/ldap/${ldapUUID}/status`)
    .then((response) => response.data);

export const configOTP = (email) => rest.post(`v2/users/${email}/otp`).then((response) => response.data);

export const activate2FA = (email, code) =>
  rest.patch(`/v2/users/${email}/otp`, { code }).then((response) => response.data);
export const deactivate2FA = (email, code) =>
  rest.delete(`/v2/users/${email}/otp`, { headers: { 'X-OTP': code } }, null).then((response) => response.data);

export const createInboundCert = ({ customerUUID, ecosystemUUID, payload }) =>
  rest.post(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert`, payload).then(({ data }) => data);

export const getInboundCertList = ({ customerUUID, ecosystemUUID }) =>
  rest.get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert`).then(({ data }) => data);

export const getInboundCert = ({ customerUUID, ecosystemUUID, certUUID }) =>
  rest
    .get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert/${certUUID}`)
    .then(({ data }) => data);

export const updateInboundCert = ({ customerUUID, ecosystemUUID, certUUID, payload }) =>
  rest
    .put(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert/${certUUID}`, payload)
    .then(({ data }) => data);

export const deleteInboundCert = ({ customerUUID, ecosystemUUID, certUUID }) =>
  rest
    .delete(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/cert/${certUUID}`)
    .then(({ data }) => data);

export const createDecryptionProfile = ({ customerUUID, ecosystemUUID, payload }) =>
  rest
    .post(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile`, payload)
    .then(({ data }) => data);

export const getDecryptionProfileList = ({ customerUUID, ecosystemUUID }) =>
  rest.get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile`).then(({ data }) => data);

export const getDecryptionProfile = ({ customerUUID, ecosystemUUID, profileUUID }) =>
  rest
    .get(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile/${profileUUID}`)
    .then(({ data }) => data);

export const updateDecryptionProfile = ({ customerUUID, ecosystemUUID, profileUUID, payload }) =>
  rest
    .put(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile/${profileUUID}`, payload)
    .then(({ data }) => data);

export const deleteDecryptionProfile = ({ customerUUID, ecosystemUUID, profileUUID }) =>
  rest
    .delete(`v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/inbound/profile/${profileUUID}`)
    .then(({ data }) => data);

export const getEcosystemReports = async ({ customerUUID, from, to, metric, aggregate, attributes }) => {
  let query = `?metric=${metric}&from=${from}&to=${to}`;
  if (aggregate) query += `&aggregate=${aggregate}`;
  if (attributes) query += `&attributes=${attributes}`;
  const response = await rest.get(`/v2/customers/${customerUUID}/dashboard${query}`).then(({ data }) => data);

  if (metric === 'network') {
    const { TimeSeries: timeSeries } = response;
    return (timeSeries || []).map((netData) => {
      const { Value: value } = netData;
      // Convert Mbps
      return {
        ...netData,
        Value: value.map((v) => (v * 8) / (1024 * 1024)),
      };
    });
  }

  return response;
};

export const getEcosystemMetrcis = async ({
  customerUUID,
  ecosystemUUID,
  from,
  to,
  metric,
  aggregate,
  attributes,
  limit,
}) => {
  const query = qs.stringify({ metric, from, to, aggregate, attributes, limit });

  return rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/dashboard?${query}`)
    .then(({ data: { TimeSeries } }) => TimeSeries);
};

export const getEcosystemThreadIndex = ({ customerUUID, from, to }) =>
  rest.get(`/v2/customers/${customerUUID}/dashboard/index?from=${from}&to=${to}`).then(({ data }) => data);

export const getEcosystemDashboardDetail = ({ customerUUID, ecosystemUUID, from, to }) =>
  rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/dashboard/detailed?from=${from}&to=${to}`)
    .then(({ data }) => data);

export const getEcosystemDashboardIndex = ({ customerUUID, ecosystemUUID, from, to }) =>
  rest
    .get(`/v2/customers/${customerUUID}/ecosystems/${ecosystemUUID}/dashboard/index?from=${from}&to=${to}`)
    .then(({ data }) => data);
