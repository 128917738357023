/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import cx from 'classnames';
import { IconButton, Tooltip, Typography } from '@mui/material';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import CloseIcon from '@mui/icons-material/Close';

import AddButton from '../components/AddButton';
import Card from '../components/Card';
import Descriptor from '../components/Descriptor';
import Field from '../components/Field';
import Form from '../components/Form';
import SelectWithSearch from '../components/SelectWithSearch';

import { EXPIRATION_TYPE } from '../enum/expiration';
import { GATEWAY_TYPE, GATEWAY_TYPE_OPTIONS, MODEL_TYPE, MODEL_TYPE_OPTIONS } from '../enum/gateway';
import { IP_MODES, IP_MODES_OPTIONS } from '../enum/ip';
import { LOCATION_TYPE_OPTIONS, DEFAULT_LOCATION } from '../enum/location';
import { OBJECT_CATEGORIES } from '../enum/object';

import Translator from '../utils/enumTranslator';
import IPValidator from '../utils/ipValidator';
import { generateKeypair } from '../utils/wireguardUtils';
import gatewayValidateSchema from '../validationSchemas/gatewayValidationSchema';
import { Footer } from './commons';

import './modals.scss';

function parseAdditionalNetworks(networks) {
  if (!networks) return [];
  return networks.map((net) => ({
    network: net.network,
    hop: net.gateway,
  }));
}

const ExpiryInput = ({ value, onClick }) => <input className="expiry-input" value={value} onClick={onClick} readOnly />;

class NewGatewaySurvey extends React.Component {
  constructor(props) {
    super(props);
    const gatewayForm = this.setGatewayForm();
    this.state = {
      gatewayForm,
      errors: [],
      newNetwork: '',
      newHop: '',
      tabKey: 1,
    };
  }

  setGatewayForm() {
    const { edit, item } = this.props;
    return {
      name: edit ? item.name : '',
      category: edit ? Translator.category(item.category) : null,
      model: edit ? item.model : MODEL_TYPE.GENERIC,
      gateway_type: edit ? item.gateway_type : GATEWAY_TYPE.IPSEC,
      expiryTime: edit && item.expiry && item.expiry.time ? new Date(item.expiry.time) : null,
      expiryType: edit && item.expiry && item.expiry.type ? item.expiry.type : EXPIRATION_TYPE.SOFT,
      desc: edit ? item.description : '',
      location: edit && !item.geolocation ? LOCATION_TYPE_OPTIONS[1] : LOCATION_TYPE_OPTIONS[0],
      lat: edit ? (!!item.geolocation && item.geolocation.latitude) || DEFAULT_LOCATION.LAT : DEFAULT_LOCATION.LAT,
      lng: edit ? (!!item.geolocation && item.geolocation.longitude) || DEFAULT_LOCATION.LNG : DEFAULT_LOCATION.LNG,
      gateway_internet_gw: edit ? item.gateway_internet_gw || '' : '',
      gateway_internet_ip: edit ? item.gateway_internet_ip || '' : '',
      gateway_internet_proto: edit ? item.gateway_internet_proto || '' : '',
      gateway_local_ip: edit ? item.gateway_local_ip || '' : '',
      peer_address: edit ? item.peer_address || '' : '',
      peer_port: edit ? item.peer_port || '' : '',
      peer_id: edit ? item.peer_id : '',
      peer_networks: edit ? parseAdditionalNetworks(item.peer_networks) : [],
      allow_all_ciphers: edit ? item.allow_all_ciphers : true,
      ipsec_fail_open: edit ? !!item.ipsec_fail_open : false,
      uuid: edit ? item.uuid : null,
      ownPubKey: !!edit,
    };
  }

  changeField = (field, value) => {
    const { gatewayForm } = this.state;
    gatewayForm[field] = value;
    this.setState({
      gatewayForm,
      errors: [],
    });
  };

  handleChangeType = (type) => {
    const {
      gatewayForm: { gateway_type },
    } = this.state;
    if (gateway_type !== type) {
      this.changeField('gateway_internet_proto', type === GATEWAY_TYPE.VGATEWAY ? IP_MODES.STATIC : '');
      this.changeField('model', MODEL_TYPE.GENERIC);
      this.changeField('gateway_type', type);
    }
  };

  handleSubmit = async () => {
    if (!(await this.handleAddNetwork())) {
      return;
    }
    if (this.validate()) {
      try {
        const { gatewayForm } = this.state;
        const payload = {
          ...gatewayForm,
          acreto_id: this.props.addresses.find((addr) => addr.name === 'Default Tunnel').address,
        };
        if (gatewayForm.gateway_type === GATEWAY_TYPE.WIREGUARD) {
          if (!gatewayForm.ownPubKey) {
            const { publicKey } = generateKeypair();
            payload.peer_id = publicKey;
          }
        } else if (gatewayForm.model === MODEL_TYPE.AWS_SITE) {
          payload.peer_id = gatewayForm.peer_address;
        }
        delete payload.ownPubKey;
        this.props.onFinish(payload);
      } catch (err) {
        this.setState({
          errors: [this.props.edit ? 'Failed to Update Gateway' : 'Failed to Add Gateway'],
        });
      }
    }
  };

  validIp = (ip, field) => {
    const { errors } = this.state;
    if (!IPValidator(ip, '4')) {
      errors.push(`${field} is invalid`);
      this.setState({ errors });
      return false;
    }
    return true;
  };

  handleAddNetwork = () => {
    const { newNetwork, newHop, gatewayForm } = this.state;

    if (!newNetwork && !newHop) return true;
    if (!this.validIp(newNetwork, 'Network IP')) return false;
    if (newHop && !this.validIp(newHop, 'Next Hop IP')) return false;

    const addNetwork = {
      network: newNetwork,
      hop: newHop,
    };
    this.setState({
      gatewayForm: {
        ...gatewayForm,
        peer_networks: gatewayForm.peer_networks.some((net) => net.network === newNetwork && net.hop === newHop)
          ? gatewayForm.peer_networks
          : [...gatewayForm.peer_networks, addNetwork],
      },
      newNetwork: '',
      newHop: '',
    });
    return true;
  };

  handleRemoveNetwork = (index) => {
    const { gatewayForm } = this.state;
    this.setState({
      gatewayForm: {
        ...gatewayForm,
        peer_networks: gatewayForm.peer_networks.filter((val, ind) => ind !== index),
      },
    });
  };

  validate = () => {
    const {
      gatewayForm: {
        gateway_type,
        gateway_internet_proto,
        gateway_internet_gw,
        gateway_internet_ip,
        gateway_local_ip,
        peer_address,
      },
    } = this.state;
    try {
      gatewayValidateSchema.validateSync(this.state.gatewayForm, {
        abortEarly: false,
      });
      this.setState({ errors: [] });
      if (peer_address && !this.validIp(peer_address, 'IP Address')) {
        return false;
      }
      if (gateway_type === GATEWAY_TYPE.IPSEC) return true;
      return gateway_internet_proto === IP_MODES.STATIC
        ? this.validIp(gateway_internet_ip, 'Gateway Internet IP') &&
            this.validIp(gateway_internet_gw, 'Default Route') &&
            this.validIp(gateway_local_ip, 'Gateway Local IP')
        : this.validIp(gateway_local_ip, 'Gateway Local IP');
    } catch (err) {
      this.contentElement.scrollIntoView({
        behavior: 'smooth',
      });
      this.setState({ errors: err.errors });
      return false;
    }
  };

  getLabel = () => {
    if (this.state.gatewayForm.model === MODEL_TYPE.AWS_SITE) {
      return 'AWS Tunnel Outside IP Address';
    }
    return 'Allow connections from (optional)';
  };

  renderConfig() {
    const {
      gatewayForm: {
        gateway_type,
        gateway_internet_gw,
        gateway_internet_ip,
        gateway_internet_proto,
        gateway_local_ip,
        peer_address,
        peer_port,
        peer_networks,
        ipsec_fail_open,
        allow_all_ciphers,
        ownPubKey,
        ...otherForm
      },
      errors,
      tabKey,
    } = this.state;

    return (
      <div className="modal__content padded new-gateway-survey">
        {tabKey === 1 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Card header={false} className="basic">
          <div className="form-row">
            <Form.Group required label="Name">
              <Form.Text value={otherForm.name} onChange={(val) => this.changeField('name', val)} placeholder="Name" />
            </Form.Group>
            {this.props.edit ? (
              <div className="gateway-type">
                <Field.Group label="Gateway Type">
                  <Field.Text text={Translator.type('gateway', gateway_type).label} />
                </Field.Group>
              </div>
            ) : (
              <Form.Group>
                <Form.Toggle
                  selected={gateway_type}
                  selectedClass="toggle-selected"
                  onChange={this.handleChangeType}
                  options={GATEWAY_TYPE_OPTIONS}
                />
              </Form.Group>
            )}
          </div>
          <div className="form-row">
            <Form.Group required label="Category">
              <SelectWithSearch
                selected={otherForm.category}
                onChange={(val) => this.changeField('category', val)}
                placeholder="Select category"
                groupCreative={false}
                options={OBJECT_CATEGORIES}
              />
            </Form.Group>
            {[GATEWAY_TYPE.IPSEC, GATEWAY_TYPE.VGATEWAY].includes(gateway_type) && (
              <Form.Group
                required={gateway_type === GATEWAY_TYPE.IPSEC}
                label={gateway_type === GATEWAY_TYPE.IPSEC ? 'Model' : ''}
              >
                {gateway_type === GATEWAY_TYPE.IPSEC && (
                  <div className="minWidth">
                    {MODEL_TYPE_OPTIONS.map((option) => (
                      <div className="centered-row" key={option.value}>
                        <div className="form__input idpOptions">
                          <Form.RadioButton
                            {...option}
                            selected={otherForm.model}
                            onChange={(val) => {
                              this.changeField('model', val);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <label className="checkbox-label wedge-checkbox-container">
                  <input
                    type="checkbox"
                    checked={ipsec_fail_open}
                    onChange={(ev) => this.changeField('ipsec_fail_open', ev.target.checked)}
                  />
                  <span className="checkmark" />
                  <p className="check_title">
                    {gateway_type === GATEWAY_TYPE.IPSEC ? 'IPSec' : 'vGateway'} Fail Open
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 12 }}>
                          Default: Disabled
                          <br />
                          When the tunnel is down Fail Open allows accessing the Internet directly using the default
                          gateway, bypassing Acreto
                        </Typography>
                      }
                    >
                      <IconButton sx={{ p: 0, pl: 1 }}>
                        <HelpCenterOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </p>
                </label>
              </Form.Group>
            )}
          </div>
          {/* Hide until API is ready
					<div className={'form-row'}>
						<Form.Group label={'Expiry'}>
							<DatePicker
								isClearable
								dateFormat={'MMM dd, yyyy'}
								selected={otherForm.expiryTime}
								onChange={val => this.changeField('expiryTime', val)}
								customInput={<ExpiryInput />}
							/>
						</Form.Group>
					</div> */}
        </Card>

        <Card header={false}>
          <div className="form-row gateway-config">
            <div className="gateway-config__ips">
              {gateway_type === GATEWAY_TYPE.WIREGUARD && (
                <>
                  {!this.props.edit && (
                    <div className="wireguard-pub-key">
                      <label className="checkbox-label wedge-checkbox-container">
                        <input
                          type="checkbox"
                          checked={ownPubKey}
                          onChange={(ev) => this.changeField('ownPubKey', ev.target.checked)}
                        />
                        <span className="checkmark" />
                        <p className="check_title">I have WireGuard Client Public Key</p>
                      </label>
                    </div>
                  )}
                  {ownPubKey && (
                    <Form.Group full required label="WireGuard Client Public Key" extraClass="space-above-8">
                      <div className="flex centered-row">
                        <Form.Text
                          multiline
                          name="peer_id"
                          value={this.state.gatewayForm.peer_id}
                          onChange={(val) => {
                            this.changeField('peer_id', val);
                          }}
                          placeholder="eAnMqNhZ7qi8/u9n6ZXxe+sA+JyIMTemy5lWZZfuwGU="
                        />
                      </div>
                    </Form.Group>
                  )}
                </>
              )}
              {gateway_type === GATEWAY_TYPE.IPSEC && (
                <Form.Group
                  full
                  label={this.getLabel()}
                  required={this.state.gatewayForm.model === MODEL_TYPE.AWS_SITE}
                >
                  <Form.Text
                    value={peer_address}
                    onChange={(val) => this.changeField('peer_address', val)}
                    placeholder="IP address"
                  />
                </Form.Group>
              )}
              {gateway_type === GATEWAY_TYPE.VGATEWAY && (
                <Form.Group>
                  <Form.Toggle
                    selected={gateway_internet_proto}
                    selectedClass="toggle-selected"
                    onChange={(val) => this.changeField('gateway_internet_proto', val)}
                    options={IP_MODES_OPTIONS}
                  />
                </Form.Group>
              )}
              {gateway_type === GATEWAY_TYPE.VGATEWAY && gateway_internet_proto === IP_MODES.STATIC && (
                <React.Fragment>
                  <Form.Group required full label="vGateway Internet IP">
                    <Form.Text
                      value={gateway_internet_ip}
                      onChange={(val) => this.changeField('gateway_internet_ip', val)}
                      placeholder="Static IP / 32"
                    />
                  </Form.Group>
                  <Form.Group required full label="vGateway Default Route">
                    <Form.Text
                      value={gateway_internet_gw}
                      onChange={(val) => this.changeField('gateway_internet_gw', val)}
                      placeholder="Enter Default Route"
                    />
                  </Form.Group>
                </React.Fragment>
              )}
              {[GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(gateway_type) && (
                <Form.Group
                  required
                  full
                  label={gateway_type === GATEWAY_TYPE.VGATEWAY ? 'vGateway Local IP' : 'WireGuard Client Local IP'}
                >
                  <Form.Text
                    value={gateway_local_ip}
                    onChange={(val) => this.changeField('gateway_local_ip', val)}
                    placeholder="Local IP / 24"
                  />
                </Form.Group>
              )}
            </div>
            <div className="gateway-config__networks">
              {[GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(gateway_type) && (
                <Form.Group
                  full
                  label={
                    gateway_type === GATEWAY_TYPE.VGATEWAY
                      ? 'Allow connections from (optional)'
                      : 'WireGuard Client Public IP'
                  }
                >
                  <div className={cx({ 'wg-public': gateway_type === GATEWAY_TYPE.WIREGUARD })}>
                    <Form.Text
                      value={peer_address}
                      onChange={(val) => this.changeField('peer_address', val)}
                      placeholder="IP address"
                    />
                    {gateway_type === GATEWAY_TYPE.WIREGUARD && (
                      <Form.Text
                        value={peer_port}
                        onChange={(val) => this.changeField('peer_port', val)}
                        placeholder="Port"
                      />
                    )}
                  </div>
                </Form.Group>
              )}

              <Form.Group required label="Local Networks">
                <div className="local-networks--container">
                  <table>
                    <tbody>
                      <tr>
                        <th className="network">Network</th>
                        {[GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(gateway_type) && (
                          <th className="hop">Next Hop</th>
                        )}
                      </tr>
                      {peer_networks &&
                        peer_networks.map((net, index) => (
                          <tr key={`additional-network-index-${index}`}>
                            <td className="network">{net.network}</td>
                            {[GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(gateway_type) && (
                              <td className="hop">{net.hop}</td>
                            )}
                            <td className="last">
                              <IconButton size="small" onClick={() => this.handleRemoveNetwork(index)}>
                                <CloseIcon />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      <tr className="new-entry">
                        <td>
                          <Form.Text
                            value={this.state.newNetwork}
                            onChange={(val) => this.setState({ newNetwork: val })}
                            extraClass="light"
                            placeholder="Network"
                          />
                        </td>
                        {[GATEWAY_TYPE.VGATEWAY, GATEWAY_TYPE.WIREGUARD].includes(gateway_type) && (
                          <td>
                            <Form.Text
                              value={this.state.newHop}
                              onChange={(val) => this.setState({ newHop: val })}
                              extraClass="light"
                              placeholder="Next Hop"
                            />
                          </td>
                        )}
                        <td className="last">
                          <AddButton onClick={this.handleAddNetwork} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Form.Group>
              {gateway_type !== GATEWAY_TYPE.WIREGUARD && (
                <Form.Group extraClass="space-above">
                  <label className="checkbox-label wedge-checkbox-container">
                    <input
                      type="checkbox"
                      checked={allow_all_ciphers}
                      onChange={(ev) => this.changeField('allow_all_ciphers', ev.target.checked)}
                    />
                    <span className="checkmark" />
                    <p className="check_title">
                      Allow all supported ciphers
                      <Tooltip title={<Typography sx={{ fontSize: 12 }}>This may have performance impact</Typography>}>
                        <IconButton sx={{ p: 0, pl: 1 }}>
                          <HelpCenterOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </p>
                  </label>
                </Form.Group>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  }

  renderDesc() {
    const { gatewayForm, errors, tabKey } = this.state;
    return (
      <div className="modal__content padded new-gateway-survey">
        {tabKey === 2 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Descriptor
          desc={gatewayForm.desc}
          location={gatewayForm.location}
          lat={gatewayForm.lat}
          lng={gatewayForm.lng}
          onChange={(field, value) => this.changeField(field, value)}
        />
      </div>
    );
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.contentElement = node;
        }}
      >
        <Tabs defaultActiveKey={1} id="object__tabs" onSelect={(key) => this.setState({ tabKey: key })}>
          <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
            {this.renderConfig()}
          </Tab>
          <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
            {this.renderDesc()}
          </Tab>
        </Tabs>

        <div className="wedge-modal__footer">
          <Footer onClick={this.handleSubmit} edit={this.props.edit} onDelete={this.props.onDelete} />
        </div>
      </div>
    );
  }
}
const addressSelector = (state) => {
  const ecosystem = state.ecosystems.currentEcosystem;
  return ecosystem && state.objects[ecosystem.uuid]
    ? state.objects[ecosystem.uuid].objects.filter((obj) => obj.element === 'address' && obj.scope === 'public')
    : [];
};

NewGatewaySurvey.deafultProps = {
  addresses: [],
};

NewGatewaySurvey.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  edit: PropTypes.bool,
  item: PropTypes.object,
  addresses: PropTypes.array,
};

ExpiryInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  addresses: addressSelector(state),
});
NewGatewaySurvey.Footer = Footer;

export default connect(mapStateToProps)(NewGatewaySurvey);
