export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const REMOVE_CUSTOMER_REQUEST = 'REMOVE_CUSTOMER_REQUEST';
export const REMOVE_CUSTOMER_SUCCESS = 'REMOVE_CUSTOMER_SUCCESS';
export const REMOVE_CUSTOMER_FAILURE = 'REMOVE_CUSTOMER_FAILURE';

export const SET_CUR_CUSTOMER = 'SET_CUR_CUSTOMER';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const REMOVE_CUR_CUSTOMER = 'REMOVE_CUR_CUSTOMER';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';

export const GET_CUSTOMER_LIST_REQUEST = 'GET_CUSTOMER_LIST_REQUEST';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';
