export const GET_DECRYPTIONS_REQUEST = 'GET_DECRYPTIONS_REQUEST';
export const GET_DECRYPTIONS_SUCCESS = 'GET_DECRYPTIONS_SUCCESS';
export const GET_DECRYPTIONS_FAILURE = 'GET_DECRYPTIONS_FAILURE';

export const CREATE_DECRYPTION_REQUEST = 'CREATE_DECRYPTION_REQUEST';
export const CREATE_DECRYPTION_SUCCESS = 'CREATE_DECRYPTION_SUCCESS';
export const CREATE_DECRYPTION_FAILURE = 'CREATE_DECRYPTION_FAILURE';

export const DELETE_DECRYPTION_REQUEST = 'DELETE_DECRYPTION_REQUEST';
export const DELETE_DECRYPTION_SUCCESS = 'DELETE_DECRYPTION_SUCCESS';
export const DELETE_DECRYPTION_FAILURE = 'DELETE_DECRYPTION_FAILURE';

export const CREATE_CERT_REQUEST = 'CREATE_CERT_REQUEST';
export const CREATE_CERT_SUCCESS = 'CREATE_CERT_SUCCESS';
export const CREATE_CERT_FAILURE = 'CREATE_CERT_FAILURE';

export const GET_CERTS_REQUEST = 'GET_CERTS_REQUEST';
export const GET_CERTS_SUCCESS = 'GET_CERTS_SUCCESS';
export const GET_CERTS_FAILURE = 'GET_CERTS_FAILURE';

export const UPDATE_CERT_REQUEST = 'UPDATE_CERT_REQUEST';
export const UPDATE_CERT_SUCCESS = 'UPDATE_CERT_SUCCESS';
export const UPDATE_CERT_FAILURE = 'UPDATE_CERT_FAILURE';

export const RESET_NEW = 'RESET_NEW';
