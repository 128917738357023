import { object, string, ref } from 'yup';

const personalInfoValidationSchema = object({
  firstName: string('First Name')
    .max(100, 'First Name cannot be more than 100 characters.')
    .required('First name is required.'),
  lastName: string('Last Name')
    .max(100, 'Last Name cannot be more than 100 characters.')
    .required('Last name is required.'),
  // DO NOT Check password for oauth users
  password: string('Password').when('iss', {
    is: 'userpass',
    then: () =>
      string()
        .min(8, 'Password cannot be less than 8 characters.')
        .max(100, 'Password cannot be more than 100 characters.')
        .required('Passwrod is required.'),
    otherwise: () => string().notRequired(),
  }),
  passwordConfirmation: string().when('iss', {
    is: 'userpass',
    then: () =>
      string()
        .oneOf([ref('password')], 'Passwords do not match.')
        .required('Password confirmation is required.'),
    otherwise: () => string().notRequired(),
  }),
});

export default personalInfoValidationSchema;
