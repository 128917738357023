import React from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { Box, CircularProgress, useTheme } from '@mui/material';
import get from 'lodash/get';

const UtilChart = () => {
  const theme = useTheme();

  const isLoading = useSelector((state) => get(state.ecosystems.ecosystem.loading, 'network.direction', false));
  const {
    network: { direction: data },
  } = useSelector((state) => state.ecosystems.ecosystem);

  let from = new Date(data?.[0]?.From).getTime();
  const to = new Date(data?.[0]?.To).getTime();
  const count = data?.[0]?.Value?.length || 0;
  const timeGap = Number(((to - from) / count).toFixed(0));
  let timeStamps = [];
  (data?.[0]?.Value || []).forEach((val, index) => timeStamps.push(from + timeGap * index));

  if (!timeStamps.length) {
    const now = new Date().getTime();
    from = now - 8 * 60 * 60 * 1000;
    timeStamps = [from, now];
  }

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ['#7255E3', '#75A0AE'],
    dataLabels: { enabled: false },
    theme: { mode: theme.palette.mode },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    stroke: {
      colors: ['#7255E3', '#75A0AE'],
      curve: 'straight',
      width: 2,
    },
    grid: {
      show: false,
    },
    legend: { show: false },
    xaxis: {
      type: 'datetime',
      min: from,
      categories: timeStamps,
      tickAmount: 8,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
        datetimeUTC: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
        // Convert to Mbps
        formatter: (value) => parseInt((value * 8) / (1024 * 1024), 10),
      },
    },
    tooltip: {
      enabled: false,
      // x: {
      //   format: 'dd MMM yyyy hh:mm',
      // },
      // y: {
      //   formatter: (value) => ((value * 8) / (1024 * 1024)).toFixed(3),
      // },
    },
  };

  const usageData = [
    {
      name: 'In',
      data: data?.[0]?.Attributes?.direction === 'receive' ? data?.[0]?.Value || [0, 0] : data?.[1]?.Value || [0, 0],
    },
    {
      name: 'Out',
      data: data?.[1]?.Attributes?.direction === 'transmit' ? data?.[1]?.Value || [0, 0] : data?.[0]?.Value || [0, 0],
    },
  ];

  return (
    <Box sx={{ border: '1px solid #E3E6E8', borderRadius: 1 }}>
      {isLoading ? (
        <Box sx={{ height: 260 }} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Chart options={options} series={usageData} type="area" height="100%" width="100%" />
      )}
    </Box>
  );
};

export default UtilChart;
