/* eslint-disable camelcase */
/* eslint-disable no-throw-literal */
import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { CONSTANTS, ROUTES } from '../../enum';
import { setContractIdError } from '../payment/actions';
import { startLoading, finishLoading, setError } from '../user/actions';
import { createCustomer } from './apiCalls';
import history from '../../history';
import { extractResponseErrorStatus, createResponseErrorMessage } from '../../utils/responseErrorHandler';
import { loginSuccess, clearData, logoutUser } from '../auth/actions';
import { removeCurrentCustomer, setCurrentCustomer, setCustomers } from '../customers/actions';
import * as REST from '../../api/rest';

const customerSelector = (state) => {
  const customers = get(state, ['auth', 'customers']);
  return customers;
};
const currentCustomer = (state) => {
  const customer = get(state, ['customers', 'currentCustomer']);
  return customer;
};

export function* contractIdSaga({ payload: companyData }) {
  const curCustomer = yield select(currentCustomer);
  yield put(startLoading());
  yield put(setContractIdError());
  try {
    const payload = {
      ...curCustomer,
      ...companyData,
    };
    if (curCustomer.contact) {
      payload.contact = curCustomer.contact;
    }
    const { data: customer } = yield call(createCustomer, payload);
    yield call(finishRegistrationProcedure, customer);
  } catch (error) {
    if (error.response && error.response.data.message) {
      yield put(setContractIdError(error.response.data.message));
    } else {
      const { field } = error;
      const errorStatus = extractResponseErrorStatus(error);
      const errorMessage = field
        ? `Invalid Data Passed As "${field}"`
        : createResponseErrorMessage({
            specificErrorHandler: {
              default: 'Error while parsing your Contract ID',
            },
            status: errorStatus,
          });
      yield put(setContractIdError(errorMessage));
    }
  } finally {
    yield put(finishLoading());
  }
}

export function* finishRegistrationProcedure(customer) {
  const customers = yield select(customerSelector);

  if (customers && customers.length) {
    yield REST.logout();
    yield put(removeCurrentCustomer());
    yield put(logoutUser());
    yield put(setError('Please login again to access your new organization'));
    yield put(clearData());
    history.replace(ROUTES.LOGIN);
  } else {
    const accessToken = localStorage.getItem(CONSTANTS.LOCAL_ACCESS_TOKEN_KEY);
    yield put(setCustomers([customer]));
    yield put(setCurrentCustomer(customer));
    yield put(loginSuccess({ accessToken }));
    history.replace({
      pathname: `${ROUTES.CUSTOMERS}/${customer.uuid}`,
      state: { openEcosystemDialog: true },
    });
  }
}
