import * as REST from '../api/rest';
import {
  createEcosystemGroupRequested,
  createEcosystemGroupSuccess,
  createEcosystemGroupFailure,
  createServiceRequested,
  createServiceSuccess,
  createServiceFailure,
  createEcosystemRoleRequested,
  createEcosystemRoleSuccess,
  createEcosystemRoleFailure,
} from './ecosystems/actions';
import { createPolicyObject } from './objects/actions';
import * as ACTION_TYPES from './ecosystems/action-types';

export function fetchGroups() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_GROUP_REQUEST });
      const result = await REST.fetchGroups({
        customerUUID: getState().customers.currentCustomer.uuid,
        ecosystemUUID: getState().ecosystems.currentEcosystem.uuid,
      });
      dispatch(createEcosystemGroupSuccess(result));
    } catch (error) {
      dispatch({ type: ACTION_TYPES.GET_GROUP_FAILURE, payload: error });
    }
  };
}

export function createGroup({ name, field }) {
  return async (dispatch, getState) => {
    try {
      dispatch(createEcosystemGroupRequested());
      const result = await REST.createGroup({
        customerUUID: getState().customers.currentCustomer.uuid,
        ecosystemUUID: getState().ecosystems.currentEcosystem.uuid,
        name,
      });
      const { groups } = getState().ecosystems.dictionaries;
      dispatch(createEcosystemGroupSuccess([...groups, result]));
      if (field) {
        dispatch(
          createPolicyObject({
            object: { ...result, element: 'group', type: 'group' },
            field,
          }),
        );
      }
    } catch (error) {
      dispatch(createEcosystemGroupFailure(error));
    }
  };
}

export function createService(service) {
  return async (dispatch, getState) => {
    try {
      dispatch(createServiceRequested());
      const result = await REST.createService({
        customer: getState().customers.currentCustomer,
        ecosystem: getState().ecosystems.currentEcosystem,
        service,
      });
      dispatch(createServiceSuccess(result));
    } catch (error) {
      dispatch(createServiceFailure(error));
    }
  };
}

export function createRole({ roleName, field }) {
  return async (dispatch, getState) => {
    try {
      dispatch(createEcosystemRoleRequested());
      const result = await REST.createRole({
        customer: getState().customers.currentCustomer,
        ecosystem: getState().ecosystems.currentEcosystem,
        name: roleName,
      });
      dispatch(createEcosystemRoleSuccess(result));
      if (field) {
        dispatch(
          createPolicyObject({
            object: { ...result, element: 'role', type: 'role' },
            field,
          }),
        );
      }
    } catch (error) {
      dispatch(createEcosystemRoleFailure(error));
    }
  };
}
