import { handleActions } from 'redux-actions';
import update from 'lodash/fp/update';

import { SET_CONTRACT_ID_ERROR, CLEAR_CONTRACT_ID_ERROR } from './actionTypes';
import { ERRORS, CONTRACT_ID_ERROR } from './propertiesNames';

const initialState = {
  [ERRORS]: {
    [CONTRACT_ID_ERROR]: '',
  },
};

const paymentReducer = handleActions(
  {
    [SET_CONTRACT_ID_ERROR]: (state, { payload }) => update([ERRORS, CONTRACT_ID_ERROR], () => payload, state),
    [CLEAR_CONTRACT_ID_ERROR]: (state, { payload }) => update([ERRORS, CONTRACT_ID_ERROR], () => payload, state),
  },
  initialState,
);

export default paymentReducer;
