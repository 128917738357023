import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import upperFirst from 'lodash/upperFirst';

import { deleteError } from '../../store/errors/actions';
import './styles.scss';

const GlobalError = ({
  customers: { currentCustomer },
  ecosystems: { currentEcosystem },
  user,
  errors: { errorKey, error },
  deleteError,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const getHelp = () => {
    const {
      data: { code },
      status,
      headers: header,
    } = error.response;

    const customerLink = currentCustomer?.uuid ? `customer=${currentCustomer.uuid}&` : '';
    const ecosystemLink = currentEcosystem ? `ecosystem=${currentEcosystem.uuid}&` : '';
    const reqLink = `x-request-id=${header['x-request-id']}`;
    const helpLink = `${process.env.REACT_APP_KB_URL}/err/${code}#${customerLink}${ecosystemLink}user=${user.uuid}&${reqLink}&status=${status}&code=${code}`;
    return helpLink;
  };

  if (!errorKey || !error || !error.response) return null;

  const {
    data: { code, message, debug_info: debugInfo },
  } = error.response;
  const msg = message || debugInfo;

  return (
    <div className="fetch-error-message">
      <div className="fetch-error-message--container">
        <div className="d-flex">
          <ErrorOutlineIcon fontSize="large" />
          <p className="fetch-error-message--code">{`Error ${code}:`}</p>
          <p className="fetch-error-message--text">&quot;{upperFirst(msg)}&quot;</p>
        </div>
        <div className="d-flex">
          <div className="show-detail" onClick={() => setShowInfo(!showInfo)}>
            {showInfo ? 'Hide Details' : 'See Details'}
          </div>
          <div className="divider">|</div>
          <a href={getHelp()} target="_blank" rel="noopener noreferrer">
            <div className="fetch-error-message--help">Get Help</div>
          </a>
          <div className="divider">|</div>
          <IconButton onClick={() => deleteError()} sx={{ color: '#a94442' }}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      {showInfo && (
        <p className="debug_info">
          <b>Debug info: </b>
          &quot;{debugInfo}&quot;
        </p>
      )}
    </div>
  );
};

GlobalError.propTypes = {
  user: PropTypes.object.isRequired,
  customers: PropTypes.object.isRequired,
  ecosystems: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  deleteError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  customers: state.customers,
  ecosystems: state.ecosystems,
  errors: state.errors,
});
const mapDispatchToProps = (dispatch) => ({
  deleteError: () => dispatch(deleteError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(GlobalError);
