import { createTheme as createMuiTheme } from '@mui/material/styles';

const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      sizeLarge: {
        fontSize: 16,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: '14px',
        fontSize: 16,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 16,
      },
    },
  },
};

const palette = {
  primary: {
    main: '#4986c5',
    dark: '#4a7aad',
  },
  error: {
    main: '#ff4a55',
  },
};

export const createTheme = () => {
  const theme = createMuiTheme({
    components,
    palette,
  });

  return theme;
};
