import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Form from '../Form';

import './styles.scss';

const CommitReminder = ({ onClose }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="commit-reminder">
      <div className="commit-reminder-dialog">
        <div className="description">
          After modifying the configuration, press &quot;<b>Apply Changes</b>&quot; and &quot;<b>Commit</b>&quot; to
          make them active
        </div>
        <div className="action-container">
          <Form.Checkbox
            checked={checked}
            onChange={(ev) => setChecked(ev)}
            label="Don't show this message in the future"
          />
          <Button bsSize="large" bsStyle="primary" onClick={() => onClose(checked)}>
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

CommitReminder.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CommitReminder;
