import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl, Button, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { setRefreshTimer } from 'store/ecosystems/actions';

const RefreshTimer = ({ disabled, value, onRefresh, onChangeRefresh }) => (
  <Stack direction="row" alignItems="center" spacing={2}>
    <FormControl fullWidth disabled={disabled}>
      <InputLabel sx={{ fontSize: 16 }} id="refresh-timer">
        Refresh Interval
      </InputLabel>
      <Select
        label="Refresh Interval"
        labelId="refresh-timer"
        value={value}
        onChange={(e) => onChangeRefresh(e.target.value)}
        sx={{ fontSize: 16, width: 160 }}
      >
        <MenuItem sx={{ fontSize: 16 }} value={0}>
          Off
        </MenuItem>
        <MenuItem sx={{ fontSize: 16 }} value={1}>
          1 minute
        </MenuItem>
        <MenuItem sx={{ fontSize: 16 }} value={5}>
          5 minutes
        </MenuItem>
        <MenuItem sx={{ fontSize: 16 }} value={10}>
          10 minutes
        </MenuItem>
      </Select>
    </FormControl>
    <Button
      variant="contained"
      onClick={onRefresh}
      color="primary"
      disabled={disabled}
      size="large"
      sx={{ fontSize: 12 }}
    >
      Refresh
    </Button>
  </Stack>
);

RefreshTimer.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onRefresh: PropTypes.func,
  onChangeRefresh: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onChangeRefresh: (value) => dispatch(setRefreshTimer(value)),
});

export default connect(null, mapDispatchToProps)(RefreshTimer);
