import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const ThreatChip = ({ color, text, label, textProps, testId, spacing = 0 }) => (
  <Box display="flex">
    <Box sx={{ backgroundColor: color, borderRadius: 2, width: '6px' }} />
    <Box sx={{ pl: `${spacing}px` }}>
      <Typography sx={{ ...textProps, color: '#1A2E42' }} data-testid={`${testId}-threat`}>
        {text}
      </Typography>
      <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: '20px', color: '#A3ABB3' }}>{label}</Typography>
    </Box>
  </Box>
);

ThreatChip.propTypes = {
  color: PropTypes.string,
  text: PropTypes.any,
  label: PropTypes.any,
  textProps: PropTypes.object,
  testId: PropTypes.string,
  spacing: PropTypes.number,
};

export default ThreatChip;
