import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { IconButton, MenuItem, Popover, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from 'react-bootstrap';
import cx from 'classnames';

import { ECOSYSTEM_STATUS, NSP_REGIONS } from '../../../../enum';
import NSPMap from '../../../../assets/NSPMap';

import './styles.scss';

const CustomSlider = styled(Slider)(
  () => `
  &.MuiSlider-root {
    margin-bottom: 8px;
    padding-top: 0;
  }
  .MuiSlider-thumb {
    background-color: #35BEEA;
    margin-top: -2px;
    width: 24px;
    height: 24px;
  },
  .MuiSlider-mark {
    background-color: #35BEEA;
    height: 8px;
    margin-top: -8px;
  },
  .MuiSlider-markActive {
    background-color: #35BEEA;
  },
  .MuiSlider-markLabel {
    top: -20px;
    font-size: 12px;
  },
  .MuiSlider-track {
    height: 20px;
    border-radius: 10px;
    background-color: #35BEEA;
  },
  .MuiSlider-rail {
    height: 20px;
    border-radius: 10px;
  }
`,
);

const bandWidthRange = [
  { value: 100, label: '100' },
  { value: 250, label: '250' },
  { value: 500, label: '500' },
  { value: 750, label: '750' },
  { value: 1000, label: '1000' },
  { value: 1500, label: '1500' },
  { value: 2000, label: '2000' },
];

const EcosystemCard = ({ data, isNew = false, onSave, onRename, onCancel, onRecover, onUpgrade, onRemove }) => {
  const location = useLocation();
  const history = useHistory();
  const anchorRef = useRef(null);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [name, setName] = useState(data.name);
  const [error, setError] = useState(null);
  const [nameEditable, setNameEditable] = useState(!!isNew);
  const [regions, setRegions] = useState(data.regions.map((region) => region.name));
  const [bandWidth, setBandWidth] = useState(bandWidthRange[0].value);

  const nameRef = useRef(null);

  const isDisabled = [
    ECOSYSTEM_STATUS.DISABLED,
    ECOSYSTEM_STATUS.ECOSYSTEM_ERROR,
    ECOSYSTEM_STATUS.COMMIT_ERROR,
    ECOSYSTEM_STATUS.DELETED,
  ].includes(data.status);
  const isDeleted = data.status === ECOSYSTEM_STATUS.DELETED;
  const isError = [ECOSYSTEM_STATUS.COMMIT_ERROR, ECOSYSTEM_STATUS.ECOSYSTEM_ERROR].includes(data.status);
  const helpLink = `${process.env.REACT_APP_KB_URL}/err/${data.status}`;

  useEffect(() => {
    if (nameEditable) {
      nameRef.current.focus();
    }
  }, [nameEditable]);

  const regionLabel = (value) => {
    const regKey = Object.keys(NSP_REGIONS).find((key) => NSP_REGIONS[key].value === value);
    return NSP_REGIONS[regKey].label;
  };

  const handleHelp = (event) => {
    event.preventDefault();
    window.open(helpLink, '_blank');
  };

  const handldeOpen = (data) => {
    const customerId = location.pathname.split('/')[3];
    history.push(`/v1/customers/${customerId}/ecosystems/${data.uuid}`);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleChangeName = (event) => {
    event.preventDefault();
    setName(event.target.value);
    setError({ ...error, name: '' });
  };

  const onNameChange = (event) => {
    event.preventDefault();
    if (name.length > 250) {
      setError({ ...error, name: 'Name cannot be more than 250 characters.' });
      nameRef.current.focus();
      return;
    }
    if (!isNew) {
      if (name && name !== data.name) {
        onRename({ uuid: data.uuid, name });
      } else {
        setName(data.name);
      }
      setNameEditable(false);
    }
  };

  const handleChangeRegion = (region) => {
    if (!isNew) return;
    setError({ ...error, region: '' });
    setRegions([region]);
  };

  const handleChangeBandwidth = (event, value) => {
    event.preventDefault();
    setBandWidth(value);
  };

  const handleSave = () => {
    setError(null);
    if (!name || name.length > 250) {
      setError({ ...error, name: 'Name cannot be more than 250 characters.' });
      nameRef.current.focus();
      return;
    }
    if (!regions.length) {
      setError({ ...error, region: 'Please select a region associated with your ecosystem.' });
      return;
    }
    const regionPayload = regions.map((region) => ({
      name: region,
      bandwidth: bandWidth,
    }));
    onSave({ name, regions: regionPayload });
  };

  const renderFooterButtons = () => {
    if (isNew) {
      return (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button bsStyle="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      );
    }
    if (isDeleted) {
      return <p className="delete_label">Delete in progress</p>;
    }
    if (isError) {
      return (
        <Button bsStyle="danger" onClick={onRecover}>
          Recover
        </Button>
      );
    }
    return (
      <Button bsStyle="primary" onClick={() => handldeOpen(data)} disabled={isDisabled}>
        Open
      </Button>
    );
  };

  const handleRename = (event) => {
    event.preventDefault();
    setNameEditable(true);
    setOpenMenu(false);
  };

  const handleUpgrade = (event) => {
    event.preventDefault();
    onUpgrade();
    setOpenMenu(false);
  };

  const handleRecover = (event) => {
    event.preventDefault();
    onRecover();
    setOpenMenu(false);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    onRemove();
    setOpenMenu(false);
  };

  return (
    <div className="ecosystemCard item">
      <div className={cx('ecosystemCard__header', { disabled: isDisabled })}>
        <div className="ecosystem-name">
          {error && error.name ? <p className="label error">{error.name}</p> : <p className="label">Ecosystem Name</p>}
          <div className="name">
            {nameEditable ? (
              <form style={{ width: '100%' }} onSubmit={onNameChange}>
                <input ref={nameRef} value={name} onChange={handleChangeName} onBlur={onNameChange} />
              </form>
            ) : (
              <span title={name}>{name}</span>
            )}
          </div>
          {!isNew && !isDeleted && (
            <div className="ecosystem-options">
              <IconButton ref={anchorRef} onClick={handleOpenMenu}>
                <MoreVertIcon sx={{ color: 'white', fontSize: 32 }} fontSize="large" />
              </IconButton>
              <Popover
                anchorEl={anchorRef.current}
                onClose={handleCloseMenu}
                open={isOpenMenu}
                PaperProps={{ sx: { width: 160 } }}
              >
                <MenuItem disabled={isDisabled} onClick={() => handldeOpen(data)} sx={{ fontSize: 14 }}>
                  Open
                </MenuItem>
                <MenuItem disabled={isDisabled} onClick={handleRename} sx={{ fontSize: 14 }}>
                  Rename
                </MenuItem>
                <MenuItem
                  disabled={
                    data.status !== ECOSYSTEM_STATUS.OK ||
                    !(data.nsps && data.nsps[0] && data.nsps[0].status === 'green')
                  }
                  onClick={handleUpgrade}
                  sx={{ fontSize: 14 }}
                >
                  Upgrade
                </MenuItem>
                <MenuItem disabled={!isError} onClick={handleRecover} sx={{ fontSize: 14 }}>
                  Recover
                </MenuItem>
                <MenuItem onClick={handleDelete} sx={{ fontSize: 14 }}>
                  Delete
                </MenuItem>
                <MenuItem disabled={!isDisabled} onClick={handleHelp} sx={{ fontSize: 14 }}>
                  Help
                </MenuItem>
              </Popover>
            </div>
          )}
        </div>
        <div className="ecosystem-date">
          {dayjs(data.created).format('D MMMM, YYYY')}
          &nbsp;|&nbsp;
          {dayjs(data.created).format('h:mm A')}
        </div>
      </div>
      <div className="ecosystemCard__body">
        {/* <div className="nsp-map">
          <NSPMap regions={regions} onClick={handleChangeRegion} />
        </div> */}
        <div className="ecosystem-nsps">
          {isNew ? (
            <p className="label">Please select a region where your users and offices are located</p>
          ) : (
            <>
              <p>Associated NSP</p>
              <div className="regions">
                {regions.map((region) => (
                  <div key={region}>{regionLabel(region)}</div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="nsp-map">
          <NSPMap regions={regions} onClick={handleChangeRegion} />
        </div>
        {isNew && (
          <>
            <div className="selected-region">
              <p>Selected region:</p>
              <div className="regions">
                {error && error.region && <p className="label error">{error.region}</p>}
                {regions.map((region) => (
                  <div key={region} onClick={() => handleChangeRegion(region)}>
                    {regionLabel(region)}
                  </div>
                ))}
              </div>
            </div>
            <div className="bandwidth">
              <p className="bandwidth__label">
                Starting bandwidth for selected region:&nbsp;
                <span className="bandwidth_value">{bandWidth} Mbps</span>
              </p>
              <div className="slider-container">
                <CustomSlider
                  value={bandWidth}
                  step={null}
                  min={100}
                  max={2000}
                  marks={bandWidthRange}
                  onChange={handleChangeBandwidth}
                />
              </div>
              <p className="best-match">- Best for {bandWidth / 1} users for email, office applications</p>
              <p className="best-match">- Best for {parseInt(bandWidth / 3, 10)} users for video, file transfers</p>
              <p className="note">You can add additional regions and bandwidth under &apos;Ecosystem Settings&apos;</p>
            </div>
          </>
        )}
      </div>
      <div className={cx('ecosystemCard__footer', { deleted: isDeleted })}>{renderFooterButtons()}</div>
    </div>
  );
};

EcosystemCard.propTypes = {
  data: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  onSave: PropTypes.func,
  onRename: PropTypes.func,
  onCancel: PropTypes.func,
  onRecover: PropTypes.func,
  onUpgrade: PropTypes.func,
  onRemove: PropTypes.func,
};

export default EcosystemCard;
