import { combineReducers } from 'redux';

import { authReducer } from './store/auth/reducer';
import { ecosystemsReducer } from './store/ecosystems/reducer';
import { objectsReducer } from './store/objects/reducer';
import { policyReducer } from './store/policies/reducer';
import { reportsReducer } from './store/reports';
import { globalReducer } from './store/global/reducer';
import { userReducer } from './store/user/reducer';
import { customersReducer } from './store/customers/reducer';
import { DNATsReducer } from './store/dNATs/reducer';
import paymentReducer from './store/payment/reducer';
import errorReducer from './store/errors/reducer';
import loadingReducer from './store/loading/reducer';
import decryptionReducer from './store/decryption/reducer';
import supportReducer from './store/support/reducer';

export default combineReducers({
  auth: authReducer,
  customers: customersReducer,
  reports: reportsReducer,
  ecosystems: ecosystemsReducer,
  objects: objectsReducer,
  policies: policyReducer,
  DNATs: DNATsReducer,
  loading: loadingReducer,
  errors: errorReducer,
  global: globalReducer,
  decryption: decryptionReducer,
  support: supportReducer,
  user: userReducer,
  payment: paymentReducer,
});
