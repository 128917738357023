import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import { EMPTY_EMAIL_ERROR } from '../../enum/constants';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './styles.scss';

const { ExportCSVButton } = CSVExport;

const Table = ({
  keyField,
  data: tableData,
  columns,
  searchText,
  onSelect,
  onSelectAll,
  nonSelectable,
  clickToSelect = true,
}) => {
  const [data, setData] = useState(tableData);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const selectedRows = [];
    tableData.forEach((user) => user.selected && selectedRows.push(user.key));
    setSelected(selectedRows);
  }, [tableData]);

  const selectRow = {
    mode: 'checkbox',
    clickToSelect,
    selected,
    onSelect: (row, isSelect) => {
      onSelect(tableData.map((user) => (user.uuid === row.uuid ? { ...user, selected: isSelect } : user)));
    },
    onSelectAll: (isSelect) => {
      const tempUserData = _.cloneDeep(tableData);
      data.forEach((user) => {
        if (user.email !== EMPTY_EMAIL_ERROR) {
          const ind = tempUserData.findIndex((u) => u.uuid === user.uuid);
          tempUserData[ind].selected = isSelect;
        }
      });
      onSelectAll(tempUserData);
    },
    nonSelectable,
  };

  const rowStyle = (row) => {
    const style = {};
    if (row.activationStatus === 'disabled') {
      style.backgroundColor = '#e1e1e1';
    }
    return style;
  };

  const searching = useCallback(
    (item) => {
      const term = searchText.toLowerCase();
      return Boolean(item && typeof item === 'string' && item.toLowerCase().includes(term));
    },
    [searchText],
  );

  useEffect(() => {
    setData(tableData.filter((item) => Object.keys(item).some((key) => searching(_.get(item, key, '')))));
  }, [tableData, searchText, searching]);

  return (
    <ToolkitProvider keyField={keyField} data={data} columns={columns} exportCSV={{ fileName: 'data.csv' }}>
      {(props) => (
        <>
          <BootstrapTable
            selectRow={selectRow}
            headerWrapperClasses="header__wrapper"
            columnToggle
            pagination={paginationFactory()}
            noDataIndication="No data to display"
            rowStyle={rowStyle}
            {...props.baseProps}
          />
          <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton>
        </>
      )}
    </ToolkitProvider>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  nonSelectable: PropTypes.array,
  keyField: PropTypes.string,
  searchText: PropTypes.string,
  baseProps: PropTypes.any,
  csvProps: PropTypes.any,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  clickToSelect: PropTypes.bool,
};

export default Table;
