/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import get from 'lodash/get';

import Card from '../components/Card';
import Form from '../components/Form';
import SelectWithSearch from '../components/SelectWithSearch';
import CreateGroupForm from '../components/CreateGroupForm';
import Descriptor from '../components/Descriptor';

import { EXPIRATION_TYPE } from '../enum/expiration';
import { LOCATION_TYPE_OPTIONS, DEFAULT_LOCATION } from '../enum/location';
import { OBJECT_CATEGORIES, OBJECT_TYPE_OPTIONS } from '../enum/object';
import Translator from '../utils/enumTranslator';
import populator from '../utils/populator';
import thingValidationSchema from '../validationSchemas/thingValidationSchema';
import { Footer } from './commons';

import './modals.scss';

const ExpiryInput = ({ value, onClick }) => <input className="expiry-input" value={value} onClick={onClick} readOnly />;

class NewThingSurvey extends React.Component {
  constructor(props) {
    super(props);
    if (props.edit) {
      this.state = {
        uuid: props.item.uuid,
        name: get(props, 'item.name'),
        category: Translator.category(props.item.category),
        type: OBJECT_TYPE_OPTIONS[0],
        asset: props.item.assetValue,
        profile: populator.group(props.groups.find((gr) => gr.uuid === props.item.profileGroup.uuid)),
        expiryTime: props.item.expiry && props.item.expiry.time ? new Date(props.item.expiry.time) : null,
        expiryType: props.item.expiry && props.item.expiry.type ? props.item.expiry.type : EXPIRATION_TYPE.SOFT,
        desc: props.item.description,
        location: props.item.geolocation ? LOCATION_TYPE_OPTIONS[0] : LOCATION_TYPE_OPTIONS[1],
        lat: (!!props.item.geolocation && props.item.geolocation.latitude) || DEFAULT_LOCATION.LAT,
        lng: (!!props.item.geolocation && props.item.geolocation.longitude) || DEFAULT_LOCATION.LNG,
        errors: [],
        isSubmitted: false,
        tabKey: 1,
      };
    } else {
      this.state = {
        name: '',
        profile: props.groups.length === 1 ? populator.group(props.groups[0]) : null,
        category: null,
        type: OBJECT_TYPE_OPTIONS[0],
        asset: 1,
        expiryTime: null,
        expiryType: EXPIRATION_TYPE.SOFT,
        desc: '',
        location: LOCATION_TYPE_OPTIONS[0],
        lng: DEFAULT_LOCATION.LNG,
        lat: DEFAULT_LOCATION.LAT,
        errors: [],
        isSubmitted: false,
        tabKey: 1,
      };
    }
  }

  componentDidUpdate(prevProps) {
    const { createdObject } = this.props;
    if (createdObject !== prevProps.createdObject && createdObject.field === 'thing') {
      const group = {
        value: createdObject.object.uuid,
        label: createdObject.object.name,
      };
      this.changeField('profile', group);
    }
  }

  changeField = (field, value) => {
    this.setState({ [field]: value });
  };

  onAssetChange = (val) => {
    const numberRegex = /^[0-9]+$/;
    const finalValue = val && val.match(numberRegex) ? Number(val) : 0;
    this.changeField('asset', finalValue);
  };

  onFinish = () => {
    this.setState(
      {
        isSubmitted: true,
      },
      () => {
        if (this.validate()) {
          this.props.onFinish(this.state);
        }
      },
    );
  };

  validate() {
    if (!this.state.isSubmitted) {
      return true;
    }
    try {
      thingValidationSchema.validateSync(this.state, { abortEarly: false });
      this.setState({
        errors: [],
      });
      return true;
    } catch (err) {
      this.contentElement.scrollIntoView({
        behavior: 'smooth',
      });
      this.setState({
        errors: err.errors,
      });
      return false;
    }
  }

  renderConfig() {
    const { tabKey, errors, nameError } = this.state;
    const { groups } = this.props;

    return (
      <div className="modal__content padded new-thing-survey">
        {tabKey === 1 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Card header={false}>
          <div className="form-row">
            <Form.Group required label="Name">
              <Form.Text value={this.state.name} onChange={(val) => this.changeField('name', val)} placeholder="Name" />
              <p className="error-text">{nameError}</p>
            </Form.Group>
            <Form.Group required label="Profile Group">
              <SelectWithSearch
                selected={this.state.profile}
                onChange={(val) => this.changeField('profile', val)}
                placeholder="Select profile group"
                groupCreative
                field="thing"
                createFormComponent={CreateGroupForm}
                options={groups.map(populator.group)}
              />
            </Form.Group>
          </div>
          <div className="form-row">
            <Form.Group required label="Category">
              <SelectWithSearch
                selected={this.state.category}
                onChange={(val) => this.changeField('category', val)}
                placeholder="Select category"
                groupCreative={false}
                options={OBJECT_CATEGORIES}
              />
            </Form.Group>
            <Form.Group label="Type">
              <p className="medium strong type">{this.state.type.label}</p>
            </Form.Group>
          </div>
          <Form.Group label="Operational Importance">
            <Form.Text value={this.state.asset} onChange={this.onAssetChange} placeholder="Asset performance" />
          </Form.Group>
          {/* Hide until API is ready
					<div className={'form-row'}>
						<Form.Group label={'Expiry'}>
							<DatePicker
								isClearable
								popperPlacement={'top-end'}
								dateFormat={'MMM dd, yyyy'}
								selected={this.state.expiryTime}
								onChange={val => this.changeField('expiryTime', val)}
								customInput={<ExpiryInput />}
							/>
						</Form.Group>
					</div> */}
        </Card>
      </div>
    );
  }

  renderDesc() {
    const { tabKey, errors } = this.state;

    return (
      <div className="modal__content padded new-thing-survey">
        {tabKey === 2 &&
          errors.map((err, index) => (
            <p className="error" key={index}>
              {err}
            </p>
          ))}
        <Descriptor
          desc={this.state.desc}
          location={this.state.location}
          lat={this.state.lat}
          lng={this.state.lng}
          onChange={(field, value) => this.changeField(field, value)}
        />
      </div>
    );
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.contentElement = node;
        }}
      >
        <Tabs defaultActiveKey={1} id="object__tabs" onSelect={(key) => this.setState({ tabKey: key })}>
          <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
            {this.renderConfig()}
          </Tab>
          <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
            {this.renderDesc()}
          </Tab>
        </Tabs>
        <div className="wedge-modal__footer">
          <Footer
            onClick={this.onFinish}
            edit={this.props.edit}
            customButtonString={this.props.edit ? 'Save' : 'Add'}
            onDelete={this.props.onDelete}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.ecosystems.dictionaries.groups,
  createdObject: state.objects.createdObject,
});

NewThingSurvey.defaultProps = {
  edit: false,
  groups: [],
};

NewThingSurvey.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  edit: PropTypes.bool,
  item: PropTypes.object,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  createdObject: PropTypes.object,
};
ExpiryInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

NewThingSurvey.Footer = Footer;

export default connect(mapStateToProps)(NewThingSurvey);
