import { ECOSYSTEM_STATUS } from '../enum';
import * as REST from '../api/rest';

import { clearData, logoutUser } from './auth/actions';
import { saveCommitID, saveRevertID } from './ecosystems/actions';
import { finishStartup } from './global/actions';
import { setError, clearPasswordError, setUser, clearUserData } from './user/actions';
import { readUserAvatar, readUserData } from './sagas/apiCalls';

export function startup() {
  return async (dispatch, getState) => {
    try {
      const {
        subsystems: { api, cm },
      } = await new Promise((resolve, reject) => {
        const connectTimeout = setTimeout(() => {
          reject(new Error('Connection timeout.'));
        }, 10000);
        REST.checkAPIStatus()
          .then((res) => {
            clearTimeout(connectTimeout);
            resolve(res);
          })
          .catch((err) => {
            clearTimeout(connectTimeout);
            reject(err);
          });
      });
      if (api.status !== 'green' || cm.status !== 'green') {
        dispatch(logoutUser());
        dispatch(setError('Our systems are undergoing maintenance. Please try again later.'));
        dispatch(clearData());
      }
      if (getState().auth.isAuthenticated && getState().auth.username) {
        const email = getState().auth.username;
        const [avatar, user] = await Promise.all([readUserAvatar(email), readUserData(email)]);
        dispatch(setUser({ ...user, avatar }));
      }
    } catch (error) {
      dispatch(logoutUser());
      dispatch(setError('Cannot connect to our servers. Please check your network or try again later.'));
      dispatch(clearData());
    }
    dispatch(finishStartup());
    dispatch(clearPasswordError());
  };
}

export function acceptCommit() {
  return async (dispatch, getState) => {
    const ecosystem = getState().ecosystems.currentEcosystem;
    const customer = getState().customers.currentCustomer;
    try {
      const commitHeader = await REST.acceptCommit({ customer, ecosystem });
      dispatch(
        saveCommitID({
          reqID: commitHeader['x-request-id'],
          ecoId: ecosystem.uuid,
          status: ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS,
        }),
      );
    } catch (err) {
      dispatch(
        saveCommitID({
          reqID: err.response.headers['x-request-id'],
          ecoId: ecosystem.uuid,
          status: ECOSYSTEM_STATUS.COMMIT_ERROR,
        }),
      );
    }
  };
}

export function acceptRollback() {
  return async (dispatch, getState) => {
    const ecosystem = getState().ecosystems.currentEcosystem;
    const customer = getState().customers.currentCustomer;
    try {
      const revertHeader = await REST.acceptRollback({ customer, ecosystem });
      dispatch(
        saveRevertID({
          reqID: revertHeader['x-request-id'],
          ecoId: ecosystem.uuid,
          status: ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS,
        }),
      );
    } catch (err) {
      dispatch(
        saveRevertID({
          reqID: err.response.headers['x-request-id'],
          ecoId: ecosystem.uuid,
          status: ECOSYSTEM_STATUS.COMMIT_ERROR,
        }),
      );
    }
  };
}

export function logout() {
  return async (dispatch) => {
    try {
      await REST.logout();
    } finally {
      dispatch(logoutUser());
      dispatch(setError('You have been logged out'));
      dispatch(clearData());
      dispatch(clearUserData());
    }
  };
}
