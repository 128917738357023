import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';

import Card from 'components/Card';
import CustomMap from 'components/CustomMap';
import Field from 'components/Field';
import { LOCATION_TYPE_OPTIONS } from 'enum/location';
import { readGateway } from 'views/Objects/scenario-actions';

import GatewayConfig from './GatewayConfig';

import './styles.scss';
import '../../modals.scss';

class GatewayDetailsModal extends React.Component {
  componentDidMount() {
    const { data } = this.props;
    this.props.readGateway(data);
  }

  renderDesc() {
    const { data } = this.props;
    const locaton = data.geolocation ? LOCATION_TYPE_OPTIONS[0].label : LOCATION_TYPE_OPTIONS[1].label;

    return (
      <div className="modal__content padded new-gateway-survey">
        <Card header={false}>
          {data.description && (
            <Field.Group label="Description" full>
              <Field.Text text={data.description} />
            </Field.Group>
          )}
          <Field.Group label="Location">
            <Field.Text text={locaton} />
          </Field.Group>
          {!!data.geolocation && (
            <React.Fragment>
              <div className="form-row">
                <Field.Group label="Latitude" extraClass="flex-3">
                  <Field.Text text={data.geolocation.latitude} />
                </Field.Group>
                <Field.Group label="Longitude" extraClass="flex-2">
                  <Field.Text text={data.geolocation.longitude} />
                </Field.Group>
              </div>
              <div>
                <CustomMap
                  center={{
                    lat: data.geolocation.latitude,
                    lng: data.geolocation.longitude,
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }

  render() {
    const { data, onUpdateWGKeys } = this.props;
    return (
      <Tabs defaultActiveKey={1} id="object__tabs">
        <Tab eventKey={1} title="Configuration" tabClassName="object__tab">
          <GatewayConfig data={data} onUpdateWGKeys={onUpdateWGKeys} />
        </Tab>
        <Tab eventKey={2} title="Descriptors" tabClassName="object__tab">
          {this.renderDesc()}
        </Tab>
      </Tabs>
    );
  }
}

GatewayDetailsModal.propTypes = {
  data: PropTypes.object.isRequired,
  readGateway: PropTypes.func,
  onUpdateWGKeys: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  readGateway: (entity) => dispatch(readGateway(entity)),
});

export default connect(null, mapDispatchToProps)(GatewayDetailsModal);
