import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Typography, useTheme } from '@mui/material';

const TopChart = ({ from, to, values, total }) => {
  const theme = useTheme();

  const count = values.length;
  const timeGap = Number(((to - from) / count).toFixed(0));
  const timeStamps = values.map((val, index) => from + timeGap * index);

  const options = {
    chart: {
      background: 'transparent',
      toolbar: { show: false },
      sparkline: { enabled: true },
      zoom: { enabled: false },
    },
    colors: ['#FF771D'],
    dataLabels: { enabled: false },
    theme: { mode: theme.palette.mode },
    stroke: {
      show: true,
      colors: ['#FF771D'],
      curve: 'smooth',
      width: 2,
    },
    legend: { show: false },
    xaxis: {
      type: 'datetime',
      categories: timeStamps,
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    tooltip: {
      enabled: false,
      // x: {
      //   format: 'hh:mm',
      // },
    },
  };

  const series = [{ name: 'Total', data: values || [] }];
  return (
    <Box>
      <Box sx={{ maxHeight: 50 }}>
        <Chart options={options} series={series} type="area" height="50" width="100" />
      </Box>
      <Typography textAlign="center" sx={{ fontSize: 16, fontWeight: 500, lineHeight: '20px', color: '#1A2E42' }}>
        {total}
      </Typography>
    </Box>
  );
};

TopChart.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  total: PropTypes.number,
  values: PropTypes.array,
};

export default TopChart;
