import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import SeverityPill from 'components/SeverityPill';

const STATUS = {
  1: 'New',
  2: 'Active',
  3: 'Pending Acreto',
  4: 'Closed',
  5: 'Pending Customer',
  7: 'Pending Closed',
};

const StatusChip = ({ stateId }) => {
  const color = useMemo(() => {
    if (stateId === 1) return 'info';
    if (stateId === 2) return 'success';
    if (stateId === 3) return 'secondary';
    if (stateId === 4) return 'disabled';
    return 'warning';
  }, [stateId]);
  return (
    <SeverityPill color={color} data-testid="chip-status">
      {STATUS[stateId]}
    </SeverityPill>
  );
};

StatusChip.propTypes = {
  stateId: PropTypes.number,
};

export default StatusChip;
