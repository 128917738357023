import React from 'react';
import PropTypes from 'prop-types';

import { LOCATION_TYPE_OPTIONS, LOCATION_TYPE } from '../../enum/location';
import Card from '../Card';
import Form from '../Form';
import CustomMap from '../CustomMap';
import SelectWithSearch from '../SelectWithSearch';

import './styles.scss';

const Descriptor = ({ onChange, location, desc, lat, lng }) => {
  const handleChangeLat = (event) => {
    if (
      (event.target.name === 'lat' && Math.abs(event.target.value) > 90) ||
      (event.target.name === 'lng' && Math.abs(event.target.value) > 180)
    ) {
      return;
    }
    if (event.target.name === 'lat' || event.target.name === 'lng') {
      onChange(event.target.name, Number(event.target.value));
      return;
    }
    onChange(event.target.name, event.target.value);
  };

  const handleCenterChanged = (center) => {
    onChange('lat', Number(center.lat().toFixed(6)));
    onChange('lng', Number(center.lng().toFixed(6)));
  };

  return (
    <Card header={false}>
      <div className="form-row">
        <Form.Group label="Description" full>
          <Form.Text value={desc} onChange={(val) => onChange('desc', val)} placeholder="Description" />
        </Form.Group>
      </div>
      <div className="form-row">
        <Form.Group label="Location">
          <SelectWithSearch
            selected={location}
            onChange={(val) => onChange('location', val)}
            placeholder="Select Location Type"
            groupCreative={false}
            options={LOCATION_TYPE_OPTIONS}
          />
        </Form.Group>
      </div>
      {location.value !== LOCATION_TYPE.UNDEFINED && (
        <React.Fragment>
          <div className="form-row latng__input">
            <Form.Group label="Latitude">
              <input
                name="lat"
                className="coordinates__input"
                placeholder="Latitude"
                type="number"
                min="-90"
                max="90"
                value={lat}
                onChange={handleChangeLat}
              />
            </Form.Group>
            <Form.Group label="Longitude">
              <Form.Text
                name="lng"
                className="coordinates__input"
                placeholder="Longitude"
                type="number"
                min="-180"
                max="180"
                value={lng}
                onChange={(val) => onChange('lng', val)}
              />
            </Form.Group>
          </div>
          <div className="space-above">
            <CustomMap center={{ lat, lng }} onCenterChange={handleCenterChanged} />
          </div>
        </React.Fragment>
      )}
    </Card>
  );
};

Descriptor.propTypes = {
  desc: PropTypes.string,
  location: PropTypes.object.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Descriptor;
