import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from '../components/Form';
import { ConfirmFooter } from './commons';

const ConfirmAction = ({ item, action, confirmByTyping = false, ...props }) => {
  const [name, setName] = useState('');

  return (
    <div>
      <div className="action__confirm">
        {confirmByTyping ? (
          <>
            <p className="title">
              To {action} <b>{item.name}</b>, please type the name of this&nbsp;
              {item.type}:
            </p>
            <Form.Group>
              <Form.Text value={name} onChange={(name) => setName(name)} placeholder="Name" required />
            </Form.Group>
          </>
        ) : (
          <p>
            You want to {action} <b>{item.name}</b> ?
          </p>
        )}
      </div>

      <div className="wedge-modal__footer">
        <ConfirmFooter disabled={confirmByTyping && name !== item.name} onYes={props.onYes} onNo={props.onNo} />
      </div>
    </div>
  );
};

ConfirmAction.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  action: PropTypes.string.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  confirmByTyping: PropTypes.bool,
};

export default ConfirmAction;
