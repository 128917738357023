import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import cx from 'classnames';
import { string, number, shape, arrayOf } from 'prop-types';

import './styles.scss';

const Stepper = ({ steps, activeStepIndex }) => (
  <div className="stepper-container">
    {steps.map(({ title }, index) => {
      const active = index === activeStepIndex;
      const done = index < activeStepIndex;
      const pending = index > activeStepIndex;
      const last = steps.length - index === 1;
      return (
        <div key={title} className="single-step-container">
          <div
            className={cx('step-title', {
              active,
            })}
          >
            {title}
          </div>
          <div
            className={cx('round-step-wrapper', {
              active,
              done,
              pending,
              last,
            })}
          >
            <div
              className={cx('round-step', {
                active,
                done,
                pending,
                last,
              })}
            >
              {done ? <CheckIcon fontSize="large" /> : index + 1}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

Stepper.propTypes = shape({
  steps: arrayOf(shape({ title: string })),
  activeStepIndex: number,
}).isRequired;

export default Stepper;
