import React, { useCallback, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/LoadingSpinner';
import { ROUTES } from 'enum';
import { oAuthProviderCallback } from '../scenario-actions';

function OAuth({ location, match, oAuthProviderCallback }) {
  const history = useHistory();
  const {
    params: { provider },
  } = match;
  const { search } = location;

  const handleCallback = useCallback(
    async ({ provider, query }) => {
      try {
        await oAuthProviderCallback({ provider, query });
      } catch (err) {
        history.push(ROUTES.LOGIN);
      }
    },
    [history, oAuthProviderCallback],
  );

  useEffect(() => {
    if (search && provider) {
      handleCallback({ provider, query: search });
    }
  }, [search, provider, handleCallback]);

  return <LoadingSpinner />;
}

OAuth.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  oAuthProviderCallback: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      oAuthProviderCallback,
    },
    dispatch,
  );

export default withRouter(connect(null, mapDispatchToProps)(OAuth));
