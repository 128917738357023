import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Card = ({ category, children, className, hCenter, header, plain, title }) => (
  <div className={cx('card', { 'card-plain': plain }, className)}>
    {header && (
      <div className={cx('headers', { 'text-center': hCenter })}>
        <h4 className="title">{title}</h4>
        <p className="category">{category}</p>
      </div>
    )}
    <div className="content">{children}</div>
  </div>
);

Card.propTypes = {
  className: PropTypes.string,
  header: PropTypes.bool,
  plain: PropTypes.bool,
  hCenter: PropTypes.bool,
  title: PropTypes.string,
  category: PropTypes.string,
  children: PropTypes.any,
};

Card.defaultProps = {
  header: true,
};

export default Card;
