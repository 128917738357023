import React from 'react';
import { Container, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const PageTitle = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(4, 0)};
`,
);

const PageTitleWrapper = ({ children, size = 'lg' }) => (
  <>
    <PageTitle>
      <Container maxWidth={size}>{children}</Container>
    </PageTitle>
  </>
);

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
};

export default PageTitleWrapper;
