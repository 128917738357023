import React from 'react';
import { Box, Button, Popover, Stack } from '@mui/material';
import PropTypes from 'prop-types';

const CommitPopover = ({ anchorEl, onClose, onRollback, onCommit }) => (
  <Popover
    sx={{ zIndex: 1400 }}
    id={anchorEl ? 'commit-popover' : undefined}
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 0,
      },
    }}
  >
    <Box
      sx={{
        position: 'relative',
        mt: '10px',
        '&::before': {
          backgroundColor: 'white',
          content: '""',
          display: 'block',
          position: 'absolute',
          width: 12,
          height: 12,
          top: -6,
          transform: 'rotate(45deg)',
          left: 'calc(50% - 6px)',
          borderTop: '1px solid rgba(0, 0, 0, 0.25)',
          borderLeft: '1px solid rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <Stack
        className="commit-actions"
        sx={{ backgroundColor: 'white', px: 2, py: 1.5, border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: 2 }}
        direction="row"
        spacing={2}
      >
        <Button
          className="revert-button"
          color="inherit"
          variant="outlined"
          size="large"
          sx={{
            fontSize: 14,
            lineHeight: '16px',
            borderColor: '#D6D6D6',
            color: '#707070',
            fontFamily: 'inherit',
            borderRadius: 12,
          }}
          onClick={onRollback}
          data-testid="button-revert"
        >
          Revert
        </Button>
        <Button
          className="commit-button"
          variant="contained"
          size="large"
          sx={{
            fontSize: 14,
            lineHeight: '16px',
            fontFamily: 'inherit',
            borderRadius: 12,
          }}
          onClick={onCommit}
          data-testid="button-commit"
        >
          Commit
        </Button>
      </Stack>
    </Box>
  </Popover>
);

CommitPopover.propTypes = {
  anchorEl: PropTypes.node,
  onClose: PropTypes.func,
  onRollback: PropTypes.func,
  onCommit: PropTypes.func,
};

export default CommitPopover;
