import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

import { ECOSYSTEM_STATUS, CONSTANTS } from '../../enum';
import './styles.scss';

const errMsg = {
  [ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS]: {
    label: 'Commit is in Progress...',
    desc: 'In some cases, commit can take up to 5 minutes.',
  },
  [ECOSYSTEM_STATUS.COMMIT_ERROR]: {
    label: 'Commit error',
    desc: (
      <>
        Last commit failed. Please&nbsp;
        <a href={`${process.env.REACT_APP_KB_URL}/err/ecosystem_error`} target="_blank" rel="noopener noreferrer">
          contact support
        </a>
      </>
    ),
  },
  [ECOSYSTEM_STATUS.ECOSYSTEM_ERROR]: {
    label: 'Ecosystem error',
    desc: (
      <>
        There is an issue with your ecosystem. Please&nbsp;
        <a href={`${process.env.REACT_APP_KB_URL}/err/ecosystem_error`} target="_blank" rel="noopener noreferrer">
          contact support
        </a>
      </>
    ),
  },
  [ECOSYSTEM_STATUS.DISABLED]: {
    label: 'Disabled',
    desc: (
      <>
        This ecosystem is disabled. Please&nbsp;
        <a href={`${process.env.REACT_APP_KB_URL}/err/ecosystem_error`} target="_blank" rel="noopener noreferrer">
          contact support
        </a>
      </>
    ),
  },
  [CONSTANTS.INACCESSIBLE]: {
    label: "You don't have access to this resource.",
    desc: ' If you consider this invalid, please contact your Organization admin or contact us, providing incident ID: xxxxxx.',
  },
};

const BlockedEcosystem = ({ status }) => (
  <div className="ecosystem-block content">
    {status === ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS && (
      <div className="loader-container">
        <CircularProgress />
      </div>
    )}
    <p className="block-label">{errMsg[status].label}</p>
    <p className="block-desc">{errMsg[status].desc}</p>
  </div>
);

BlockedEcosystem.propTypes = {
  status: PropTypes.string.isRequired,
};

export default BlockedEcosystem;
