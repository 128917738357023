import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { BACK_ARROW_LINK, LOGIN_FOOTER, NOT_FOUND_404 } from '../../assets/Icons';
import Header from '../../components/Header/Header';
import './NotFound.scss';

const NotFound = (props) => {
  const renderContent = () => {
    const errorCode = props.location.state ? props.location.state.errorCode : 404;
    const headerString = `I can't seem to find the page you're looking for!`;
    const subHeaderString = `It's either no longer here or it was never here in the first place.`;

    return (
      <>
        <Helmet>
          <title>Not Found</title>
        </Helmet>
        <div className="content">
          <div className="login-form">
            <div className="left Grid-cell">
              <img src={NOT_FOUND_404} alt="Not Found" />
            </div>
            <div className="right Grid-cell">
              <div className="group">
                <h1 className="title">{errorCode}</h1>
                <p className="header">{headerString}</p>
                <p className="subHeader">{subHeaderString}</p>
                <NavLink to="/v1" className="nav-link link">
                  <img src={BACK_ARROW_LINK} alt="back-arrow-link" className="back-arrow" />
                  <p className="link--text">Go to Homepage</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="wrapper notFound-wrapper">
        <Header {...props} />
        {renderContent()}
      </div>
      <div className="login-page--footer footer">
        <img
          src={LOGIN_FOOTER}
          className={cx({
            'footer--image': true,
          })}
          alt="footer"
        />
      </div>
    </div>
  );
};

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NotFound;
