import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const ConfirmAction = ({ action, ...props }) => (
  <div>
    <div className="action__confirm">
      <p>
        {action === 'enable'
          ? 'Two-factor authentication is enabled. Login again to apply new configuration.'
          : 'Two-factor authentication is disabled. Login again to apply new configuration.'}
      </p>
    </div>

    <div className="wedge-modal__footer">
      <div className="survey__footer">
        <Button bsStyle="danger" onClick={props.onClick} className="right" datat-testid="button-confirm_2fa">
          Continue
        </Button>
      </div>
    </div>
  </div>
);

ConfirmAction.propTypes = {
  action: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ConfirmAction;
