import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircularProgress } from '@mui/material';
import qs from 'query-string';

import ErrorPanel from '../../../components/ErrorPanel';
import { ROUTES } from '../../../enum';
import { redirectToPWDReset } from '../../SignUpForm/scenario-actions';
import { clearError } from '../../../store/user/actions';
import { errorSelector } from '../../../store/user/selectors';
import '../login-form.scss';

class ResetPwdEmailConfirmation extends Component {
  constructor(props) {
    super(props);
    const {
      location: { search },
    } = props;
    const { username, token } = qs.parse(search);
    this.shouldCheckToken = token && username;
    this.error = this.shouldCheckToken ? '' : 'Incorrect link';
    if (this.shouldCheckToken) {
      this.token = token;
      this.username = username.replace(' ', '+');
    }
  }

  componentDidMount() {
    const { redirectToPWDReset } = this.props;
    const { shouldCheckToken, token, username } = this;
    if (shouldCheckToken) {
      redirectToPWDReset({
        token,
        username,
      });
    }
  }

  componentWillUnmount() {
    const { clearError, error } = this.props;
    if (error) clearError();
  }

  handleErrorClose = () => {
    const { clearError, history } = this.props;
    clearError();
    history.replace(ROUTES.SIGN_UP);
  };

  render() {
    const error = this.error || this.props.error;
    return (
      <div className="form-container">
        {error ? (
          <ErrorPanel type="warning" message={error} buttonClickHandler={this.handleErrorClose} />
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

ResetPwdEmailConfirmation.propTypes = {
  error: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
  redirectToPWDReset: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: errorSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearError,
      redirectToPWDReset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPwdEmailConfirmation);
