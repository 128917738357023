import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TimeWindows = ({ disabled = false, value, onChange }) => (
  <FormControl disabled={disabled}>
    <InputLabel sx={{ fontSize: 16 }} id="time-window">
      Time Window
    </InputLabel>
    <Select
      labelId="time-window"
      id="timeWindow"
      value={value}
      label="Time Window"
      onChange={(e) => onChange(e.target.value)}
      sx={{ fontSize: 16, width: 160 }}
    >
      <MenuItem sx={{ fontSize: 16 }} value={8}>
        8 hours
      </MenuItem>
      <MenuItem sx={{ fontSize: 16 }} value={24}>
        24 hours
      </MenuItem>
      {/* <MenuItem sx={{ fontSize: 16 }} value={168}>
        7 days
      </MenuItem> */}
    </Select>
  </FormControl>
);

TimeWindows.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default TimeWindows;
