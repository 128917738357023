/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import ReactPhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import './styles.scss';

const CustomPhoneInput = forwardRef((props, ref) => <TextField {...props} inputRef={ref} />);

const PhoneInput = ({ value, onChange, ...props }) => (
  <ReactPhoneInput value={value} onChange={onChange} {...props} defaultCountry="US" inputComponent={CustomPhoneInput} />
);

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhoneInput;
