import { CLEAR_DATA } from '../common-action-types';
import * as ACTION_TYPES from './action-types';

export function loginStarted() {
  return {
    type: ACTION_TYPES.LOGIN_REQUEST,
  };
}

export function loginSuccess(token) {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
    payload: token,
  };
}

export function loginFailed(err) {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE,
    payload: err,
  };
}

export function logoutUser() {
  return {
    type: ACTION_TYPES.LOGOUT_USER,
  };
}

export function renewToken() {
  return {
    type: ACTION_TYPES.RENEW_TOKEN,
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

export function cleanLoginMessage() {
  return {
    type: ACTION_TYPES.CLEAR_LOG_IN_MESSAGE,
  };
}
