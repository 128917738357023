import * as REST from '../../api/rest';
import { updateUserCall, readUserAvatar, updatePasswordCall } from '../../store/sagas/apiCalls';
import { updateCustomerSuccess, updateCustomerFailed } from '../../store/customers/actions';
import { logoutUser } from '../../store/auth/actions';
import {
  updateUser,
  updateUserFailed,
  startLoading,
  finishLoading,
  setError,
  clearPasswordError,
} from '../../store/user/actions';
import { loading } from '../../store/global/actions';
import * as ACTION_TYPES from '../../store/auth/action-types';
import history from '../../history';

export const updateCustomerInfo =
  ({ customer }) =>
  async (dispatch, getState) => {
    try {
      const { uuid } = getState().customers.currentCustomer;
      const result = await REST.updateCustomerInfo({
        customerUUID: uuid,
        customer,
      });
      dispatch(loading(true));
      result.logo = await REST.getCustomerLogo(uuid);
      dispatch(updateCustomerSuccess(result));
      history.goBack();
    } catch (error) {
      dispatch(updateCustomerFailed(error));
    } finally {
      dispatch(loading(false));
    }
  };

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    const result = await updateUserCall(user);
    const avatar = await readUserAvatar(user.email);
    result.avatar = avatar;
    dispatch(updateUser(result));
    history.goBack();
  } catch (error) {
    dispatch(updateUserFailed(error));
    throw error;
  }
};

export const updateUserPassword =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      await updatePasswordCall({ email, password });
      await REST.logout();
      dispatch(logoutUser());
      dispatch(setError('Please login again with new password'));
      dispatch(clearPasswordError());
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.UPDATE_PASSWORD_FAILURE,
        payload: { message: error },
      });
    } finally {
      dispatch(finishLoading());
    }
  };

export const activate2FA = (code) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACTION_TYPES.ACTIVATE_2FA_REQUEST,
    });
    const { email } = getState().user;
    await REST.activate2FA(email, code);
    dispatch({ type: ACTION_TYPES.ACTIVATE_2FA_SUCCESS });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.ACTIVATE_2FA_FAILURE,
      payload: { message: error },
    });
    throw error;
  }
};

export const deactivate2FA = (code) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.DEACTIVATE_2FA_REQUEST });
    const { email } = getState().user;
    await REST.deactivate2FA(email, code);
    dispatch({ type: ACTION_TYPES.DEACTIVATE_2FA_SUCCESS });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.DEACTIVATE_2FA_FAILURE,
      payload: { message: error },
    });
    throw error;
  }
};

export const logout2FA = (message) => async (dispatch) => {
  dispatch(logoutUser());
  dispatch(setError(message));
};
