import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { currentCustomerSelector } from 'store/user/selectors';
import WedgeModal from '../../components/WedgeModal';
import ExpiryWarning from '../../components/ExpiryWarning';
import Header from '../../components/Header/Header';
import TabsHeader from '../../components/TabsHeader';
import { DiscardChanges } from '../../Modals/DiscardChanges';

import User from './UserProfile';
import Organization from './OrgProfile';
import './styles.scss';

const profileTabs = [{ name: 'User Profile' }, { name: 'Organization Profile' }];

const Profile = ({ currentCustomer, user, isLoading }) => {
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [nextNavTab, setNextNavTab] = useState(0);
  const [isFormChanged, setFormChanged] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);

  const tabs = useMemo(
    () => (currentCustomer?.uuid && currentCustomer.owner.uuid === user.uuid ? profileTabs : [profileTabs[0]]),
    [currentCustomer, user],
  );

  const handleChangeTab = (index) => {
    if (selectedIndex !== index) {
      if (!isFormChanged) {
        setSelectedIndex(index);
        setShowChangeModal(false);
      } else {
        setShowChangeModal(true);
        setNextNavTab(index);
      }
    }
  };

  const setFormChangedStatus = (value) => setFormChanged(value);

  const renderForm = () => {
    if (selectedIndex === 0) {
      return <User onFormChanged={setFormChangedStatus} />;
    }
    if (selectedIndex === 1) {
      return <Organization onFormChanged={setFormChangedStatus} />;
    }
    return null;
  };

  const handleDiscardChanges = () => {
    setFormChanged(false);
    setShowChangeModal(false);
    setSelectedIndex(nextNavTab);
  };

  const handleCloseModal = () => setShowChangeModal(false);

  return (
    <>
      <Helmet>
        <title>Profile - Acreto</title>
      </Helmet>
      <div className="wrapper profile-wrapper">
        <ExpiryWarning />
        <WedgeModal isOpen={showChangeModal} onClose={handleCloseModal} title="Discard changes ?" size="tiny">
          <DiscardChanges onYes={handleDiscardChanges} onNo={handleCloseModal} />
        </WedgeModal>
        <Header brandText="Edit Profile" location={location} />
        <div className="profile-container">
          {isLoading ? (
            <div className="loader-container">
              <CircularProgress />
            </div>
          ) : (
            <div className="container profile-form ">
              <TabsHeader selectedIndex={selectedIndex} onSelect={handleChangeTab} tabs={tabs} />
              {renderForm()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Profile.propTypes = {
  currentCustomer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentCustomer: currentCustomerSelector(state),
  user: state.user,
  isLoading: state.customers.fetchingCustomer,
});

export default connect(mapStateToProps)(Profile);
