import React from 'react';

import { ACRETO_LOGO } from '../../assets/Icons';
import './styles.scss';

export default function GlobalLoader() {
  return (
    <div className="global-loader">
      <img className="global-loader--logo" alt="acreto" src={ACRETO_LOGO} />
    </div>
  );
}
