import * as ACTION_TYPES from 'store/auth/action-types';
import { ROUTES } from '../../enum';
import * as REST from '../../api/rest';
import history from '../../history';
import { loginFailed, loginStarted, loginSuccess } from '../../store/auth/actions';
import { readUserAvatar, readUserData } from '../../store/sagas/apiCalls';
import { setUser } from '../../store/user/actions';
import { parseResponseError } from '../../utils/utils';

export function login(credentials) {
  return async (dispatch) => {
    try {
      dispatch(loginStarted());
      let result = null;
      if (credentials.code) {
        result = await REST.loginMFA(
          {
            username: credentials.email,
            password: credentials.password,
          },
          credentials.code,
        );
      } else {
        result = await REST.login({
          username: credentials.email,
          password: credentials.password,
        });
      }
      dispatch(loginSuccess({ accessToken: result.accessToken }));
      dispatch({ type: ACTION_TYPES.LOADING_USER_REQUEST });
      const [avatar, user] = await Promise.all([readUserAvatar(credentials.email), readUserData(credentials.email)]);
      dispatch(setUser({ ...user, avatar }));
      dispatch({ type: ACTION_TYPES.LOADING_USER_SUCCESS });
      history.push(ROUTES.CUSTOMERS);
    } catch (error) {
      const errorMessage = parseResponseError(error, {
        400: 'Your email or password is incorrect',
        424: credentials.code ? 'Invalid Code' : 'MFA required',
      });
      dispatch({ type: ACTION_TYPES.LOADING_USER_FAILURE, payload: { message: errorMessage } });
      dispatch(loginFailed({ message: errorMessage }));
    }
  };
}

export function eulaAccepted(redirect) {
  localStorage.setItem('eulaAccepted', 'true');
  localStorage.setItem('commit-reminder', 'true');
  return async () => {
    history.push(redirect || ROUTES.CUSTOMERS, null);
  };
}

export const removeAuthError = () => (dispatch) => {
  dispatch(loginFailed({ message: '' }));
};
