import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import GlobalLoader from 'components/GlobalLoader';
import { ROUTES } from '../../enum';
import { selectCustomer } from '../../views/CustomersForm/scenario-actions';
import { isAuthenticatedSelector, isEulaAcceptedSelector, currentCustomerSelector } from '../../store/user/selectors';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { isAuthenticated, currentCustomer, selectCustomer, computedMatch } = rest;
  const eulaAccepted = localStorage.getItem('eulaAccepted') === 'true';

  const { params } = computedMatch;

  useEffect(() => {
    const checkCustomer = async () => {
      if (isAuthenticated && params.cusID) {
        if (!currentCustomer || currentCustomer.uuid !== params.cusID) {
          try {
            setLoading(true);
            await selectCustomer(params.cusID);
            setLoading(false);
          } catch (err) {
            history.push(ROUTES.CUSTOMERS);
          }
        }
      }
    };
    checkCustomer();
  }, [currentCustomer, history, isAuthenticated, params.cusID, selectCustomer]);

  if (!isAuthenticated) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  if (!eulaAccepted) {
    if (location.pathname === ROUTES.EULA) {
      return <Component {...rest} />;
    }
    return <Redirect to={{ pathname: ROUTES.EULA, state: { from: location } }} />;
  }

  if ((params.cusID && !currentCustomer?.uuid) || loading) {
    return <GlobalLoader />;
  }

  return (
    <>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  currentCustomer: PropTypes.object,
  eulaAccepted: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  currentCustomer: currentCustomerSelector(state),
  eulaAccepted: isEulaAcceptedSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectCustomer: (customer) => dispatch(selectCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
