import { createAction } from 'redux-actions';

import * as API from 'api/rest';
import * as ACTION_TYPES from './action-types';

export function fecthCustomerStarted() {
  return {
    type: ACTION_TYPES.FETCH_CUSTOMER_REQUEST,
  };
}
export function fetchCustomerSuccess(result) {
  return {
    type: ACTION_TYPES.FETCH_CUSTOMER_SUCCESS,
    payload: result,
  };
}
export function fetchCustomerFailed(error) {
  return {
    type: ACTION_TYPES.FETCH_CUSTOMER_FAILURE,
    payload: {
      message: error,
    },
  };
}

export function setCurrentCustomer(result) {
  return {
    type: ACTION_TYPES.SET_CUR_CUSTOMER,
    payload: result,
  };
}
export function removeCurrentCustomer() {
  return {
    type: ACTION_TYPES.REMOVE_CUR_CUSTOMER,
  };
}

export function updateCustomerStarted() {
  return {
    type: ACTION_TYPES.UPDATE_CUSTOMER_REQUEST,
  };
}
export function updateCustomerSuccess(result) {
  return {
    type: ACTION_TYPES.UPDATE_CUSTOMER_SUCCESS,
    payload: result,
  };
}
export function updateCustomerFailed(error) {
  return {
    type: ACTION_TYPES.UPDATE_CUSTOMER_FAILURE,
    payload: {
      message: error,
    },
  };
}

export const setCustomers = createAction(ACTION_TYPES.SET_CUSTOMERS);

export const getCustomerList = () => async (dispatch) => {
  try {
    const customers = await API.getCustomersList();
    dispatch({ type: ACTION_TYPES.GET_CUSTOMER_LIST_SUCCESS, payload: customers });
    return customers;
  } catch (err) {
    dispatch({ type: ACTION_TYPES.GET_CUSTOMER_LIST_FAILURE, payload: { message: err } });
    throw err;
  }
};
