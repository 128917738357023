import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Field from '../../components/Field';
import './style.scss';

const Active2FA = ({ otp, apiError, ...props }) => {
  const [code, setCode] = useState('');

  const handleEnable = () => {
    props.onSubmit(code);
  };

  if (!otp || !otp.barcode) {
    return null;
  }
  return (
    <div className="modal__content padded active-2fa">
      {!!apiError && <p className="error">Invalid Code</p>}
      <div className="description">
        <p>
          1. Install a two-factor authenticator app on your mobile device, like&nbsp;<b>Google Authenticator</b> or
          similar.
        </p>
        <p>2. Open and setup new account.</p>
        <p>3. Scan the QR code:</p>
      </div>
      <img data-testid="img-2fa_qr" src={`data:image/png;base64, ${otp.barcode}`} alt="QR-Code" />
      <div className="secret-key">
        <p>You can also use the following setup key:</p>
        <Field.Text testId="text-2fa_setup_key" copy text={otp.secret} to={otp.url} />
      </div>
      <div className="description">
        <p>4. Enter the six digit code you see in the app to confirm correct setup:</p>
      </div>
      <div className="code-input" data-testid="input-2fa_code">
        <ReactCodeInput
          fields={6}
          type="number"
          onChange={(value) => setCode(value)}
          inputStyle={{ borderBottom: 'solid 2px #C3D5FF' }}
        />
      </div>
      <div className="description">
        <p>
          Note: After you enable this feature, you will always need to login using your two-factor authentication app.
        </p>
      </div>
      <div className="button-container">
        <Button
          bsStyle="primary"
          bsSize="large"
          disabled={code.length !== 6}
          onClick={handleEnable}
          data-testid="button-2fa_enable"
        >
          Enable
        </Button>
      </div>
    </div>
  );
};

Active2FA.propTypes = {
  otp: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  apiError: PropTypes.any,
};

const mapStateToProps = (state) => ({
  otp: state.user.otp,
});

export default connect(mapStateToProps)(Active2FA);
