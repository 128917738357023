/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, useTheme } from '@mui/material';

const UsageChart = ({ data }) => {
  const theme = useTheme();

  const xLables = useMemo(() => (data.receive || data.transmit || [])?.map(({ timeStamp }) => timeStamp), [data]);

  const options = useMemo(
    () => ({
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#7255E3', '#75A0AE'],
      dataLabels: { enabled: false },
      theme: { mode: theme.palette.mode },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      stroke: {
        colors: ['#7255E3', '#75A0AE'],
        curve: 'straight',
        width: 2,
      },
      grid: {
        show: false,
      },
      legend: { show: false },
      xaxis: {
        type: 'datetime',
        categories: xLables,
        min: xLables[0],
        tickAmount: 8,
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
          datetimeUTC: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
          formatter: (value) => value.toFixed(1),
        },
      },
      tooltip: {
        enabled: false,
        // x: {
        //   format: 'dd MMM yyyy hh:mm',
        // },
        // y: {
        //   formatter: (value) => `${value.toFixed(3)} Mbps`,
        // },
      },
    }),
    [xLables],
  );

  const usageData = useMemo(
    () => [
      {
        name: 'In',
        data: data.receive ? data.receive.map(({ value }) => value) : [],
      },
      {
        name: 'Out',
        data: data.transmit ? data.transmit.map(({ value }) => value) : [],
      },
    ],
    [data],
  );

  if (!xLables.length) return null;

  return (
    <Box sx={{ border: '1px solid #E3E6E8', borderRadius: 1 }}>
      <Chart options={options} series={usageData} type="area" height="100%" width="100%" />
    </Box>
  );
};

UsageChart.propTypes = {
  data: PropTypes.object,
};

export default UsageChart;
